import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            padding: 0,
            width: props.width ?? 850,
            maxWidth: "unset"
        },
        "& .MuiDialogTitle-root": {
            padding: theme.spacing(0, 2.5, 0, 0),
            borderBottom: `1px solid ${theme.custom.color.dividerColor}`,
        },
        "& .MuiDialogContentText-root": {
            padding: theme.spacing(0, 2.5, 0, 0)
        },
        "& .MuiDialog-paperScrollPaper": {
            maxHeight: "calc(100% - 10px)",
        },
    },
    dialogTitleEditIcon: {
        color: theme.palette.secondary.main,
    },
    dialogContent: {
        border: `1px solid ${theme.palette.primary.light}`,
        margin: theme.spacing(0, 1),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 4),
        height: `${props?.height}px !important`,
    },
    dialogContentInner: {
        width: "100%",
        height: "100%"
    },
    scrolledDialogContent: {
        height: 608
    },
    dialogActions: {
        margin: theme.spacing(1, 0, 0),
    },
    tabPanelRoot: {
        "& > div:first-of-type": {
            paddingTop: props?.TabPanelPaddingTop,
            height: "100%",
            "& > div:first-of-type": {
                height: "100%",
            }
        }
    }
}));

export default useStyles;
