import React, { useEffect, useState } from "react";
import { useQuery } from "@redux-requests/react";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  saveSchedule,
  saveScheduleInSRQueues,
  updateSchedule,
  updateScheduleInSRQueues,
  isProcessAssignedToQueue,
  fetchProcessesByFleet,
  fetchResourceList
}
  from "../../../../redux/actions/services";
import clsx from "clsx";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { formatDatePickerByLanguage, getLocale } from "util";
import SchedulePeriod from "../SchedulePeriod/SchedulePeriod";
import ScheduleInfo from "../ScheduleInfo";
import { resetPreventHandleClose, storePreventHandleClose } from "redux/slices/stepperSlice";
import { FETCH_CURRENT_USER } from "redux/constants";
import CustomAutoComplete, { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import TaskForm from "./TaskForm";
import CustomButton from "../../../../components/CustomButton";
import DataNotFound from "../../../../components/DataNotFound";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import InformationIcon from "components/InformationIcon";
import CustomSwitch from "../CustomSwitch";
import useStyles from "./style";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function getDateForm(dateIn) {
  const dt = new Date(dateIn);
  const pattern = "yyyy-MM-dd'T'HH:mm";
  return format(dt, pattern);
}
function ScheduleForm(props) {
  const {
 process, showFromParent, row, customCancel, onSave, isFormOnly = false
} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [dataToSave, setDataToSave] = useState(null);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = useState(false);
  const [scheduleEdit, setScheduleEdit] = useState(null);
  const [showForm, setShowForm] = useState(
    showFromParent || false,
  );
  const [endDateCheck, setEndDateCheck] = useState(false);
  const [masterProcessCheck, setMasterProcessCheck] = useState(false);
  const initialStartDate = new Date().setHours(new Date().getHours() + 1, 0, 0, 0)
  const initialEndDate = new Date().setHours(new Date().getHours() + 1, 30, 0, 0)
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(row?.endDate || null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResourceName, setSelectedResourceName] = useState(null);
  const [tempSchedule, setTempSchedule] = useState(null);
  const [queueConfirm, setQueueConfirm] = useState({
    open: false,
    queueProcess: null
  });
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      startDate: initialStartDate,
      period: 1,
      unitType: 0,
      endDate: row?.endDate || null
    }
  });
  const { errors } = form.formState
  const customHandleClose = useSelector(({ stepper }) => stepper?.customHandleClose);
  const postScheduleAction = (result, successMsg, failureMsg) => {
    if ([200, 201].includes(result.status)) {
      editShowForm();
      if (onSave) onSave();
      toast.success(t(successMsg))
      const isCurrentProcessSchedule = !!form.watch("tasks")?.find(({ taskSessions }) => taskSessions?.map(({ process }) => process?.id)?.includes(process?.id))
      if (!isFormOnly && isCurrentProcessSchedule) setTempSchedule(dataToSave);
    }
    else if (result?.error?.response?.status === 409) toast.error(t(result.error.response.data?.detail))
    else if (result?.error?.response?.status === 400 && result.error.response.data?.title === "unique.schedule.name.exception") toast.error(t("unique.schedule.name.exception"))
    else toast.error(t(failureMsg))
    setOpenMsgConfirm(false);
    setIsLoading(false);
  }
  const confirmSave = () => {
    setIsLoading(true);
    if (scheduleEdit != null) {
      props.updateSchedule(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "Schedule modified successfully",
            "Failed to update the schedule! Please try again later"
          )
        })
    } else {
      props.saveSchedule(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "The schedule was created successfully",
            "Failed to create the schedule! Please try again later"
          )
        })
    }
    setSelectedResourceName(null);
  };

  const clearForm = () => {
    dispatch(resetPreventHandleClose())
    form.clearErrors()
    form.unregister()
    form.reset()
    setEndDate(null)
    setScheduleEdit(null)
    setTempSchedule(null)
  }

  const confirmDiscard = () => {
    clearForm()
    customHandleClose()
    setOpenMsgDiscard(false);
    toast.success(t("Changes disregarded"))
    editShowForm();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setSelectedResourceName(null);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };
  const initialAutomation = form.watch("tasks.0.taskSessions.0.process")
  const orchestratorId = process?.orchestrator?.id ?? initialAutomation?.orchestratorId
  const onSubmit = (data) => {
    if (data && data.name && startDate) {
      const dataToSend = data;
      dataToSend.name = data.name.trim();
      dataToSend.scheduleOrgId = scheduleEdit != null ? scheduleEdit.scheduleOrgId : null;
      dataToSend.startDate = getDateForm(startDate);
      dataToSend.endDate = endDate ? getDateForm(endDate) : null;
      dataToSend.period = data.period;
      dataToSend.unitType = data.unitType;
      dataToSend.description = "";
      dataToSend.priority = 1;
      dataToSend.processInputs = "";
      dataToSend.resource = selectedResourceName;
      dataToSend.isDisabled = false;
      dataToSend.master = form.getValues("queueId") ? masterProcessCheck : false;
      const initialProcess = data?.tasks?.[0]?.taskSessions?.[0]?.process
      dataToSend.orchestratorId = initialProcess?.orchestratorId || initialProcess?.orchestrator?.id;
      data?.tasks.forEach((task, index) => {
        if (task?.onCompleteTask?.name === t("task.stop")) dataToSend.tasks[index].onCompleteTask = null
        if (task?.onExceptionTask?.name === t("task.abort")) dataToSend.tasks[index].onExceptionTask = null
      })
      setDataToSave(dataToSend);
      setOpenMsgConfirm(true)
    }
  };

  const handleChange = (event) => {
    setEndDateCheck(event.target.checked);
    if (!event.target.checked) {
      form.reset({
        name: form.getValues("name"),
        startDate: form.getValues("startDate"),
        endDate: null,
        period: form.getValues("period"),
        unitType: form.getValues("unitType"),
        tasks: form.getValues("tasks"),
        initialTask: form.getValues("initialTask"),
      });
      setEndDate(null);
    } else {
      setEndDate(initialEndDate)
      form.setValue("endDate", initialEndDate)
    }
  };
  const handleChangeMasterProcess = (event) => {
    setMasterProcessCheck(event.target.checked);
  }

  const editShowForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      dispatch(storePreventHandleClose(showMsgDiscard))
      form.reset({
        name: "",
        startDate: initialStartDate,
        endDate: "",
        period: 1,
        unitType: 0,
      });
      if (scheduleEdit === null) {
        setStartDate(initialStartDate);
        setEndDate(null);
      }
      setEndDateCheck(false);
    } else dispatch(resetPreventHandleClose())
  };
  const showMsgDiscard = () => {
    setOpenMsgDiscard(true);
  };
  const onEdit = (row) => {
    setScheduleEdit(row);
    setEndDateCheck(!!row?.endDate ?? !!row?.end);
    setShowForm(!showForm);
    form.reset({
      name: row.scheduleName,
      startDate: new Date(row?.startDate ?? row?.start),
      endDate: new Date(row?.endDate ?? row?.end),
      period: row.period,
      unitType: row.unitType,
      initialTask: row?.scheduleTasks?.find(({ isInitial }) => isInitial),
      tasks: row?.scheduleTasks,
      isProcessSchedule: !!process,
      orchestratorId
    });
    setStartDate(new Date(row?.startDate ?? row?.start).toISOString());
    setMasterProcessCheck(row?.master);
    setEndDate(row?.endDate || row?.end ? new Date(row?.endDate ?? row?.end) : null);
    dispatch(storePreventHandleClose(showMsgDiscard))
  };
  const changeStarDate = (value) => {
    setStartDate(value);
    form.setValue("startDate", value);
    form.clearErrors("startDate");
  };

  const changeEndDate = (value) => {
    setEndDate(value);
    form.setValue("endDate", value);
  };

  const taskFormBody = {
    name: "",
    description: "",
    onCompleteTask: null,
    onExceptionTask: null,
    postCompletionDelay: 1,
    taskSessions: [
      { process: null, resource: null }
    ]
  }

  const onAddTaskForm = () => {
    form.setValue("tasks", form.watch()?.tasks ? [...form.watch().tasks, taskFormBody] : [taskFormBody])
    form.setValue("orchestratorId", orchestratorId)
    form.setValue("isProcessSchedule", !!process)
  }
  const handleQueueChange = () => {
    if (initialAutomation && initialAutomation?.queueId !== queueConfirm?.queueProcess?.queueId) {
      setQueueConfirm({
        open: true,
        queueProcess: initialAutomation,
      })
    }
  }

  const handleQueueConfirm = () => {
    form.setValue("queueId", queueConfirm?.queueProcess?.queueId)
    setQueueConfirm((prevState) => ({ ...prevState, open: false }))
  }

  useEffect(() => {
    form.setValue("orchestratorId", orchestratorId)
    form.setValue("isProcessSchedule", !!process)
  }, [orchestratorId]);

  useEffect(() => {
    dispatch(fetchProcessesByFleet(currentUser?.fleet?.id));
    dispatch(fetchResourceList());
    return () => clearForm()
  }, [currentUser]);

  useEffect(() => {
    if (isFormOnly && row) {
      onEdit(row)
    }
  }, [isFormOnly, row]);

  useEffect(() => {
    if (!form.watch("tasks") || (form.watch("tasks") && form.watch("tasks").length === 0)) form.setValue("initialTask", null)
  }, [form.watch("tasks")]);

  useEffect(() => {
    if (scheduleEdit) scheduleEdit?.scheduleTasks?.[0]?.taskSessions?.[0]?.process?.id !== initialAutomation?.id && handleQueueChange()
    else handleQueueChange()
  }, [initialAutomation]);

  useEffect(() => {
    if (form.watch("tasks")?.length === 1 && form.watch("tasks.0.name")) form.setValue("initialTask", { name: form.watch("tasks.0.name") })
  }, [form.watch("tasks.0.name")]);
  return (
    <FormProvider {...form}>
      <form
      onSubmit={form.handleSubmit(onSubmit)}
      className={classes.root}
      noValidate
      autoComplete="off"
    >
        {(showForm || isFormOnly) && (
          <Box component={Grid} container pt="35px">
            <Grid item container xs={4} className={classes.scheduleContainer}>
              <Grid item xs={12}>
                <Typography className={classes.title}>{t("Schedule")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <InformationIcon
                      titleContent={t(
                          "The name of the schedule",
                      )}
                      originalContent={(
                        <CustomTextField
                              fullWidth
                              type="text"
                              label={t("Name")}
                              id="name"
                              value={form.watch("name")}
                              {...form.register("name", {
                                required: true,
                                validate: (value) => !!value.trim(),
                              })}
                              error={errors?.name}
                              helperText={errors?.name && t("Your input is required")}
                          />
                      )}
                      placement="right"
                      alignItems="flex-end"
                  />

              </Grid>
              <Grid
                  item
                  xs={12}
                  key={`${form.getValues("initialTask")?.name}`}
                >
                <InformationIcon
                      titleContent={t("The first task executed by the schedule")}
                      originalContent={(
                        <CustomAutoComplete
                              options={form.watch("tasks") || []}
                              optionLabel="name"
                              optionUuid="name"
                              noOptionsNode={<NoDataMenu message={t("no.task.message")} />}
                              value={form.watch("initialTask")}
                              {...form.register("initialTask", {
                                required: true,
                              })}
                              onChange={(newVal) => {
                                form.setValue("initialTask", { name: newVal?.name })
                              }}
                              label={t("task.initial.task")}
                              error={errors?.initialTask}
                              helperText={errors?.initialTask && t("Your input is required")}
                              translateLabel={false}
                              disableCloseOnSelect={false}
                              fullWidth
                          />
                      )}
                      placement="right"
                      alignItems="flex-end"
                  />
              </Grid>
              {form.getValues("queueId") && (
              <Box component={Grid} item xs={12} height="revert !important" pt={1.5}>
                <CustomSwitch
                    checked={masterProcessCheck}
                    handleChange={handleChangeMasterProcess}
                    name="masterProcessCheck"
                    label={(
                      <Typography className={classes.dateLabel}>
                        {t("master.process")}
                      </Typography>
                    )}
                    labelPlacement="end"
                />
              </Box>
              )}
              <Grid item xs={12}>
                <InformationIcon
                      titleContent={t("The date and time of the first execution of the schedule")}
                      originalContent={(
                        <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={getLocale()}
                          >
                          <FormControl margin="none" fullWidth>
                            <DateTimePicker
                                  InputProps={{ endAdornment: <TodayOutlinedIcon className={classes.popupIcon} fontSize="small" /> }}
                                  {...form.register("startDate", { required: true })}
                                  value={startDate}
                                  onChange={changeStarDate}
                                  disablePast={!scheduleEdit}
                                  showTodayButton
                                  fullWidth
                                  minDate={startDate || new Date()}
                                  className={classes.input}
                                  format={formatDatePickerByLanguage()}
                                  cancelLabel={t("user.button.cancel")}
                                  todayLabel={t("Today")}
                                  label={t("Start Date")}
                                  ampm={false}
                                  error={errors?.startDate}
                                  helperText={errors?.startDate && t("Your input is required")}
                              />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      )}
                      placement="right"
                      alignItems="flex-end"
                  />
              </Grid>
              <Box component={Grid} item xs={12} height="revert !important">
                <InformationIcon
                    titleContent={t("The date and time after which the schedule will no longer be executed")}
                    originalContent={(
                      <Box height="revert !important" display="grid" gridTemplateColumns="auto 1FR">
                        <Box pt={1.5}>
                          <CustomSwitch
                                checked={endDateCheck}
                                handleChange={handleChange}
                                name="endDateCheck"
                                label={(
                                  <Typography className={classes.dateLabel}>
                                    {t("End date")}
                                  </Typography>
                                )}
                                labelPlacement="end"
                            />
                        </Box>
                        <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={getLocale()}
                          >
                          <FormControl margin="none" fullWidth>
                            <DateTimePicker
                                  InputProps={{
                                    endAdornment: <TodayOutlinedIcon
                                        className={endDateCheck ? classes.popupIcon : classes.popupIconDisabled}
                                        fontSize="small" />
                                  }}
                                  {...form.register("endDate", {
                                    required: endDateCheck,
                                    validate: (data) => {
                                      const date = new Date(data);
                                      return endDateCheck && startDate
                                          ? date.getTime() >= new Date(startDate).getTime()
                                          : true;
                                    },
                                  })}
                                  value={endDate}
                                  onChange={changeEndDate}
                                  className={clsx(classes.input, !endDateCheck ? classes.inputDisabled : "")}
                                  disablePast={!scheduleEdit}
                                  showTodayButton
                                  disabled={!endDateCheck}
                                  minDate={startDate || initialStartDate}
                                  format={formatDatePickerByLanguage()}
                                  fullWidth
                                  cancelLabel={t("user.button.cancel")}
                                  todayLabel={t("Today")}
                                  label={t("End date")}
                                  ampm={false}
                                  error={errors?.endDate}
                                  helperText={errors?.endDate && t("end date cannot be earlier than the start date")}
                              />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </Box>
                    )}
                    placement="right"
                    alignItems="flex-end"
                    marginBottom={0.5}
                />
              </Box>
              <SchedulePeriod
              startDate={startDate}
          />
            </Grid>
            <Grid item container xs={8} className={classes.tasksContainer}>
              <Box component={Grid} container item xs={12} pl="10px" mb="10px !important" height="revert !important" justifyContent="space-between">
                <Box component={Grid} item display="flex" alignItems="center">
                  <InformationIcon
                      titleContent={t(
                          "Set(s) of process sessions that are executed concurrently",
                      )}
                      originalContent={(
                        <Typography className={classes.title}>{t("task(s)")}</Typography>
                      )}
                      placement="right"
                  />
                </Box>
                <Grid item>
                  <CustomButton
                  view="primary"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={onAddTaskForm}
              >
                    {t("add.task")}
                  </CustomButton>
                </Grid>
              </Box>
              <Grid item xs={12} container className={classes.tasksGrid}>
                {form.watch("tasks") && form.watch("tasks").length > 0 ? form.watch("tasks")?.map((task, index) => (
                  <TaskForm task={task} index={index} key={`task-${index}`} />
            ))
            : (
              <DataNotFound
              message={t("no.tasks.yet")}
              backgroundColor="transparent"
              boxShadow="none"
              height="fit-content"
              iconWidth={180}
              padding={50}
          />
              )}
              </Grid>
            </Grid>
            <Box component={Grid} item xs={12} container justify="flex-end" mt="20px !important">
              <Box component={Grid} item mr="10px !important">
                {customCancel || (
                <CustomButton
                    view="cancel"
                    variant="contained"
                    size="medium"
                    onClick={showMsgDiscard}
                >
                  {t("Cancel")}
                </CustomButton>
                )}
              </Box>
              <Grid item>
                <CustomButton
                view="primary"
                size="medium"
                type="submit"
            >
                  {t("Save")}
                </CustomButton>
              </Grid>
            </Box>
          </Box>
      )}
        {openMsgConfirm && (
          <ConfirmMessage
          message={
            scheduleEdit
              ? t("Are you sure you want to save the changes?")
              : t("Are you sure you want to create this schedule ?")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            scheduleEdit ? t("Save changes") : t("Create schedule")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
        {openMsgDiscard && (
          <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
        {queueConfirm.open && (
        <ConfirmMessage
                message={t("You choose a process assigned to queue, all processes must be assigned to the same queue")}
                openStart={queueConfirm.open}
                hideCancel
                onConfirm={handleQueueConfirm}
                buttonConfirm={t("Confirm")}
            />
        )}
        {!showForm && !isFormOnly && (
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
              <ScheduleInfo
              tempSchedule={!showForm ? tempSchedule : null}
              onClickEdit={onEdit}
              process={process}
              classes={classes}
              button={(
                <CustomButton
                  view="primary"
                  size="medium"
                  onClick={editShowForm}
                  startIcon={<AddIcon />}
                >
                  {t("add.schedule")}
                </CustomButton>
              )}
            />
            </Grid>
          </Grid>
      )}
      </form>
    </FormProvider>
  );
}
const mapDispatchToProps = {
  saveSchedule,
  saveScheduleInSRQueues,
  updateSchedule,
  updateScheduleInSRQueues,
  isProcessAssignedToQueue
};
export default connect(null, mapDispatchToProps)(ScheduleForm);
