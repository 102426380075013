import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CustomTooltip from "components/CustomTooltip";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    margin: theme.spacing(0, 1),
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.7)",
      opacity: 0,
    },
  },
  dot: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: 16,
    width: 16,
    borderRadius: "50%",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: "\"\"",
    },
  },
  orange: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.main,
  },
  green: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  red: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  gray: {
    backgroundColor: theme.custom.color.chartColor4,
    color: theme.custom.color.chartColor4,
  },
  blue: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  statusTitle: {
    fontWeight: 700,
    fontSize: "0.9rem",
    color: theme.palette.secondary.main
  },
  status: {
    fontWeight: 700,
    fontSize: "0.9rem",
    color: theme.palette.primary.main
  }
});

const StatusBadge = (props) => {
  const { level, classes } = props;
  const { t } = useTranslation()
  const [levelText, setLevelText] = useState("");
  const handleLevelType = (level) => {
    if (typeof level === "boolean") {
      level ? setLevelText("ACTIVE") : setLevelText("OFFLINE")
    }
    setLevelText(level?.toUpperCase() ?? "PRIVATE")
  }

  const badgeColor = () => {
    switch (levelText) {
      case "WARNING":
      case "ACTIVE":
      case "WORKING":
        return classes.green;
      case "WAITING":
        return classes.orange;
      case "DISABLE":
      case "DISCONNECTED":
      case "OFFLINE":
        return classes.red;
      case "IDLE":
        return classes.blue;
      case "PRIVATE":
      case "":
        return classes.gray;
      default:
        return classes.red;
    }
  };

  useEffect(() => {
    handleLevelType(level)
  }, [level]);
  return (
    <CustomTooltip
      title={
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="span" className={classes.statusTitle}>
              {t("Status")}
              {": "}
            </Typography>
            <Typography variant="span" className={classes.status}>
              {t(levelText)}
            </Typography>
          </Grid>
        </Grid>
      }
      customBgColor
    >
      <Box display="flex" justifyContent="center">
        <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            classes={{ badge: badgeColor(level), dot: classes.dot }}
        />
      </Box>
    </CustomTooltip>
  );
};

export default withStyles(styles)(StatusBadge);
