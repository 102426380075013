import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import CustomButton from "components/CustomButton";
import useStyles from "./style";

export default function CustomVerticalStepper({
  steps,
  handleClose,
  handleNext,
  onSubmit,
  isLoading,
}) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles({ activeStep })();
  const handleNextFunc = () => {
    if (activeStep < steps.length - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextClick = () => {
    if (activeStep < steps.length - 1) {
      if (handleNext) handleNext(handleNextFunc);
      else handleNextFunc();
    } else onSubmit();
  };
  const handlePrevious = () => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box
      display="grid"
      gridTemplateColumns="350px 2px 1FR"
      alignItems="center"
      mt="20px"
    >
      <Box>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          classes={{
            root: classes.stepperRoot,
          }}
        >
          {steps?.map(({ label, key }, index) => (
            <Step key={key}>
              <StepLabel
                classes={{
                  iconContainer: clsx(
                    classes.stepIconContainer,
                    activeStep >= index ? classes.stepIconContainerActive : "",
                    activeStep > index ? classes.stepIconContainerCompleted : ""
                  ),
                  label: classes.stepLabel,
                  active: classes.stepActive,
                  completed: classes.stepCompleted,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box
        display="grid"
        gridTemplateColumns="1FR"
        gridTemplateRows="1FR auto"
        height="100%"
        alignItems="space-between"
        pl={4}
      >
        <Box pl={2.5} pr={1} className={classes.stepContainer}>
          {steps?.[activeStep]?.component}
        </Box>
        <Box display="grid" gridTemplateColumns="1FR auto" mt={2}>
          <Box>
            <CustomButton view="cancelModal" onClick={handleClose}>
              {t("cancel")}
            </CustomButton>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {activeStep !== 0 && (
              <CustomButton
                view="cancelOutlined"
                onClick={() => handlePrevious()}
                disabled={isLoading}
              >
                {t("Previous")}
              </CustomButton>
            )}
            <CustomButton
              view="primary"
              className={classes.nextButton}
              onClick={() => handleNextClick()}
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              {t(activeStep < steps.length - 1 ? "next" : "Save")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
