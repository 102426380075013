import {
    CHECK_IF_QUEUS_HAVE_SCHEDULES_WITHOUT_ROBOTS, CREATE_OR_UPDATE_SR_QUEUE,
    DELETE_QUEUES_SCHEDULES, DELETE_SR_QUEUE,
    DELETE_SR_QUEUES, FETCH_SR_QUEUE,
    FETCH_SR_QUEUE_EXECUTIONS, FETCH_SR_QUEUES,
    HANDLE_PRE_DELETE_QUEUE,
    IS_PROCESS_ASSIGNED_TO_QUEUE,
    MIGRATE_QUEUES_SCHEDULES,
    REFRESH_QUEUES_FROM_DB,
    REMOVE_EXECUTION_FROM_QUEUE, RESET_SR_QUEUE, UPDATE_PROCESS, UPDATE_SR_EXECUTION_PRIORITY,
    UPDATE_SR_QUEUE_STATUS,
    CHECK_IF_QUEUS_HAVE_MANUAL_EXECUTION_WITH_SAME_ROBOT, HAS_QUEUE_PENDING_EXECUTIONS, FETCH_SR_QUEUES_NAMES_AND_IDS
} from "redux/constants";
import { URL_SR_QUEUES, URL_SR_QUEUES_MNGT } from "redux/constants/endpoints";
import { automationEntity, resourcesEntity } from "util/configs/backendEntities";

export const deleteQueuesSchedules = (queuesIds, onSuccess, onError) => ({
    type: DELETE_QUEUES_SCHEDULES,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/delete-queues-schedules`,
        method: "delete",
        params: { queuesIds: queuesIds.join(",") }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const migrateQueuesSchedules = (queuesIds, onSuccess, onError) => ({
    type: MIGRATE_QUEUES_SCHEDULES,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/migrate-queues-schedules`,
        method: "get",
        params: { queuesIds: queuesIds.join(",") }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const countQueuesSchedules = (queuesIds, onSuccess, onError) => ({
    type: CHECK_IF_QUEUS_HAVE_SCHEDULES_WITHOUT_ROBOTS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/schedules/count`,
        method: "get",
        params: { queuesIds: queuesIds.join(",") }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const handlePreDeleteSRQueue = (queueId, processActions, onSuccess, onError) => ({
    type: HANDLE_PRE_DELETE_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/delegation`,
        method: "put",
        data: processActions,
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});

export const refreshQueuesFromDB = () => ({
    type: REFRESH_QUEUES_FROM_DB,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/refreshQueues`,
        method: "post",
    }
});
export const removeExecutionFromSrQueue = (
    execution,
    onSuccess,
    onError
) => {
    execution.process.orchestrator = null;
    execution.process.resources = null;
    execution.resource = null;
    return {
        type: REMOVE_EXECUTION_FROM_QUEUE,
        request: {
            url: `${URL_SR_QUEUES_MNGT}/executions`,
            method: "delete",
            data: execution,
            headers: { "Content-Type": "application/json" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (err) => {
                if (onError) onError();
                throw err;
            },
        },
    };
};
export const fetchSrQueueExecutions = (queueId) => ({
    type: FETCH_SR_QUEUE_EXECUTIONS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/executions`,
        method: "get",
    }
});
export const isProcessAssignedToQueue = (processId) => ({
    type: IS_PROCESS_ASSIGNED_TO_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/isProcessAssignedToQueue/${processId}`,
    }
});
export const enableOrDisableSrQueue = (queueId, status, processesAction) => {
    // Generic function in AdminTableRow uses DISABLE
    if (status === "DISABLE") {
        status = "DISABLED";
    }
    return {
        type: UPDATE_SR_QUEUE_STATUS,
        request: {
            url: `${URL_SR_QUEUES_MNGT}/status/${queueId}`,
            method: "put",
            params: { status },
            data: processesAction,
            headers: { "Content-Type": "application/json" },
        }
    };
};

export const deleteSrQueues = (queuesIds, onSuccess, onError) => {
    const queuesId = queuesIds.join(",");
    return {
        type: DELETE_SR_QUEUES,
        request: {
            url: `${URL_SR_QUEUES_MNGT}`,
            method: "delete",
            params: { queuesIds: queuesId },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (err) => {
                if (onError) onError();
                throw err;
            },
        },
    };
};

export const resetSrQueues = (queueId) => ({
    type: RESET_SR_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/reset/${queueId}`,
        method: "put",
    }
});
export const deleteSrQueue = (queueId, processesAction, onSuccess, onError) => ({
    type: DELETE_SR_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}`,
        method: "delete",
        data: processesAction
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const createOrUpdateSrQueue = (data) => ({
    type: CREATE_OR_UPDATE_SR_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/`,
        method: "put",
        data,
        headers: { "Content-Type": "application/json" },
    }
});
export const fetchSrQueue = (queueId) => ({
    type: FETCH_SR_QUEUE,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}`,
        method: "get",
    }
});
export const fetchSrQueues = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, sortBy, fleets
    } = filter;
    return {
        type: FETCH_SR_QUEUES,
        request: {
            url: encodeURI(`${URL_SR_QUEUES_MNGT}${searchCriteria ? `?search=${searchCriteria}` : ""}`),
            method: "get",
            params: {
                sort: `${sortBy || "id"},desc`,
                page: pageNo,
                size: pageSize,
                status: status ? status.join(",") : null,
                fleetIds: fleets?.join()
            }
        }
    };
};
export const fetchSrQueuesNamesAndIds = (fleetId) => ({
    type: FETCH_SR_QUEUES_NAMES_AND_IDS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/user`,
        method: "get",
        params: {
          fleetId
        }
    }
});
export const startProcessInSRQueues = ({ id, resourceName }) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_SR_QUEUES}/${automationEntity}/${id}/${resourceName}/start`,
        method: "put",
    },
    meta: {
        onSuccess: (response) => response,
    },
});

export const checkManualPendingInSrQueue = ({ id, resourceName }) => ({
    type: CHECK_IF_QUEUS_HAVE_MANUAL_EXECUTION_WITH_SAME_ROBOT,
    request: {
        url: `${URL_SR_QUEUES}/${automationEntity}/${id}/${resourceName}/check-manual-pending`,
    },
});

export const updateSrExecutionPriority = (
    data,
    newPriority,
    onSuccess,
    onError
) => {
    data.process.orchestrator = null;
    data.process.resources = null;
    data.resource = null;
    return {
        type: UPDATE_SR_EXECUTION_PRIORITY,
        request: {
            url: `${URL_SR_QUEUES_MNGT}/execution/priority/${newPriority}`,
            method: "post",
            data
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (err) => {
                if (onError) onError();
                throw err;
            },
        },
    };
};

export const fetchQueueResourcePendingExecutionsCount = (queueId, resourceId, onSuccess, onError) => ({
    type: CHECK_IF_QUEUS_HAVE_MANUAL_EXECUTION_WITH_SAME_ROBOT,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/${resourcesEntity}/${resourceId}/pending-executions`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const hasQueuePendingExecutions = (queueId, processId, onSuccess, onError) => ({
    type: HAS_QUEUE_PENDING_EXECUTIONS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/has-pending-executions/${automationEntity}/${processId}`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
