import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTranslation } from "react-i18next";
import { padStart } from "lodash";
import renderValue, { renderContent } from "../../dashboardUtils";
import User from "assets/NewDashboardIcons/user.svg";
import useStyles from "../../style";

const FTESaved = ({ fteSaved, isLoading }) => {
  const classes = useStyles()();
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(new Date());
    };
    const remainingSeconds = 60 - currentTime.getSeconds();
    const initialUpdateTimeout = setTimeout(() => {
      updateCurrentTime();
      const intervalId = setInterval(updateCurrentTime, 60000);
      return () => clearInterval(intervalId);
    }, remainingSeconds * 1000);

    return () => clearTimeout(initialUpdateTimeout);
  }, [currentTime]);

  const formattedTime = `${padStart(currentTime.getHours(), 2, "0")}:${padStart(currentTime.getMinutes(), 2, "0")}`;
  const Component = () => (
    <Grid container item justify="space-around" alignItems="center">
      <Grid item xs={3}>
        <img src={User} alt="User" className={classes.userImage} />
      </Grid>
      <Grid container item xs={8}>
        <Typography className={classes.textBold}>
          {`${renderValue({ value: fteSaved })} ${t("timeUnite.fte")}s`}
          <br />
          {` ${t("til.now")}`}
        </Typography>
        <Grid container item alignItems="center" spacing={1}>
          <Grid item>
            <Typography className={classes.textTime}>00:00</Typography>
          </Grid>
          <Grid item>
            <ArrowForwardIcon className={classes.iconColor} />
          </Grid>
          <Grid item>
            <Typography className={classes.textTime}>{formattedTime}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>);

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={t("Time Saved")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        { renderContent(isLoading, [fteSaved], <Component />) }
      </CardContent>
    </Box>
  );
};

export default FTESaved;
