import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useQuery } from "@redux-requests/react";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { Controller, useFormContext } from "react-hook-form";
import { fetchResourceList } from "redux/actions/services";
import { useTranslation } from "react-i18next";
import isUndefined from "lodash/isUndefined";
import { range } from "lodash";
import { useDispatch } from "react-redux";
import { allDaysSelected } from "util/helpers/date.helpers";
import isNull from "lodash/isNull";
import { periods } from "util/constants";
import { FETCH_ROBOTS, FETCH_UNAVAILABLE_PRIORITIES } from "redux/constants";
import SlaItemTitle from "pages/Services/components/SlaInfo/SlaItemTitle";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import CustomButton from "components/CustomButton";
import EstimationComputing from "pages/Services/components/Process/components/ExecutionTimeComputer";
import {
  formatNumberInputOnBlur,
  formatNumberInputOnFocus,
  handleInputChanges,
} from "util";
import CustomSelectField from "components/FormFields/CustomSelectField";
import DayHoursSelector from "components/TimeSelectors/DayHoursSelector";
import WeekDaysCalendar from "components/TimeSelectors/WeekDaysCalendar";
import useStyles from "pages/AutomationsPending/style";

export const POLICY_LIST = ["KEEP", "DELETE"];
export default function SLAConfiguration() {
  const form = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles()();
  const dispatch = useDispatch();
  const allResources = useQuery({ type: FETCH_ROBOTS })?.data?.content;
  const unavailablePriorities = useQuery({
    type: FETCH_UNAVAILABLE_PRIORITIES,
  })?.data;
  const [openEstimatedTimeComputingPopUp, setOpenEstimatedTimeComputingPopUp] = useState(false);
  const priorityArray = range(1, 100).map((i) => ({ id: i, label: i }));
  const [availablePriorities, setAvailablePriorities] = useState(priorityArray);
  const createPriority = (priority) => (priority ? { id: priority, label: priority } : null);
  useEffect(() => {
    dispatch(
      fetchResourceList({ orchestrator: [form.getValues("orchestrator")] })
    );
  }, [form.getValues("orchestrator")]);
  useEffect(() => {
    if (unavailablePriorities) { setAvailablePriorities(
        priorityArray.filter(
          (p) => !unavailablePriorities?.includes(Number(p.id))
        )
      ); }
  }, [unavailablePriorities]);
  return (
    <>
      <Box>
        <SlaItemTitle
          title={t("Robot assignation")}
          info={t("Robot assignation")}
        />
        <FormControl fullWidth className={classes.textField}>
          <Controller
            control={form.control}
            name="resourcesId"
            rules={{
              required: t("field.is.required", {
                field: t("Available robots"),
              }),
            }}
            render={(field) => (
              <CustomAutoComplete
                {...field}
                multiple
                label={t("Available robots")}
                optionLabel="resourceDisplayName"
                options={allResources ?? []}
                value={allResources?.filter(({ id }) => form.getValues("resourcesId")?.includes(id))}
                onChange={(value) => form.setValue(
                    "resourcesId",
                    value?.map(({ id }) => id)
                  )}
                error={!!form.formState.errors.resourcesId?.message}
                helperText={
                  !!form.formState.errors.resourcesId
                  && form.formState.errors.resourcesId?.message
                }
                disableClearable
                simpleChipStyle
              />
            )}
          />
        </FormControl>
        <Divider orientation="horizontal" className={classes.divider} />
        <SlaItemTitle
          title={t("Estimated execution time")}
          info={t("Estimated execution time")}
        />
        <Box
          display="grid"
          gridTemplateColumns="1FR 1FR 1FR 1FR 2FR"
          gridColumnGap="40px"
        >
          {periods.map((period) => estimatedExecutionField(period, form, t, classes))}
          <Box pl={4} pt={1}>
            <CustomButton
              view="primary"
              onClick={() => setOpenEstimatedTimeComputingPopUp(true)}
              size="small"
            >
              {t("Update using historical data")}
            </CustomButton>
          </Box>
        </Box>
        <Divider orientation="horizontal" className={classes.divider} />
        <SlaItemTitle
          title={t("restriction.form.MinimumExecutionFrequency")}
          info={t("restriction.userguide.frequencyOccurence")}
        />
        <Box
          display="grid"
          gridTemplateColumns="1FR 1FR 1FR"
          gridColumnGap="40px"
          mt="15px"
        >
          <CustomTextField
            {...form.register("restrictionDto.occurence", {
              pattern: {
                value: /^[0-9]*$/,
                message: t("restriction.form.numberInteger.error"),
              },
            })}
            value={form.getValues("restrictionDto.occurence")}
            fullWidth
            InputProps={{ inputProps: { min: minVal } }}
            InputLabelProps={{
              shrink: isFieldFull("restrictionDto.occurence", form),
            }}
            label={t("restriction.form.occurence")}
            error={!!form.formState.errors.restrictionDto?.occurence}
            helperText={
              form.formState.errors.restrictionDto?.occurence
              && form.formState.errors.occurence.message
            }
            type="text"
            onInput={(e) => handleInputChanges(e, false)}
            onFocus={(e) => formatNumberInputOnFocus(e)}
            onBlur={(e) => formatNumberInputOnBlur(e)}
          />
          <CustomTextField
            {...form.register("restrictionDto.frequency", {
              pattern: {
                value: /^[0-9]*$/,
                message: t("restriction.form.numberInteger.error"),
              },
            })}
            value={form.getValues("restrictionDto.frequency")}
            InputProps={{ inputProps: { min: minVal } }}
            InputLabelProps={{
              shrink: isFieldFull("restrictionDto.frequency", form),
            }}
            label={t("restriction.form.frequency")}
            fullWidth
            error={!!form.formState.errors.restrictionDto?.frequency}
            helperText={
              form.formState.errors.restrictionDto?.frequency
              && form.formState.errors.restrictionDto?.frequency.message
            }
            type="text"
            onInput={(e) => handleInputChanges(e, false)}
            onFocus={(e) => formatNumberInputOnFocus(e)}
            onBlur={(e) => formatNumberInputOnBlur(e)}
          />
          <Controller
            control={form.control}
            name="restrictionDto.frequencyOcurrenceType"
            rules={{
              required: t("field.is.required", {
                field: t("Time"),
              }),
            }}
            render={(field) => (
              <CustomAutoComplete
                {...field}
                label={t("Time")}
                optionLabel="label"
                optionUuid="value"
                options={timeType(t) ?? []}
                value={timeType(t)?.find(
                  ({ value }) => form.getValues("restrictionDto.frequencyOcurrenceType")
                    === value
                )}
                onChange={(value) => form.setValue(
                    "restrictionDto.frequencyOcurrenceType",
                    value?.value
                  )}
                error={
                  !!form.formState.errors.restrictionDto?.frequencyOcurrenceType
                }
                helperText={
                  !!form.formState.errors.resourcesId
                  && form.formState.errors.resourcesId?.message
                }
                disableClearable
              />
            )}
          />
        </Box>
        <Divider orientation="horizontal" className={classes.divider} />
        <Box
          display="grid"
          gridTemplateColumns="1FR 2px 1FR"
          gridColumnGap="30px"
        >
          <Box>
            <SlaItemTitle
              title="restriction.form.allowedSession"
              info="restriction.userGuide.allowedSession"
            />
            <CustomTextField
              {...form.register("restrictionDto.allowedSession", {
                pattern: {
                  value: /^[0-9]*$/,
                  message: t("restriction.form.numberInteger.error"),
                },
              })}
              value={form.getValues("restrictionDto.allowedSession")}
              className={classes.textField}
              InputProps={{ inputProps: { min: minVal } }}
              InputLabelProps={{
                shrink: isFieldFull("restrictionDto.allowedSession", form),
              }}
              label={t("orchestrator.management.formControl.sessionNumber")}
              error={!!form.formState.errors.restrictionDto?.allowedSession}
              helperText={
                form.formState.errors.restrictionDto?.allowedSession
                && form.formState.errors.restrictionDto?.allowedSession.message
              }
              type="text"
              onInput={(e) => handleInputChanges(e, false)}
              onFocus={(e) => formatNumberInputOnFocus(e)}
              onBlur={(e) => formatNumberInputOnBlur(e)}
              fullWidth
            />
          </Box>
          <Divider orientation="vertical" className={classes.verticalDivider} />
          <Box>
            <SlaItemTitle
              title="restriction.form.priority"
              info="restriction.userGuide.priority"
            />
            <Controller
              {...form.register("restrictionDto.priority")}
              key={form.getValues("restrictionDto.priority")}
              control={form.control}
              rules={{
                required: t("restriction.form.priority.required.error"),
                pattern: {
                  value: /^[0-9]*$/,
                  message: t("restriction.form.numberInteger.error"),
                },
                min: {
                  value: 0,
                  message: t("restriction.form.priority.minValue.error"),
                },
                max: {
                  value: 100,
                  message: t("restriction.form.priority.maxValue.error"),
                },
              }}
              render={({ field }) => (
                <CustomAutoComplete
                  options={availablePriorities || []}
                  value={createPriority(field?.value)}
                  onChange={(newValue) => {
                    field?.onChange(newValue?.id);
                    form.setValue("restrictionDto.priority", newValue?.id);
                  }}
                  optionLabel="label"
                  optionUuid="id"
                  label={t("restriction.form.priority.label")}
                  error={!!form.formState.errors.restrictionDto?.priority}
                  helperText={
                    form.formState.errors.restrictionDto?.priority
                    && form.formState.errors.restrictionDto?.priority.message
                  }
                  inputRef={field?.ref}
                  className={classes.textField}
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
        <Divider orientation="horizontal" className={classes.divider} />
        <SlaItemTitle
          title="restriction.form.executionTimeLine"
          info="restriction.userGuide.executionTimeLine"
        />
        <Box
          display="grid"
          gridTemplateColumns="1FR 2px 1FR"
          gridColumnGap="30px"
        >
          <Box className={classes.daysContainer}>
            <WeekDaysCalendar
              title="restriction.form.executionDay"
              titleStyle={classes.executionTimeTitle}
              allDaysLabel="restriction.form.allDays"
              days={form.getValues("restrictionDto.restrictionDays")}
              setDays={(days) => form.setValue("restrictionDto.restrictionDays", days)}
              allDaysChecked={allDaysSelected(
                form.getValues("restrictionDto.restrictionDays")
              )}
            />
          </Box>
          <Divider orientation="vertical" className={classes.verticalDivider} />
          <Box className={classes.daysContainer}>
            <DayHoursSelector
              title="restriction.form.executionTime"
              titleStyle={classes.executionTimeTitle}
              setError={form.setError}
              clearErrors={form.clearErrors}
              errors={form.formState.errors}
              fromTime={form.watch("restrictionDto.from")}
              toTime={form.watch("restrictionDto.to")}
              setFromTime={(value) => form.setValue("restrictionDto.from", value)}
              setToTime={(value) => form.setValue("restrictionDto.to", value)}
            />
          </Box>
        </Box>
        <Divider orientation="horizontal" className={classes.divider} />
        <SlaItemTitle
          title="restriction.form.missedExecutionsPolicy"
          info="restriction.userGuide.missedExecutionsPolicy"
        />
        <Box gridRowGap={10} className={classes.keepPolicyContainer} mb="40px">
          <CustomSelectField
            options={POLICY_LIST}
            value={form.getValues("restrictionDto.missedExecutionsPolicy")}
            {...form.register("restrictionDto.missedExecutionsPolicy", {
              required: {
                value: true,
                message: t("queue.management.form.validation.required"),
              },
            })}
            onChange={(event) => {
              form.clearErrors("restrictionDto.missedExecutionsPolicy");
              form.setValue(
                "restrictionDto.missedExecutionsPolicy",
                event.target.value
              );
              if (event.target.value === "DELETE") {
                form.setValue("missedExecutionsTime", null);
              }
            }}
            variant="standard"
            customOptionLabel={(option) => t(option)}
            labelClassName={classes.inputLabel}
            error={
              !!form.formState.errors.restrictionDto?.missedExecutionsPolicy
            }
            helperText={
              form.formState.errors.restrictionDto?.missedExecutionsPolicy
              && form.formState.errors.missedExecutionsPolicy.message
            }
            fullWidth
            isCustom
          />
          {form.getValues("restrictionDto.missedExecutionsPolicy")
            === "KEEP" && (
            <>
              <Typography
                variant="body2"
                component="span"
                className={classes.typography}
              >
                {t("for")}
              </Typography>
              <CustomTextField
                {...form.register("restrictionDto.missedExecutionsTime", {
                  required: {
                    value: true,
                    message: t("queue.management.form.validation.required"),
                  },
                })}
                value={form.getValues("restrictionDto.missedExecutionsTime")}
                id="restrictionDto.missedExecutionsTime"
                type="number"
                error={
                  !!form.formState.errors.restrictionDto?.missedExecutionsTime
                    ?.message
                }
                onChange={(event) => {
                  form.clearErrors("restrictionDto?.missedExecutionsTime");
                  form.setValue(
                    "restrictionDto.missedExecutionsTime",
                    event.target.value
                  );
                }}
                inputProps={{
                  min: 0,
                }}
              />
              <Typography
                variant="body2"
                component="span"
                className={classes.hourTypography}
              >
                {t("hr")}
                {form.formState.errors.missedExecutionsTime?.message && (
                  <Tooltip
                    title={t("queue.management.form.validation.required")}
                    placement="right"
                  >
                    <ButtonBase>
                      <ErrorOutlineRoundedIcon className={classes.infoIcon} />
                    </ButtonBase>
                  </Tooltip>
                )}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <EstimationComputing
        setValues={form.setValue}
        openEstimatedTimeComputingPopUp={openEstimatedTimeComputingPopUp}
        processId={form.getValues("id")}
        setOpenEstimatedTimeComputingPopUp={setOpenEstimatedTimeComputingPopUp}
      />
    </>
  );
}

const minVal = 0;
let maxVal = 5;
const isFieldFull = (fieldKey, form) => !(isUndefined(form.watch(fieldKey)) || isNull(form.watch(fieldKey)));
const estimatedExecutionField = (period, form, t, classes) => {
  let maxValMessage = "";
  if (period === "days") {
    maxVal = Number.POSITIVE_INFINITY;
  } else if (period === "seconds" || period === "minutes") {
    maxVal = 59;
    maxValMessage = "restriction.form.min.sec.maxValue.error";
  } else if (period === "hours") {
    maxVal = 23;
    maxValMessage = "restriction.form.hour.maxValue.error";
  }

  return (
    <CustomTextField
      {...form.register(`restrictionDto.estimationExecution.${period}`, {
        pattern: {
          value: /^[0-9]*$/,
          message: t("restriction.form.numberInteger.error"),
        },
        min: {
          value: minVal,
          message: t("restriction.form.minValue.error"),
        },
        max: {
          value: maxVal,
          message: t(maxValMessage),
        },
      })}
      className={classes.textField}
      key={
        form.formState.errors.restrictionDto?.estimationExecution?.[period]
          ?.message
      }
      value={form.getValues(`restrictionDto.estimationExecution.${period}`)}
      label={t(period.charAt(0).toUpperCase() + period.slice(1))}
      InputLabelProps={{
        shrink: isFieldFull(
          `restrictionDto.estimationExecution.${period}`,
          form
        ),
      }}
      error={
        !!form.formState.errors.restrictionDto?.estimationExecution?.[period]
      }
      helperText={
        form.formState.errors.restrictionDto?.estimationExecution?.[period]
        && form.formState.errors.restrictionDto?.estimationExecution?.[period]
          ?.message
      }
      type="number"
      fullWidth
    />
  );
};

export const timeType = (t) => [
  { id: 1, label: t("Days"), value: "Days" },
  { id: 2, label: t("Hours"), value: "Hours" },
  { id: 3, label: t("Minutes"), value: "Minutes" },
  { id: 4, label: t("Seconds"), value: "Secondes" },
];
