import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddIcon from "@material-ui/icons/Add";
import isEmpty from "lodash/isEmpty";
import {
  formtNumberByLanguage,
  formatDateByLanguage,
  getCurrencySymbol,
} from "util";
import { useQuery } from "@redux-requests/react";
import { useTranslation } from "react-i18next";
import { HasPermission } from "components/HasPermission";
import DataNotFound from "components/DataNotFound";
import CircularLoader from "components/Loaders/CircularLoader";
import CustomButton from "components/CustomButton";
import { FETCH_CURRENT_USER } from "redux/constants";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import SlaItemTitle from "pages/Services/components/SlaInfo/SlaItemTitle";
import useStyles from "pages/AutomationsPending/ConfigurationSteps/LinkedDocumentation/style";

const TABLE_CELL_ALIGN = "left";
export default function CostDistribution({
  sharedData,
  handleClickOpen,
  sharedLoading,
  allocationLoading,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const isListEmpty = isEmpty(sharedData ?? []);
  return (
    <Box>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={8} alignItems="center" container>
          <SlaItemTitle title={t("Cost distribution")} />
        </Grid>
        <Grid item>
          <HasPermission name="Add cost">
            <CustomButton
              onClick={handleClickOpen}
              view="primary"
              size="small"
              fullWidth
              startIcon={<AddIcon />}
              className={classes.addButton}
            >
              {t("Add cost")}
            </CustomButton>
          </HasPermission>
        </Grid>
      </Grid>
      <Box>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(sharedLoading || allocationLoading) && (
          <CircularLoader size={50} />
        )}
        {!(sharedLoading || allocationLoading) && isListEmpty ? (
          <DataNotFound
            backgroundColor="transparent"
            boxShadow="none"
            iconHeight={150}
            iconWidth={150}
            width="100%"
          />
        ) : !(sharedLoading || allocationLoading) && (
          <TableContainer className={classes.costTable}>
            <Table stickyHeader classes={{ root: classes.table }}>
              <EnhancedTableHead
                headCells={headCells(t)}
                cellFontWeight={600}
              />
              <TableBody>
                {sharedData.map((item, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {item?.costAllocations?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {item?.costAllocations?.category}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {item?.costAllocations?.category === "recurring"
                          ? formatDateByLanguage(
                              item.costAllocations.startDate,
                              false
                            )
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {item?.costAllocations?.category === "recurring"
                          ? formatDateByLanguage(item?.endDate, false)
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {t(item?.costAllocations?.type)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.costTableCell}
                      align={TABLE_CELL_ALIGN}
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.urlText}
                      >
                        {formtNumberByLanguage(
                          item?.costAllocations?.allocatedAmount
                        )}
                        {" "}
                        {getCurrencySymbol(currentUser?.fleet?.currency)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

const headCells = (t) => [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: t("Name"),
    sortable: false,
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: t("Category"),
    sortable: false,
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: t("Start Date"),
    sortable: false,
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: t("End date"),
    sortable: false,
  },
  {
    id: "allocationAmount",
    numeric: false,
    disablePadding: false,
    label: t("Allocation Amount"),
    sortable: false,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: t("Type"),
    sortable: false,
  },
];
