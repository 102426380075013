import React, { useEffect, } from "react";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  flatMap, max, uniqBy
} from "lodash";
import { IDLE_STATUS } from "util/constants";
import { toggleView } from "../../redux/slices/settingsSlice";
import { fetchProcesses } from "../../redux/actions/services/index";
import { useQuery } from "@redux-requests/react";
import { updateFilter } from "../../redux/slices/processSlice";
import { FETCH_PROCESSES } from "redux/constants";
import TableCard from "./components/Process/TableCard";
import Filter from "./components/Filter";
import ProcessNotFound from "../../assets/Process_Overview.svg";
import DataNotFound from "../../components/DataNotFound";
import CircularLoader from "../../components/Loaders/CircularLoader";
import CustomTableContainer from "../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../components/TableComponents/CustomTable";
import CustomTableHeader from "../../components/TableComponents/CustomTableHeader";
import CustomPagination from "./components/CustomPagination";
import PageHeader from "components/PageHeader";
import useStyles from "./style";

function Service() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data } = useQuery({ type: FETCH_PROCESSES });
  const { list: processesData } = data || {};
  const { resultsCount } = data || 0;
  // This use state is used to make sure you cannot edit the priority of 2 processes at the same time
  // it stores the id of the currently edited process, or 0 if no process is edited
  const [editId, setEditId] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const uniqueTags = uniqBy(flatMap(processesData, "tags"), "id")
  const longestFirstWordLengthInTags = max(uniqueTags.map((tag) => tag?.name.split(" ")[0].length));
  const { view } = useSelector((state) => state.settings);
  const handleToggleView = () => {
    dispatch(toggleView());
  };

  const filterProcess = useSelector(({ filterProcess: i }) => i);

  const dataNotFound = () => (
    <DataNotFound message={t("no.process.message")} icon={ProcessNotFound} />
  );

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, filterProcess]);

  const { t } = useTranslation();

  const headerCellProps = {
    paddingBottom: "0"
  }

  const handlePageChange = (e, pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo
        }),
    );
  };

  const handleRowPerPageChange = (e) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: 0,
          pageSize: e.target.value,
        }),
    );
  };

  const handleNextPerPage = (pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: pageNo + 1
        }),
    );
  };

  const handlePreviousPage = (pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: pageNo - 1
        }),
    );
  };

  const labels = [
    { label: "", ...headerCellProps },
    { label: t("Execution Count"), ...headerCellProps },
    { label: t("Process Item"), ...headerCellProps },
    // { label: t("process.history.exceptionItems"), ...headerCellProps },
    { label: t("dashboard.graph.pendingItems"), ...headerCellProps },
    { label: t("Roi"), ...headerCellProps },
    { label: t("Break-even"), ...headerCellProps },
    { label: t("Status"), ...headerCellProps },
  ]

  return (
    <Grid
      container
      direction="column"
      className={classes.rootGlobal}
    >
      <PageHeader title="My Processes" />
      <Grid item>
        <Filter
            view={view}
            handleToggleView={handleToggleView}
            showListButton={processesData && processesData.length > 0}
        />
      </Grid>

      <Grid item>
        {!loading ? (
          <CustomTableContainer>
            <CustomTable>
              {processesData && processesData.length > 0 && (<CustomTableHeader labels={labels} />)}
              <TableBody>
                {processesData && processesData.length > 0
                        ? processesData.map((process, index) => (
                          <TableCard width={longestFirstWordLengthInTags} process={process} index={index} statusData={statusData} editId={editId} setEditId={setEditId} key={`process-list-table-card-${index}`} />
                        ))
                        : dataNotFound()}
              </TableBody>
            </CustomTable>

          </CustomTableContainer>
          ) : (
            <CircularLoader />
          )}
      </Grid>
      <Grid item>
        {!loading && processesData && processesData.length > 0 && (
        <CustomPagination
                count={resultsCount}
                rowsPerPage={filterProcess.pageSize}
                page={filterProcess.pageNo}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowPerPageChange}
                onNext={handleNextPerPage}
                onPrevious={handlePreviousPage}
            />
        )}
      </Grid>
    </Grid>
  );
}

const statusData = {
  PENDING: { label: "Pending" },
  RUNNING: { label: "Executing" },
  PENDING_SR: { label: "PENDING_SR" },
  DONE: { label: IDLE_STATUS },
  UNKNOWN: { label: "Unknown" },
  EXCEPTION: { label: IDLE_STATUS },
  STOPPED: { label: IDLE_STATUS },
  COMPLETED: { label: IDLE_STATUS },
  DEBUGGING: { label: IDLE_STATUS },
  ARCHIVED: { label: IDLE_STATUS },
  WARNING: { label: IDLE_STATUS },
  STOPPING: { label: "Executing" },
};

export default Service;
