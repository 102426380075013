import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { fetchLinksByProcess, deleteLink } from "redux/actions/services/links";
import { handleDisplayedLabel } from "util";
import { FETCH_CURRENT_USER } from "redux/constants";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import ConfirmMessage from "components/ConfirmMessage";
import { EnhancedTableToolbar } from "components/DataTable/EnhancedTableToolbar";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import { SearchFilter } from "components/Filter";
import { isPermitted } from "components/HasPermission";
import useStyles from "pages/AutomationsPending/ConfigurationSteps/LinkedDocumentation/style";

const DocumentationRow = (props) => {
  const { processId } = props;
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const classes = useStyles();
  const [row, setRow] = useState(null);
  const page = 0;
  const rowsPerPage = 5;
  const [orderBy, setOrderBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const { t } = useTranslation();

  const sortByList = [
    { code: "name,desc", label: "Name (descending)" },
    { code: "name,asc", label: "Name (ascending)" },
    { code: "description,desc", label: "Description (descending)" },
    { code: "description,asc", label: "Description (ascending)" },
  ];
  const getLinksList = (page, size, sort, searchCriteria) => {
    setIsLoaded(false);
    props
      .fetchLinksByProcess(processId, page, size, sort, searchCriteria)
      .then((result) => {
        setData(result?.data?.content);
        setCount(result?.data?.totalElements);
      })
      .then(() => setIsLoaded(true));
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getLinksList(page, rowsPerPage, orderBy, searchText);
    }
  }, [orderBy, page, rowsPerPage, searchText]);

  useEffect(() => {
    if (props.dataChanged) {
      getLinksList(page, rowsPerPage, orderBy, searchText);
      props.setDataChanged(false);
    }
  }, [props.dataChanged]);

  const handleChangeSortBy = (value) => {
    setOrderBy(value.code);
    getLinksList(page, rowsPerPage, value.code, searchText);
  };

  const handleChangeSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value);
    getLinksList(page, rowsPerPage, orderBy, value);
  };

  const redirect = (event, row) => {
    window.open(row?.url, "_blank");
  };

  const deleteLink = (event, row) => {
    setRow(row);
    setOpenMsgConfirm(true);
  };

  const cancelDelete = () => {
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    setIsLoading(true);
    props.deleteLink(row.id).then(() => {
      toast.success(t("link.delete.success"));
      setOpenMsgConfirm(false);
      getLinksList(page, rowsPerPage, orderBy, searchText);
      setIsLoading(false);
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation="0" className={classes.paper}>
        <EnhancedTableToolbar
          filtred
          numSelected={0}
          button={
            isPermitted(currentUser, "Manage external documentation")
            && props.button
          }
          filterWidth={6}
          filterInput={
            <Grid container item spacing={3} alignItems="center">
              <Grid item xs={5} className={classes.searchContainer}>
                <SearchFilter
                  placeHolder={t("Search")}
                  callback={handleChangeSearchText}
                  className={classes.searchIcon}
                  value={searchText}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomAutoComplete
                  options={sortByList ?? []}
                  optionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                  optionUuid="code"
                  value={sortByList.find(({ code }) => orderBy === code)}
                  onChange={(value) => handleChangeSortBy(value)}
                  label={t("sortBy")}
                />
              </Grid>
            </Grid>
          }
        />
        <TableContainer className={classes.linkTable}>
          {isLoaded ? (
            <Table stickyHeader classes={{ root: classes.table }}>
              {count > 0 && (
                <EnhancedTableHead
                  order={orderBy}
                  orderBy={orderBy}
                  rowCount={count}
                  headCells={headCells(t)}
                  cellFontWeight={600}
                />
              )}
              <TableBody>
                {count > 0 ? (
                  data.map((row) => (
                    <TableRow hover key={row.id} className={classes.tableRow}>
                      <TableCell align="left" className={classes.tableCell}>
                        <Tooltip title={row.url}>
                          <Typography
                            noWrap
                            variant="body2"
                            className={classes.urlText}
                          >
                            {row?.url}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={clsx(
                          classes.tableCell,
                          classes.urlTableCell
                        )}
                      >
                        <Tooltip title={row?.description}>
                          <Typography
                            noWrap
                            variant="body2"
                            className={classes.urlText}
                          >
                            {row?.description}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={clsx(
                          classes.tableCell,
                          classes.descriptionTableCell
                        )}
                      >
                        <Tooltip
                          title={`${t("tooltip.visit.link")}: ${row?.url}`}
                        >
                          <Typography
                            className={classes.directLink}
                            onClick={(event) => redirect(event, row)}
                          >
                            {row?.url}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={clsx(
                          classes.tableCell,
                          classes.actionsTableCell
                        )}
                      >
                        <Grid container wrap="nowrap">
                          {isPermitted(
                            currentUser,
                            "Manage external documentation"
                          ) && (
                            <Tooltip title={t("tooltip.delete.link")}>
                              <IconButton
                                onClick={(event) => deleteLink(event, row)}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <DataNotFound padding={50} message={t("no.link.message")} />
                )}
              </TableBody>
            </Table>
          ) : (
            <CircularLoader />
          )}
        </TableContainer>
      </Paper>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("confirm.delete.link")}
          openStart={openMsgConfirm}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
          isDelete="true"
          buttonConfirm={t("Delete")}
          buttonCancel="Cancel"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  fetchLinksByProcess,
  deleteLink,
};

const headCells = (t) => [
  {
    id: "url",
    numeric: false,
    disablePadding: false,
    label: t("Url'"),
    sortable: false,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: t("link.description"),
    sortable: false,
  },
  {
    id: "DirectLink",
    numeric: false,
    disablePadding: false,
    label: t("Direct link'"),
    sortable: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: t("Actions"),
    sortable: false,
  },
];

export default connect(null, mapDispatchToProps)(DocumentationRow);
