import React, { useEffect, useState } from "react";
import CustomDialog from "components/CustomDialog";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  fetchProcessesDetails,
  fetchProcessesSortedByUsage,
  fetchProcessExecutionByProcess,
  fetchProcessRestriction,
  fetchUnavailablePriorities,
} from "redux/actions/services";
import { useDispatch } from "react-redux";
import { formtNumberByLanguage } from "util";
import { DEFAULT_FTE_COST } from "util/constants/financial";
import { periods } from "util/constants";
import get from "lodash/get";
import {
  fetchProcessGroups,
  fetchProcessUsers,
  updatePendingAutomation,
} from "redux/actions/services/pendingAutomation";
import { toast } from "react-toastify";
import {
  DEFAULT_FROM_TIME,
  DEFAULT_TO_TIME,
  initialDays,
} from "util/helpers/date.helpers";
import CustomVerticalStepper from "components/CustomVerticalStepper";
import GeneralInfo from "pages/AutomationsPending/ConfigurationSteps/GeneralInfo";
import AutomationAssignment from "pages/AutomationsPending/ConfigurationSteps/AutomationAssignment";
import AutomationControlSettings from "pages/AutomationsPending/ConfigurationSteps/AutomationControlSettings";
import DataMapping from "pages/AutomationsPending/ConfigurationSteps/DataMapping";
import KpiSettings from "pages/AutomationsPending/ConfigurationSteps/KpiSettings";
import SLAConfiguration, {
  POLICY_LIST,
} from "pages/AutomationsPending/ConfigurationSteps/SLAConfiguration";
import FinancialParameters from "pages/AutomationsPending/ConfigurationSteps/FinancialParameters";
import LinkedDocumentation from "pages/AutomationsPending/ConfigurationSteps/LinkedDocumentation";
import { DEFAULT_ALLOWED_SESSIONS } from "pages/Services/components/SlaInfo";

export default function ConfigurationSteps({
  openConfigSteps,
  configuredAutomations,
  copiedAutomation,
  handleClose,
}) {
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const defaultPeriodValues = () => {
    const tempValue = {};
    periods?.forEach((period) => {
      tempValue[period] = 0;
    });
    return tempValue;
  };
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      dataMappings: [],
      restrictionDto: {
        estimationExecution: defaultPeriodValues(),
        allowedSession: DEFAULT_ALLOWED_SESSIONS,
        frequency: null,
        occurence: null,
        priority: null,
        frequencyOcurrenceType: null,
        restrictionDays: initialDays,
        from: DEFAULT_FROM_TIME,
        to: DEFAULT_TO_TIME,
        missedExecutionsPolicy: POLICY_LIST[1],
        missedExecutionsTime: null,
      },
      financialDto: {
        sharedData: [],
        fteItemTime: null,
        fteExecutionTime: null,
        fteCost: null,
      },
      id: configuredAutomations?.id,
      copiedId: copiedAutomation?.id,
      withCopiedData: !!copiedAutomation,
      resourcesId: [],
      exceptionAccountingRate: 0,
      executionsExceptionAccountingRate: 0,
    },
  });
  const steps = getSteps(t);
  const onUpdateSuccess = () => {
    setIsUpdating(false);
    toast.success(t("changes.saved.successfully"));
    handleClose();
  };
  const onUpdateError = () => {
    setIsUpdating(false);
    toast.error(t("changes.saving.failed"));
  };
  const onSubmit = (data) => {
    setIsUpdating(true);
    const dataToSend = {
      ...data,
      fleetsIds: data?.fleetsIds?.join(),
      usersIds: data?.usersIds?.join(),
      tagsDto: data?.tagsDto?.join(),
      resourcesId: data?.resourcesId?.join(),
    };
    dispatch(
      updatePendingAutomation(dataToSend, onUpdateSuccess, onUpdateError)
    );
  };
  const handleNext = (nextFunc) => {
    form
      .trigger(undefined, {
        shouldFocus: true,
      })
      .then((allClear) => {
        if (allClear) {
          nextFunc();
        }
      });
  };
  const fetchRestriction = (idKey = "id", processDescription) => {
    let processExecution = "";
    let defaultIsSet = false;
    dispatch(
      fetchProcessExecutionByProcess(
        form.getValues(idKey),
        0,
        1,
        "executionEndTime",
        "desc",
        []
      )
    ).then((res) => {
      processExecution = res?.data;
      dispatch(fetchProcessRestriction(form.getValues(idKey))).then((res) => {
        const processRestrictions = res?.data;
        form.setValue("orchestrator", processRestrictions?.orchestrator);
        form.setValue(
          "restrictionDto.priority",
          processRestrictions.priority ?? null
        );
        form.setValue(
          "restrictionDto.from",
          processRestrictions?.fromTime ?? DEFAULT_FROM_TIME
        );
        form.setValue(
          "restrictionDto.to",
          processRestrictions?.toTime ?? DEFAULT_TO_TIME
        );
        const day = 86400;
        const hour = 3600;
        const minute = 60;
        let totalseconds;
        if (
          !processRestrictions.executionEstimation
          && !defaultIsSet
          && processExecution?.list?.[0]
        ) {
          totalseconds = processExecution.list[0]?.executionDuration;
          defaultIsSet = true;
          processRestrictions.executionEstimation = null;
        } else {
          totalseconds = processRestrictions.executionEstimation;
        }

        const daysout = Math.floor(totalseconds / day);
        const hoursout = Math.floor((totalseconds - daysout * day) / hour);
        const minutesout = Math.floor(
          (totalseconds - daysout * day - hoursout * hour) / minute
        );
        const secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute;
        form.setValue(
          "restrictionDto.estimationExecution.days",
          processRestrictions?.executionEstimation && daysout
        );
        form.setValue(
          "restrictionDto.estimationExecution.hours",
          processRestrictions?.executionEstimation && hoursout
        );
        form.setValue(
          "restrictionDto.estimationExecution.minutes",
          processRestrictions?.executionEstimation && minutesout
        );
        form.setValue(
          "restrictionDto.estimationExecution.seconds",
          processRestrictions?.executionEstimation && secondsout
        );
        form.setValue(
          "restrictionDto.allowedSession",
          processRestrictions?.allowedSession
            ? formtNumberByLanguage(processRestrictions.allowedSession)
            : DEFAULT_ALLOWED_SESSIONS
        );
        form.setValue(
          "restrictionDto.frequency",
          processRestrictions.frequency
            ? formtNumberByLanguage(processRestrictions.frequency)
            : null
        );
        form.setValue(
          "restrictionDto.occurence",
          processRestrictions.occurence
            ? formtNumberByLanguage(processRestrictions.occurence)
            : null
        );
        form.setValue(
          "restrictionDto.frequencyOcurrenceType",
          processRestrictions.frequencyType || processRestrictions.occurenceType
            ? processRestrictions.frequencyType
                || processRestrictions.occurenceType
            : null
        );
        form.setValue(
          "restrictionDto.missedExecutionsPolicy",
          processDescription?.missedExecutionsTime ? "KEEP" : "DELETE"
        );
        form.setValue(
          "restrictionDto.allowedSession",
          processDescription?.missedExecutionsTime
            ? processDescription?.missedExecutionsTime
            : 0
        );
        if (processRestrictions.restrictionDays) {
          updateDays(processRestrictions.restrictionDays);
        } else {
          const newarray = form
            .getValues("restrictionDto.restrictionDays")
            ?.map((e) => ({ ...e, checked: false }));
          form.setValue("restrictionDto.restrictionDays", newarray);
        }
      });
    });
  };
  const updateDays = (data) => {
    if (data) {
      data = data.split(",");
      const newDays = form
        .getValues("restrictionDto.restrictionDays")
        ?.map((day) => ({ ...day, checked: data.includes(day.value) }));
      form.setValue("restrictionDto.restrictionDays", newDays);
    }
  };
  const setFteChange = (tempProcess) => {
    dispatch(fetchProcessesSortedByUsage()).then((res) => {
      const process = res?.data?.find(({ id }) => id === tempProcess?.id);
      const tempFteCost = formtNumberByLanguage(
        process?.processRoiDetail?.fteCost
      );
      form.setValue(
        "financialDto.fteCost",
        tempFteCost && tempFteCost !== 0 ? tempFteCost : DEFAULT_FTE_COST
      );
      form.setValue(
        "financialDto.fteItemTime",
        formtNumberByLanguage(process?.processRoiDetail?.fteItemTime)
      );
      form.setValue(
        "financialDto.fteExecutionTime",
        formtNumberByLanguage(process?.processRoiDetail?.fteExecutionTime)
      );
    });
  };
  const handleAutomationAssigment = (id) => {
    dispatch(fetchProcessGroups(id)).then((res) => form.setValue(
        "fleetsIds",
        res?.data?.map(({ id }) => id)
      ));
    dispatch(fetchProcessUsers(id)).then((res) => form.setValue(
        "usersIds",
        res?.data?.map(({ id }) => id)
      ));
  };
  const handleFormClose = () => {
    form.reset();
    handleClose();
  };
  const onError = (errors) => errors[Object.keys(errors)[0]].ref.focus;
  useEffect(() => {
    dispatch(fetchProcessesDetails(form.getValues("id"))).then((res) => {
      form.setValue(
        "processDescription.processDisplayName",
        res?.data?.processDescription?.processDisplayName
      );
      form.setValue(
        "processDescription.processDescription",
        res?.data?.processDescription?.processDescription
      );
      if (form.getValues("withCopiedData")) {
        dispatch(fetchUnavailablePriorities(form.getValues("copiedId")));
        dispatch(fetchProcessesDetails(form.getValues("copiedId"))).then(
          (res) => {
            fetchRestriction("copiedId", res?.data?.processDescription);
            form.setValue(
              "tagsDto",
              res?.data?.tagsDto?.map(({ id }) => id)
            );
            handleAutomationAssigment(form.getValues("copiedId"));
            form.setValue("isSchedulable", res?.data?.isSchedulable);
            form.setValue("isManualAllowed", res?.data?.isManualAllowed);
            form.setValue("isRetryable", res?.data?.isRetryable);
            form.setValue(
              "isRetryableWithData",
              res?.data?.isRetryableWithData
            );
            form.setValue(
              "businessExceptionsAsCompleted",
              res?.data?.businessExceptionsAsCompleted
            );
            form.setValue(
              "beCalculationPeriod",
              res?.data?.beCalculationPeriod
            );
            form.setValue(
              "executionsExceptionAccountingRate",
              get(res?.data, "executionsExceptionAccountingRate") || 0
            );
            form.setValue(
              "exceptionAccountingRate",
              get(res?.data, "exceptionAccountingRate") || 0
            );
            form.setValue(
              "executionsExceptionAccountingRateSwicth",
              !!get(res?.data, "executionsExceptionAccountingRate")
            );
            form.setValue(
              "exceptionAccountingRateSwitch",
              !!get(res?.data, "exceptionAccountingRate")
            );
            form.setValue("orchestrator", res?.data?.orchestrator);
            form.setValue(
              "resourcesId",
              res?.data?.resources?.map(({ id }) => id)
            );
            setFteChange(res?.data);
          }
        );
      } else {
        handleAutomationAssigment(form.getValues("id"));
        form.setValue("orchestrator", res?.data?.orchestrator);
        dispatch(fetchUnavailablePriorities(form.getValues("id")));
        fetchRestriction("id", res?.data?.processDescription);
        setFteChange(res?.data);
      }
    });
  }, []);
  return (
    <CustomDialog
      title={t("Configuration Steps")}
      open={openConfigSteps}
      customWidth={1300}
      maxWidth={1300}
      borderRadius={7}
      withDivider
      withCloseButton
      formProps={{
        ...form,
        onSubmit: form.handleSubmit(onSubmit, onError),
      }}
      onClose={handleFormClose}
    >
      <CustomVerticalStepper
        steps={steps}
        handleClose={handleFormClose}
        handleNext={handleNext}
        onSubmit={form.handleSubmit(onSubmit, onError)}
        isLoading={isUpdating}
      />
    </CustomDialog>
  );
}

const getSteps = (t) => [
  {
    key: "GeneralInformation",
    component: <GeneralInfo />,
    label: t("General Information"),
  },
  {
    key: "AssignAutomationToUsers",
    component: <AutomationAssignment />,
    label: t("Assign Automation to Users"),
  },
  {
    key: "DefineAutomationControlSettings",
    component: <AutomationControlSettings />,
    label: t("Define Automation Control Settings"),
  },
  {
    key: "SetDataMapping",
    component: <DataMapping />,
    label: t("Set Data Mapping"),
  },
  {
    key: "ConfigureKPISettings",
    component: <KpiSettings />,
    label: t("Configure KPI Settings"),
  },
  {
    key: "SetSLAConfiguration",
    component: <SLAConfiguration />,
    label: t("Set SLA Configuration"),
  },
  {
    key: "ConfigureFinancialParameters",
    component: <FinancialParameters />,
    label: t("Configure Financial Parameters"),
  },
  {
    key: "SetLinkedDocumentation",
    component: <LinkedDocumentation />,
    label: t("Set Linked Documentation"),
  },
];
