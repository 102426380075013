import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash/debounce";
import {
  fetchFleetsForFilter,
  fetchDivisionsForFilters,
  fetchProcesses, fetchTagsForFilters,
} from "../../../../redux/actions/services";
import {
  updateFilter,
  initFilter,
} from "../../../../redux/slices/processSlice";
import { FETCH_CURRENT_USER, FETCH_TAG_FOR_FILTERS } from "../../../../redux/constants";
import { isFleetAdministrator } from "util";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import ClearFilter from "../../../../components/ClearFilter";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import { isPartitioningDisabled } from "../../../../util/constants/PermitedFeature";
import { SearchFilter } from "components/Filter";

const sortFields = [
  { id: "latestStatus", label: "Status" },
  { id: "processKpi.completedCount", label: "completedCount" },
  { id: "processKpi.completedCount,DESC", label: "completedCount.descending" },
  { id: "processKpi.pendingCount", label: "pendingCount" },
  { id: "processKpi.pendingCount,DESC", label: "pendingCount.descending" },
  { id: "recentActivity", label: "processRestriction.recentActivity" },
  { id: "recentActivity,DESC", label: "processRestriction.recentActivity.descending" }
]
function Filter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [searchText, setsearchText] = React.useState("")
  const [userDivision, setUserDivision] = useState();
  const [userFleets, setUserFleets] = useState();
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const tagsFilter = useQuery({ type: FETCH_TAG_FOR_FILTERS })?.data;

  const searchProcesses = (keyWord) => {
    dispatch(
      updateFilter({
        ...filterProcess,
        pageNo: 0,
        search: keyWord,
      }),
    );
  };

  const handleClear = () => {
    dispatch(initFilter())
    setsearchText("")
  }

  const debouncer = useCallback(
    debounce((nextValue) => searchProcesses(nextValue), 500),
    [filterProcess.fleet, filterProcess.tags],
  );

  const handleChangeSearchText = (event) => {
    setsearchText(event.target.value);
    debouncer(event.target.value);
  };

  useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);

  useEffect(() => {
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    );
  }, [dispatch, filterProcess]);

  useEffect(() => {
    dispatch(fetchTagsForFilters());
    setsearchText(filterProcess.search);
  }, []);

  const handleChangeFilter = (event, key) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          [key]: event?.map(({ id }) => id)
        }),
    );
  };
  const handleSort = (value) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          sortBy: value
        }),
    );
  };

  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchTagsForFilters(filterProcess.fleet));
    }
  }, [filterProcess.fleet])
  useEffect(() => {
    if (currentUser && !isPartitioningDisabled) {
      dispatch(fetchDivisionsForFilters()).then((res) => {
        setUserDivision(res?.data)
      })
    }
  }, [currentUser])

  useEffect(() => {
    dispatch(fetchFleetsForFilter()).then((res) => {
      setUserFleets(res.data);
    });
  }, [])

  return (
    <Grid container item alignItems="flex-end" spacing={2}>
      <Grid item xs={2}>
        <SearchFilter
            callback={handleChangeSearchText}
            value={searchText}
            queryParams={filterProcess.search}
        />
      </Grid>
      {isInstanceOwner && (
        <Grid item xs={2}>
          <CustomAutoComplete
              multiple
              options={userFleets || []}
              optionLabel="companyName"
              value={userFleets?.filter(({ id }) => filterProcess.fleet?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
              onChange={(e) => handleChangeFilter(e, "fleet")}
              label={t("groups")}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={tagsFilter || []}
          optionLabel="name"
          value={tagsFilter?.filter(({ id }) => filterProcess.tags?.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
          onChange={(e) => handleChangeFilter(e, "tags")}
          label={t("Tags")}
        />
      </Grid>
      {!isPartitioningDisabled && (
        <Grid item xs={2}>
          <CustomAutoComplete
            multiple
            options={userDivision || []}
            optionLabel="divisionName"
            value={userDivision?.filter(({ id }) => filterProcess.division?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.division.message")} />}
            onChange={(e) => handleChangeFilter(e, "division")}
            label={t("divisions.division")}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <CustomAutoComplete
            defaultValue={{ id: "recentActivity,DESC", label: "processRestriction.recentActivity.descending" }}
            options={sortFields}
            optionLabel="label"
            value={sortFields?.filter(({ id }) => filterProcess.sortBy === id)}
            onChange={(e) => handleSort(e.id)}
            label={t("Sort By")}
        />
      </Grid>
      <Grid item>
        <ClearFilter clearFilter={handleClear} />
      </Grid>
    </Grid>
  );
}

export default connect(null, { fetchProcesses })(Filter);
