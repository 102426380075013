import React from "react";
import BugReportIcon from "@material-ui/icons/BugReport";
import reportBug from "util/helpers/menu.helper";
import { ROLES } from "util/constants";
import {
  GROUPS_BASE_URL,
  isEnvParamEnabled,
  itemsPathLabel,
  ORCHESTRATORS_BASE_URL,
  QUEUES_BASE_URL,
  RESOURCE_BASE_URL, TAGS_BASE_URL, USERS_BASE_URL, OPPORTUNITY_ASSESSMENT_BASE_URL
} from "util/index";
import Services from "../pages/Services";
import ProcessAssessment from "../pages/ProcessAssessment";
import Reporting from "../pages/Reporting";
import AlertsManagement from "../pages/AlertsManagement";
import CalendarComponent from "../pages/Calendar";
import FinancialParameters from "../pages/Financial";
import UsersAdministration from "../pages/Administration/UsersAdministration";
import RobotsAdministration from "../pages/Administration/RobotsAdministration";
import ProcessExecutionPage from "pages/ProcessExecutionPage";
import ProcessItemPage from "pages/ProcessItemPage";
import NewDashboard from "../pages/newDashboard";
import Dashboard from "../pages/Dashboard";
import Audits from "../pages/Audits";
import AutomationsPending from "pages/AutomationsPending";
import { ReactComponent as OpportunityAssessmentIcon } from "assets/navigationIcons/leftMenuIcons/opportunity_assessment.svg";
import { ReactComponent as PlannerIcon } from "assets/navigationIcons/leftMenuIcons/planner.svg";
import { ReactComponent as AutomationIcon } from "assets/navigationIcons/leftMenuIcons/automation.svg";
import { ReactComponent as MonitoringIcon } from "assets/navigationIcons/leftMenuIcons/monitoring_dashborad.svg";
import { ReactComponent as PerformanceIcon } from "assets/navigationIcons/leftMenuIcons/performance_dashboard.svg";
import { ReactComponent as ExceptionIcon } from "assets/navigationIcons/leftMenuIcons/exception.svg";
import { ReactComponent as ExecutionIcon } from "assets/navigationIcons/leftMenuIcons/execution.svg";
import { ReactComponent as SrQueueIcon } from "assets/navigationIcons/leftMenuIcons/sr_queue.svg";
import { ReactComponent as ResourceIcon } from "assets/navigationIcons/leftMenuIcons/resource.svg";
import { ReactComponent as OrchestratorIcon } from "assets/navigationIcons/leftMenuIcons/orchestrator.svg";
import { ReactComponent as ItemIcon } from "assets/navigationIcons/leftMenuIcons/item.svg";
import { ReactComponent as UserIcon } from "assets/navigationIcons/leftMenuIcons/user.svg";
import { ReactComponent as GroupIcon } from "assets/navigationIcons/leftMenuIcons/group.svg";
import { ReactComponent as ReportingIcon } from "assets/navigationIcons/leftMenuIcons/reporting.svg";
import { ReactComponent as AuditIcon } from "assets/navigationIcons/leftMenuIcons/audit.svg";
import { ReactComponent as AlertsIcon } from "assets/navigationIcons/leftMenuIcons/alerts.svg";
import { ReactComponent as AssigmentIcon } from "assets/navigationIcons/leftMenuIcons/onboarding.svg";

const menus = [
  {
    name: "Monitoring Dashboard",
    path: "/monitoring-dashboard",
    component: () => <NewDashboard />,
    icon: MonitoringIcon,
    isHidden: !isEnvParamEnabled("REACT_APP_ENABLE_MONITORING_DASHBOARD"),
    group: "Monitoring & Reporting",
  },
  {
    name: "Dashboard",
    path: "/performance-dashboard",
    component: () => <Dashboard />,
    icon: PerformanceIcon,
    group: "Monitoring & Reporting",
  },
  {
    name: "Analytics",
    label: "Rapports",
    path: "/reporting",
    component: () => <Reporting />,
    icon: ReportingIcon,
    group: "Monitoring & Reporting",
  },
  {
    name: "Alerts Management",
    path: "/alerts",
    component: () => <AlertsManagement />,
    icon: AlertsIcon,
    group: "Monitoring & Reporting",
  },
  {
    name: "My Processes",
    label: "Automations",
    path: "/automations",
    component: () => <Services />,
    icon: AutomationIcon,
    group: "Automation Management",
  },
  {
    name: "Process Item",
    label: "Exceptions",
    path: "/exceptions/exception",
    component: () => <ProcessExecutionPage />,
    icon: ExceptionIcon,
    group: "Automation Management",
  },
  {
    name: "Process Item",
    path: `/${itemsPathLabel}/completed`,
    component: () => <ProcessItemPage />,
    icon: ItemIcon,
    group: "Automation Management",
  },
  {
    name: "Executions",
    path: "/executions",
    component: () => <ProcessExecutionPage />,
    icon: ExecutionIcon,
    group: "Automation Management",
  },
  {
    name: "Process Assessment",
    label: "Opportunity assessment",
    path: OPPORTUNITY_ASSESSMENT_BASE_URL,
    component: () => <ProcessAssessment />,
    icon: OpportunityAssessmentIcon,
    group: "Automation Management",
  },
  {
    name: "Orchestrators",
    label: "Orchestrators",
    path: ORCHESTRATORS_BASE_URL,
    component: () => <RobotsAdministration />,
    icon: OrchestratorIcon,
    moduleEnabled: ["Orchestrators"],
    isAdmin: ROLES.admin,
    group: "Resource Management",
  },
  {
    name: "Resources",
    label: "Resources",
    path: RESOURCE_BASE_URL,
    component: () => <RobotsAdministration />,
    icon: ResourceIcon,
    moduleEnabled: ["Resources"],
    isAdmin: ROLES.admin,
    group: "Resource Management",
  },
  {
    name: "Resource Planner",
    path: "/planner",
    component: () => <CalendarComponent />,
    icon: PlannerIcon,
    group: "Resource Management",
  },
  {
    name: "SR Queues",
    label: "SR Queues",
    path: QUEUES_BASE_URL,
    component: () => <RobotsAdministration />,
    icon: SrQueueIcon,
    moduleEnabled: ["SR Queues"],
    isAdmin: ROLES.admin,
    group: "Resource Management",
  },
  {
    label: "groups",
    path: GROUPS_BASE_URL,
    component: () => <UsersAdministration />,
    icon: GroupIcon,
    isInherited: true,
    group: "Administration",
  },
  {
    label: "user.management.menu.title",
    path: USERS_BASE_URL,
    component: () => <UsersAdministration />,
    icon: UserIcon,
    isInherited: true,
    group: "Administration",
  },
  {
    label: "Audit",
    path: "/auditTrail",
    component: () => <Audits />,
    isInherited: true,
    icon: AuditIcon,
    group: "Administration",
  },
];

export const footerMenuActions = [
  {
    label: "Automations Pending",
    path: "/automationsPending",
    component: () => <AutomationsPending />,
    isInherited: true,
    icon: AssigmentIcon,
    withBadge: true,
    badgeCountApi: "fetchPendingAutomationsCount",
    group: ""
  },
  {
    label: "Report a Bug",
    handleClick: (u) => reportBug(u),
    icon: BugReportIcon,
    group: ""
  },
]

export const headerMenus = [
  {
    name: "User Administration",
    label: "Tags",
    permissionKey: "Administration",
    path: TAGS_BASE_URL,
    component: () => <UsersAdministration />,
    moduleEnabled: ["Tags Module"],
    isAdmin: ROLES.admin,
    group: "Administration",
  },
  {
    name: "Financial Parameters",
    path: "/Financial",
    component: () => <FinancialParameters />,
  },
];

export default menus;
