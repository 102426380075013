// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "تحديث",
  "dashboard.refresh.desc": "كل {{time}} {{timeUnit}}",
  "All rights reserved": ".جميع الحقوق محفوظة",
  Dashboard: "لوحة المعلومات",
  Planner: "مخطط",
  "Resource Planner": "مخطط",
  "Business management": "إدارة الأعمال",
  Orders: "الطلبات",
  Invoices: "الفواتير",
  Regulations: "المدفوعات",
  Suppliers: "الموردون",
  Rapports: "التقارير",
  Search: "بحث",
  "Add your Invoice": " أضف فاتورتك أو",
  browse: "تصفح",
  "Show all": "عرض الكل",
  Supplier: "المورد",
  "Invoice No.": "رقم الفاتورة",
  "restriction.form.estimationExecutaion.required.error":
    "الرجاء إدخال وقت التنفيذ",
  "restriction.form.allowedSession": "عدد التنفيذات الموازية المسموح بها",
  "restriction.form.to.error": " يجب أن يكون التاريخ أكبر من",
  "restriction.form.allDays": "كل الأيام",
  "restriction.form.MinimumExecutionFrequency": "الحد الأدنى من تكرار التنفيذ",
  "user has exception item": "حساب المستخدم هذا له استثناءات مرفقة به",
  "Unexpected Error": "حدث خطأ غير متوقع",
  "resource.hidden.success": "تم بنجاح {{status}} الروبوت",
  hidden: "مخفي",
  unhidden: "غير مخفي",
  "users deleted successfully": "تم حذف المستخدم (المستخدمين) بنجاح.",
  "fleet deleted successfully": "تم حذف المجموعة بنجاح.",
  "orchestrator deleted successfully": "تم حذف المنظم بنجاح.",
  "queue deleted successfully": "تم حذف قائمة الانتظار SR بنجاح.",
  Mon: "الاثنين",
  Tue: "الثلاثاء",
  Wed: "الأربعاء",
  Thu: "الخميس",
  Fri: "الجمعة",
  Sat: "السبت",
  Sun: "الأحد",
  "restriction.form.numberInteger.error": "لرجاء إدخال رقم صحيح ",
  "restriction.form.minValue.error": "يجب أن تكون القيمة أكبر من أو تساوي 0",
  "restriction.form.maxValue.error":
    "يجب أن يكون عدد الأرقام أقل من أو يساوي 9",
  "restriction.form.frequency": "تكرار",
  "restriction.form.occurence": "الحدوث",
  "restriction.form.priority.required.error": "الرجاء إدخال رقم",
  "restriction.form.priority.minValue.error":
    "الرجاء إدخال قيمة أكبر من أو تساوي 1",
  "restriction.form.priority.maxValue.error":
    "الرجاء إدخال قيمة أقل من أو تساوي 100",
  "Release date": "تاريخ الإصدار",
  "Due date": "تاريخ الاستحقاق",
  "Price WT": "المبلغ قبل الضريبة",
  "Taxe Rate": "نسبة ضريبة القيمة المضافة",
  Price: "المبلغ شامل الضريبة",
  "Last actions": "الإجراءات الأخيرة",
  Today: "اليوم",
  Yesterday: "أمس",
  "Last week": "الأسبوع الماضي",
  "Estimated Execution":
    " بناءً على معايير الاختيار، الوقت المقدر لتنفيذ هذه العملية هو",
  "Use Historical data": "استخدام البيانات التاريخية؟",
  "Update using historical data": "تحديث باستخدام البيانات التاريخية",
  "Choose the period":
    " : اختر الفترة المراد مراعاتها عند حساب الوقت المقدر لتنفيذ هذه العملية",
  Status: "الحالة",
  "Execution.time": "وقت التنفيذ",
  "Default Alerts": "تنبيهات افتراضية",
  "Choose frequency": "اختر التكرار",
  "View default alerts": "عرض التنبيهات الافتراضية",
  "Edit default alerts": "تعديل التنبيهات الافتراضية",
  "Delete default alert": "حذف التنبيه الافتراضي",
  "Add default alert": "إضافة تنبيه افتراضي",
  "Resource Administration": "إدارة الروبوتات",
  "Manage resource": "إدارة الروبوت",
  "Custom Alerts": "تنبيهات مخصصة",
  "View custom alerts": "عرض التنبيهات المخصصة",
  "Edit custom alerts": "تعديل التنبيهات المخصصة",
  "Delete custom alert": "حذف التنبيه المخصص",
  "Add custom alert": "إضافة تنبيه مخصص",
  Date: "تاريخ",
  "My tasks": "فواتيرى",
  "Invoices to verify": "للتحقق",
  "Invoices to be checked": "للتحقق من صحة",
  "Invoices to Payers": "جاهز للدفع",
  "Paid invoices": "مدفوع",
  "Unpaid invoices": "غير مدفوع",
  "Total amount of invoices": "إجمالي الفواتير",
  "Total VAT amount": "Total VAT",
  "Statistical overview of purchases": "سجل الفواتير",
  "Statistical overview of product sales":
    "نظرة عامة إحصائية على مبيعات المنتجات",
  "Total Purchases": "إجمالي طلبات الشراء",
  "Total of expenses": "إجمالي الفواتير",
  Late: "متأخر",
  "restriction.form.resources": "الروبوتات",
  "restriction.form.priority": " أولوية",
  Day: "يوم",
  Week: "أسبوع",
  "3 DAYS": "3 أيام",
  "restriction.form.priority.label": "أولوية عمليتك",
  "restriction.form.executionTimeLine": "وقت التنفيذ المسموح به",
  "restriction.form.executionDay": "يوم التنفيذ",
  "restriction.form.executionTime": "وقت التنفيذ",
  "restriction.form.from": "من",
  "restriction.form.to": "إلى",
  "restriction.form.estimatedExecutionTime": "الوقت المقدر للتنفيذ",
  "restriction.form.estimatedTime": "الوقت المقدر",
  "restriction.userGuide.resources":
    "يشير إلى الآلات (الروبوتات) التي يمكن تشغيل هذه العملية عليها. لإضافة الروبوتات المؤهلة، يرجى التواصل مع فريق RPA.",
  "restriction.userGuide.priority":
    " SLAs سيتم تنفيذ العملية ذات الأولوية العالية (الرقم الأقل) أولاً، عندما تتساوى جميع",
  "sla.update.success": "تم تحديث SLAs بنجاح.",
  "restriction.userGuide.executionTimeLine":
    "اختر الأيام والأوقات التي يُسمح فيها بتشغيل هذه العملية",
  "restriction.userGuide.estimatedExecutionTime":
    "الوقت المتوسط الذي يستغرقه إكمال هذه العملية.",
  "restriction.userGuide.allowedSession":
    "عدد التشغيلات المتوازية المسموح بها لهذه العملية، إذا كان هناك عدد كافٍ من التراخيص والأجهزة المتاحة لتشغيلها.",
  "restriction.userguide.frequencyOccurence":
    "التردد الأدنى لتنفيذ العملية المطلوب تشغيلها.",
  "sla.update.error": "فشل تحديث SLAs.",
  Administrator: "المشرف",
  "administration.status": "الحالة",
  "administration.company": "الشركة",
  "administration.userId": "تعريف المستخدم",
  "administration.name": "الاسم",
  "administration.creationDate": "تاريخ الإنشاء",
  "administration.role": "Role",
  "administration.email": "البريد الإلكتروني",
  "administration.phone": "الهاتف",
  "administration.departement": "قسم",
  "administration.subscription": "اشتراك",
  "administration.activate.deactivate.user.message":
    "هل أنت متأكد أنك تريد تفعيل / إلغاء تفعيل هذا المستخدم؟",
  "administration.orchestrator.resource.list.not.available":
    "لا توجد روبوتات متاحة",
  "administration.orchestrator.process.list.not.available":
    "لا توجد عمليات متاحة",
  "user.add.civility": "لقب (مدني)",
  "user.add.lastName": "الاسم الاخير",
  "user.add.firstName": "الاسم الأول",
  "user.add.creationDate": "تاريخ الإنشاء",
  "user.add.entreprise": "شركة",
  "user.add.subscription": "الاشتراك",
  "user.add.email": "البريد الإلكتروني",
  "user.add.pass1": "كلمة المرور",
  "user.add.pass2": "كرر كلمة المرور",
  "user.add.comm.lang": "لغة التواصل",
  "user.add.comm.lang.helperText":
    "ستكون جميع الاتصالات ورسائل البريد الإلكتروني المرسلة من SmartRoby باللغة المحددة",
  "New suppliers": "الموردين الجدد",
  "user.add.process": "عملية",
  "user.add.availableProcess": "العمليات المتاحة",
  "user.add.affectedProcess": "العمليات المعينه",
  "user.add.button": "إضافة مستخدم",
  "user.add.mfa": "مطلوب OTP",
  "user.add.division.admin": "مشرف القسم",
  "user.add.fleet.admin": "مشرف المجموعة",
  "user.add.division": "قسم",
  "user.status.active": "ممكن",
  "user.modify": "تعديل",
  "process.modify": "تعديل",
  "process.hidden": "مخفي",
  "user.add": "إضافة",
  "user.button.save": "إضافة مستخدم",
  "user.button.update": "تحديث",
  "user.button.cancel": "إلغاء",
  "user.add.processus": "عملية",
  "user.status.disable": "غير نشط",
  "user.title.add": "إضافة مستخدم",
  "user.title.details": "تفاصيل المستخدم",
  "user.save.confirmMsg": "هل أنت متأكد أنك تريد إضافة هذا المستخدم؟",
  "user.update.confirmMsg": "هل أنت متأكد أنك تريد تحديث تفاصيل هذا المستخدم؟",
  "user.delete.confirmMsg": "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
  "user.delete.discard": "هل أنت متأكد أنك تريد تجاهل هذه التغييرات؟",
  "orchestrator.button.save": "أضف المنظم",
  orchestrator: "المنظم",
  "orchestrator.save.confirmMsg": "هل أنت متأكد أنك تريد إضافة هذا المنظم؟",
  "orchestrator.update.confirmMsg":
    "هل أنت متأكد أنك تريد تحديث تفاصيل هذا المنظم؟",
  "exception.type": "نوع الاستثناء",
  "exception.time": "وقت الاستثناء",
  Mr: "السيد",
  Mrs: "السيدة",
  Ms: "الآنسة",
  "tooltip.action.delete": "حذف",
  "tooltip.action.edit": "تعديل",
  "tooltip.action.view": "عرض",
  "tooltip.action.add": "إضافة",
  "Visualise Scheduler": "عرض المجدول",
  firstName: "الاسم الأول",
  lastName: "الاسم الاخير",
  companyName: "الشركة",
  "720 hours/year": "720 ساعة / سنة",
  "1800 hours/year": "1800 ساعة / سنة",
  "60 days/year": "60 يوم / سنة",
  "120 days/year": "120 يوم / سنة",
  "3 months/year": "3 شهور / سنة",
  "5 months/year": "5 أشهر / سنة",
  "user.roles.admin": "مشرف",
  "user.roles.user": "مستخدم",
  "sorted descending": "مرتبة تنازليًا",
  "sorted ascending": "مرتبة تصاعديًا",
  Invoicing: "الفواتير",
  Alerts: "التنبيهات",
  "User Management": "إدارة المستخدمين",
  "Audit Logs": "سجلات التدقيق",
  Documentation: "توثيق",
  Services: "الخدمات",
  "Carte d'identité nationale": "تعريف البطاقة الوطنية",
  "Driving license": "رخصة القيادة",
  Passport: "جواز السفر",
  "Green card": "بطاقة إثبات شخصية ",
  "Car registration papers": "أوراق تسجيل السيارة",
  Facture: "فاتورة",
  Invoice: "فاتورة",
  RIB: "كشف حساب بنكي",
  "Purchase order": "طلب الشراء",
  "Payroll statemenet": "بيان كشوف المرتبات",
  "Avis d'imposition": "إشعار ضريبي",
  "Trigger Details": "تفاصيل المشغل",
  "Start Process": "بدء العملية",
  "Stop Process": "اوقف",
  Schedule: "جدولة",
  Prioritize: "تحديد الأولويات",
  Sheet: "ورقة",
  Parameters: "الاعدادات",
  "Processes launcher": "مشغل العمليات",
  "Drop process": "اسقط هنا لبدء العملية",
  "Time saved": "الوقت الموفر",
  "Total time saved": "إجمالي الوقت الموفر",
  "Process overview": "وقت التنفيذ حسب العملية",
  "Resource overview": "وقت التنفيذ حسب الروبوت",
  "Number of executions": "عدد التنفيذات",
  "Latest Transcriptions": "أحدث النصوص المحولة",
  "Break-even": "نقطة التعادل",
  "Return on Investment": "معدل الربحية",
  // smart ocr services
  "Analyze a new file": "تحليل ملف جديد",
  Receipt: "وصل",
  "EDF proof": "دليل EDF",
  "Click here or drop a file to download": "انقر هنا أو قم بإسقاط ملف للتنزيل",
  "Loaded File": "ملف محمل",
  "File preview": "معاينة ملف",
  Informations: "المعلومات",
  "Document name": "اسم الوثيقة",
  "File content type": "نوع محتوى الملف",
  userId: "معرف المستخدم",
  "First name": "الاسم الأول",
  "Last name": "الاسم الاخير",
  CIN: "CIN",
  "Date of Birth": "تاريخ الميلاد",
  "Place of birth": "مكان الميلاد",
  "Expiration date": "تاريخ انتهاء الصلاحية",
  Address: "العنوان",
  Gender: "الجنس",
  CivilStatusNo: "رقم الحالة المدنية",
  "Use of APIs": "استهلاك الخدمات",
  "under construction":
    "هذا القسم قيد الإنشاء ، فريقنا يعمل عليه. ابقوا متابعين..",
  Overview: "نظرة شاملة",
  Historical: "تاريخي",
  "General Information": "معلومات عامة",
  "Schedule Information": "معلومات الجدول",
  Description: "الوصف",
  processOriginalName: "اسم العملية الأصلي",
  Priority: "أولوية",
  "Start date": "تاريخ البداية",
  "End date": "تاريخ الانتهاء",
  Cron: "كرون",
  "FTE Time to Process": "FTE الوقت اللازم للمعالجة",
  "FTE Cost": "تكلفة FTE",
  "Process cost": "تكلفة العملية",
  Add: "إضافة",
  Reset: "إعادة ضبط",
  Update: "تحديث",
  Save: "حفظ",
  Cancel: "إلغاء",
  Confirm: "تأكيد",
  "Are you sure delete this row ?": "هل أنت متأكد من حذف هذا الصف؟",
  "Field must not be empty": "يجب ألا يكون الحقل فارغًا",
  "This process was started successfully": "بدأت هذه العملية بنجاح",
  "The process has been added to the SR Queues.":
    "تمت إضافة التنفيذ المطلوب إلى قائمة انتظار SR.",
  "The process start failed! Please try again later":
    "فشلت العملية! الرجاء معاودة المحاولة في وقت لاحق",
  ERRORS_EMPTY_FIELD: "يجب ألا يكون الحقل فارغًا.",
  ERRORS_LESS_THAN_MIN_FIELD: "من فضلك أدخل قيمة أكبر من {{ limit }}",
  ERRORS_INVALID_DATA: "من فضلك أدخل بيانات صحيحة",
  ERRORS_NO_DATA: "لا توجد بيانات متاحة!",
  WEIGHTS_SUM_MUST_BE_100: "يجب أن يكون مجموع الأوزان 100٪",
  "Start date must be less than the end date":
    "يجب أن يكون تاريخ البدء أقل من تاريخ الانتهاء",
  "End date must be greater than the start date":
    "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء",
  "Start time": "وقت البداية",
  "End time": "وقت الانتهاء",
  "How much time does it take an employee to execute this process?":
    "م من الوقت يستغرقه الموظف لتنفيذ هذه العملية؟",
  "How much does the hour cost for an employee executing this task?":
    "كم تكلفة الساعة للموظف الذي ينفذ هذه المهمة؟",
  "How much does this process cost per month ?":
    "كم تبلغ تكلفة هذه العملية شهريًا؟",
  Recurrence: "تكرار",
  Previous: "السابق",
  // authentication page
  "Enter your mail": "أدخل بريدك",
  "Confirm your mail": "قم بتأكيد بريدك",
  "Enter your password": "أدخل كلمة المرور الخاصة بك",
  Login: "تسجيل الدخول",
  "Sign up to SmartRoby": "قم بالتسجيل في SmartRoby",
  "Go to SmartRoby": "اذهب إلى Smartroby",
  "Registration was successfull, you will be redirected":
    "تم التسجيل بنجاح، سيتم إعادة التوجيه",
  "Registration failed": "فشل في التسجيل",
  Close: "أغلق",
  "Aws registration token missing": "رمز تسجيل Aws مفقود",
  "Email not matching": "البريد الإلكتروني غير مطابق",
  Register: "قم بالتسجيل",
  "You are already signed up": "لقد قمت بالتسجيل بالفعل",
  "Forgot your password ?": "نسيت كلمة المرور؟",
  "Login or password incorrect !": "اسم المستخدم أو كلمة المرور غير صحيحة!",
  "Copyright © Smart-Roby  All rights reserved.":
    "حقوق النشر © Smart-Roby جميع الحقوق محفوظة.",
  English: "الإنجليزية",
  French: "الفرنسية",
  Spanish: "الإسبانية",
  Polish: "البولندية",
  Germany: "الألمانيه",
  "Process executions": "التنفيذات",
  Ready: "جاهز",
  "Executed by": "تم التنفيذ من قبل",
  "you want to modify this data ?": "هل أنت متأكد أنك تريد تعديل هذه البيانات؟",
  Yes: "نعم",
  No: "لا",
  "Data saved with success": "تم حفظ البيانات بنجاح",
  "Data not saved": "لم يتم حفظ البيانات",
  "Hour(s)": "ساعة / ساعات",
  "Day(s)": "يوم / أيام",
  "Week(s)": "أسبوع / أسابيع",
  "Month(s)": "شهر / أشهر",
  "Year(s)": "سنة / سنوات",
  Every: "كل",
  "Choose period": "اختر الفترة",
  "Your input is required": "إدخالك مطلوب",
  "The process": "العملية",
  "is running, try again when it's completed.":
    "قيد التشغيل ، حاول مرة أخرى عند اكتماله.",
  "Are you sure you want to execute this process ?":
    "هل أنت متأكد أنك تريد تنفيذ هذه العملية؟",
  Domain: "نطاق",
  "Process name": "اسم العملية",
  "Process reference": "مرجع العملية",
  "Number used of systems and applications": "عدد الأنظمة والتطبيقات المستخدمة",
  "SAP environments (if applicable)": "بيئات SAP (إن أمكن)",
  Frequency: "تردد",
  Volume: "حجم",
  "Execution per": "تنفيذ لكل",
  "Rule-based": "على أساس القواعد",
  Standardization: "التوحيد",
  "Structured data": "البيانات المنظمة",
  "IT environment": "بيئة تقنية المعلومات",
  "Test enabled": "تم  تفعيل الاختبار",
  "Documentation Readiness": "جاهزية الوثائق",
  "Authentication mode": "وضع المصادقة",
  "Time spent for one execution": "الوقت المستغرق في تنفيذ واحد",
  "Frequency of occurence": "عدد مرات الحدوث",
  "Time spent": "الوقت المستغرق",
  "Time granularity": "دقة وقت التنفيذ",
  "Occurence time granularity": "دقة وقت الحدوث",
  Occurrence: "الحدوث",
  "Process Exceptions": "استثناءات العملية",
  "Item Exceptions": "استثناءات العنصر",
  "Occurrence driver": "سبب الحدوث",
  "Total time spent in hours per year":
    "إجمالي الوقت المستغرق بالساعات في السنة",
  "Re-usability": "إعادة الاستخدام",
  "Expected qualitative benefits": "الفوائد النوعية المتوقعة",
  "Process Stability": "استقرار العملية",
  "Number of steps": "عدد الخطوات",
  "Number of windows": "عدد النوافذ",
  "Business Rules": "قواعد العمل",
  "Business Applications": "تطبيقات العمل",
  "Complex functions - Unstructured data / OCR":
    "وظائف معقدة - بيانات غير منظمة / OCR",
  "Complex functions - DataBase": "وظائف معقدة - قاعدة البيانات",
  "Complex functions - Powershell": "وظائف معقدة - Powershell",
  "Complex functions - Active Directory": "الوظائف المعقدة - Active Directory",
  "Complex functions - VB Script": "الوظائف المعقدة - VB Script",
  "Complex functions - Web Services": "وظائف معقدة - خدمات الويب",
  "Type of applications - Web application": "نوع التطبيقات - تطبيق ويب",
  "Type of applications - Windows application": "نوع التطبيقات - تطبيق Windows",
  "Type of applications - Mainframe application":
    "نوع التطبيقات - تطبيق Mainframe",
  "Type of applications - Citrix application": "نوع التطبيقات - تطبيق Citrix",
  "Automation without RPA": "أتمتة بدون تقنية RPA",
  "assessment.Basic documentation is available": "التوثيق الأساسي متاح",
  "assessment.Few simple applications with a unique ERP instance custom application":
    "بعض التطبيقات البسيطة مع تطبيق شخصي لنسخة فريدة من  (ERP).",
  "assessment.No - Judgment based": "لا - استند إلى الحكم",
  "assessment.Yes - Based on objective criteria":
    "نعم - بناءً على معايير موضوعية",
  Accounting: "المحاسبة",
  Controlling: "المتابعة",
  Other: "آخر",
  Others: "آخرين",
  "assessment.Yes - Completely based on objective criteria":
    "نعم - تستند بالكامل إلى معايير موضوعية",
  "assessment.No - Completely judgment-based": "لا - تستند بالكامل إلى الحكم",
  "assessment.Completely optimized process, managed at Group level":
    "عملية محسّنة بالكامل ، تدار على مستوى المجموعة",
  "assessment.Largely standardized process, mostly managed at group level":
    "عملية موحدة إلى حد كبير ، تدار في الغالب على مستوى المجموعة",
  "assessment.Good level of standardisation, managed regionaly":
    "مستوى جيد من التوحيد، مُدار على مستوى إقليمي.",
  "assessment.Low degree of standardization and split responsibilities":
    "درجة منخفضة من التوحيد وتقسيم المسؤوليات.",
  "assessment.Disaggregated process and split responsibilities":
    "عمليات منفصلة وتقسيم المسؤوليات.",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "نعم - منظم بشكل كامل (مثل في نموذج/جدول/جدول بيانات).",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "لا - غير منظم تمامًا، مثل البريد الإلكتروني غير الموحد.",
  "assessment.Few simple applications (Excel, Outlook, …)":
    "نعم - بعض التطبيقات البسيطة (مثل إكسل، أوتلوك، ...).",
  "assessment.Few simple applications with a unique ERP instance or another / custom application":
    "نعم - بعض التطبيقات البسيطة مع نسخة SAP فريدة أو تطبيق مخصص آخر.",
  "assessment.Few complex / custom applications or ERP instances":
    "عدد قليل من التطبيقات المعقدة / المخصصة أو مثيلات SAP",
  "assessment.Several complex / custom applications, and a cloud application":
    "عدة تطبيقات معقدة / مخصصة، وتطبيق سحابي.",
  "assessment.Large number of applications, (i.e. several ERP instances)":
    "عدد كبير من التطبيقات (مثل العديد من حالات تطبيق ERP).",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
    "نعم - البيانات المجمعة متاحة بالكامل لإجراء العديد من الاختبارات.",
  "assessment.No - No Data-sets available to conduct multiple tests":
    "لا - لا تتوفر مجموعات بيانات لإجراء اختبارات متعددة.",
  "assessment.A detailed documentation is available": "وثائق مفصلة متاحة",
  "assessment.A high level documentation is available":
    "يتوفر توثيق عالي المستوى",
  "assessment.No documentation": "لا توجد وثائق",
  "assessment.Standard authentication mode (ID and password)":
    "وضع المصادقة القياسي (المعرف وكلمة المرور)",
  "assessment.SSO only authentication mode": "وضع المصادقة SSO فقط",
  "assessment.Additional authentication required (e.g. USB key)":
    "مصادقة إضافية مطلوبة (مثل مفتاح USB)",
  Seconds: "ثانية",
  Minutes: "دقائق",
  Minute: "دقيقة",
  Hours: "ساعات",
  Days: "أيام",
  "no execution in this period": "لم يتم العثور على أي تنفيذ",
  "unit.manday": "m/d",
  "assessment.Fully Core process compliant":
    "متوافق مع العمليات الأساسية بالكامل",
  "assessment.Partially Core process compliant":
    "متوافقة جزئيا مع العملية الأساسية",
  "assessment.Local (zone or country specific)": "محلي (منطقة أو بلد محدد) ",
  "assessment.Major": "كبير",
  "assessment.High": "عالي",
  "assessment.Medium": "متوسط",
  "assessment.Low": "منخفض",
  "assessment.Minor": "صغير",
  "assessment.No change is planned": "لا يوجد تغيير مخطط",
  "assessment.No change is planned in the next 2 years":
    "لا يوجد تغيير مخطط له في العامين المقبلين",
  "assessment.Process/system may change in next 2 years":
    "قد تتغير العملية / النظام في العامين المقبلين",
  "assessment.Process/system update planned in next 2 years":
    "تحديث العملية / النظام المخطط له في العامين المقبلين",
  "assessment.Frequent changes occur in the process":
    "تغييرات متكررة تحدث في هذه العملية",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "أكثر من 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "أكثر من 20",
  "assessment.Between 2 and 3": "بين 2 و 3",
  "assessment.Between 4 and 10": "بين 4 و 10",
  "assessment.More than 10": "أكثر من 10",
  "assessment.Between 4 and 5": "بين 4 و 5",
  "assessment.More than 5": "أكثر من 5",
  "assessment.1": "1",
  true: "true",
  false: "false",
  "planned.executions": "عمليات التنفيد المخطط لها",
  resource: "الروبوت",
  "planner.fullScreenExit": "اخرج في وضع ملء الشاشة",
  "planner.fullScreen": "افتح المخطط في وضع ملء الشاشة",
  "Risk management": "هامش الخطر",
  "planner.risktime": "وقت الخطر",
  "Latest Exceptions": "أحدث الاستثناءات",
  Process: "عملية",
  Executing: "جاري التنفيذ",
  Planned: "مخطط له",
  Paused: "متوقف",
  "Rows Per page": "عدد الصفوف في كل صفحة",
  "pagination.of": "من",
  Completed: "مكتمل",
  Running: "قيد التشغيل",
  RUNNING: "قيد التشغيل",
  "How long will it take an employee to complete this process?":
    "كم من الوقت سيستغرق الموظف لإكمال هذه العملية؟",
  "How much does it cost per hour for an employee to perform this task?":
    "ما هي تكلفة الموظف في الساعة لأداء هذه المهمة؟",
  unknown: "غير معروف",
  Unknown: "غير معروف",
  UNKNOWN: "غير معروف",
  "This Week": "آخر 7 أيام",
  "This Month": "آخر 30 يوماً",
  "This Year": "آخر 365 يوماً",
  "All time": "كل الوقت",
  Discard: "تجاهل",
  "Exception Reason": "سبب الاستثناء",
  "Exception Workflow Status": "الحالة",
  "Exception Assignee": "المكلف",
  More: "المزيد",
  Less: "أقل",
  "Hide filters": "إخفاء الفلاتر",
  READY: "غير معالج",
  TAKEN: "تعيين",
  Name: "الاسم",
  createdBy: "تم الإنشاء من قبل",
  updatedBy: "تم التعديل الأخير من قبل",
  "Last Update ": "آخر تحديث",
  "Last update": "تاريخ التعديل الأخير",
  Feasibility: "الجدوى",
  Impact: "التأثير",
  Roi: "نسبة الربحية",
  Accepted: "مقبول",
  Rejected: "مرفوض",
  Pending: "معلق",
  PENDING: "معلق",
  DELETED: "محذوف",
  MISSED: "مفقود",
  MISSED_P: "معلق (مفقود)",
  PENDING_SR: "معلق SR",
  DONE: "تمت المعالجة",
  EXCEPTION: "استثناء",
  "Exception Type": "نوع الاستثناء",
  "My cases": "حالاتي",
  "Schedule Name": "اسم الجدول",
  Period: "الفترة",
  Exceptions: "استثناءات",
  "header.kpi.exceptions": "الاستثناءات التي لم تتم معالجتها",
  Actions: "اجراءات",
  "Loaded Time": "وقت التحميل",
  Reason: "السبب",
  "Exception Time": "وقت الاستثناء",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "كم من الوقت يستغرق الموظف لتنفيذ عنصر في هذه العملية؟ (بالدقائق)",
  "How much does this Process cost to maintain per Year?":
    "كم تكلفة هذه العملية للمحافظة عليها في السنة؟",
  "Add tag": "إضافة وسم",
  "Edit tag": "تحديث وسم",
  "Delete tag": "حذف وسم",
  "Tags Module": "الوسوم",
  Type: "النوع",
  "Delete Item": "حذف العنصر",
  "Are you sure you want to delete this cost ?":
    "هل أنت متأكد أنك تريد حذف هذه التكلفة؟",
  "This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing parameters. If you are sure you want to delete this cost from all processes, proceed with the delete.":
    "تم مشاركة هذه التكلفة مع عدة عمليات. إذا كنت ترغب في إزالة التكلفة المخصصة لهذه العملية بشكل فردي، يرجى تحرير التكلفة وإزالة العمليات من معلمات المشاركة. إذا كنت متأكدًا من رغبتك في حذف هذه التكلفة من جميع العمليات، فاستمر في الحذف.",
  "Cost Configuration": "تكوين التكلفة",
  "Cost Name": "اسم التكلفة",
  "Cost Category": "فئة التكلفة",
  Category: "الفئة",
  "Recurring ": "متكررة",
  "Cost Type": "نوع التكلفة",
  Weight: "الوزن",
  "Add cost": "إضافة تكلفة",
  "Cost Allocation": "تخصيص التكلفة",
  "Delete cost": "حذف التكلفة",
  "Edit cost": "تعديل التكلفة",
  "Allocation Amount": "مبلغ التخصيص",
  "Shared ": "مشترك",
  shared: "مشترك",
  "FTE hourly cost": "تكلفة الساعة للعامل بدوام كامل",
  "Save ": "حفظ",
  "Cancel ": "إلغاء",
  "Time saved for each completed item of this process (in minutes)":
    "الوقت الموفر لكل عنصر تم إكماله في هذه العملية (بالدقائق)",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)":
    "الوقت الموفر لكل تنفيذ لهذه العملية باستثناء الوقت الموفر من كل عنصر (بالدقائق)",
  "Cost Shared": "تكاليف مشتركة",
  "Cost Amount": "مبلغ التكلفة",

  "Are you sure you want to update the parameters ?":
    "هل أنت متأكد أنك تريد تحديث الاعدادات؟",
  "Are you sure you want to create this schedule ?":
    "هل أنت متأكد أنك تريد إنشاء هذا الجدول؟",
  "Are you sure you want to update this schedule ?":
    "هل أنت متأكد أنك تريد تحديث هذا الجدول؟",
  "The schedule was created successfully": "تم إنشاء الجدول الزمني بنجاح",
  "Failed to create the schedule! Please try again later":
    "فشل إنشاء الجدول! من فضلك حاول مرة أخرى لاحقا",
  "Failed to update parameters":
    "فشل تحديث الإعدادات. الرجاء التحقق والمحاولة مرة أخرى.",
  "Are you sure you want to disable this schedule ?":
    "هل أنت متأكد من رغبتك في تعطيل هذا الجدول الزمني؟",
  "Are you sure you want to enable this schedule ?":
    "هل أنت متأكد من رغبتك في تفعيل هذا الجدول الزمني؟",
  "button.deactivate.confirm": "إلغاء تفعيل",
  "button.activate.confirm": "تفعيل",
  hours: "ساعة",
  Hourly: "كل ساعة",
  Daily: "يوميا",
  Weekly: "أسبوعيا",
  Monthly: "شهريا",
  Yearly: "سنويا",
  Trimestrial: "ثلث سنوي ",
  Quarterly: "ربع سنوي",
  "Start Date": "تاريخ البداية",
  "Are you sure you want to save the changes ?":
    "هل أنت متأكد من رغبتك في حفظ التغييرات؟",
  "Failed to modify schedule": "فشل في تعديل الجدول الزمني",
  "Are you sure you want to disregard the changes ?":
    "هل أنت متأكد أنك تريد تجاهل التغييرات؟",
  "Changes disregarded": "تم تجاهل التغييرات",
  "Are you sure you want to delete this exception ?":
    "هل أنت متأكد أنك تريد إخفاء هذا الاستثناء؟",
  "Are you sure you want to show this exception ?":
    "هل أنت متأكد أنك تريد إظهار هذا الاستثناء؟",
  "Are you sure you want to delete these exception ?":
    "هل أنت متأكد أنك تريد حذف هذه الاستثناءات؟",
  "Exception Deleted Successfully": "تم إخفاء الاستثناء بنجاح!",
  "Exception Restored Successfully": "تمت استعادة الاستثناء بنجاح!",
  "Exceptions Deleted Successfully": "تم إخفاء الاستثناءات بنجاح",
  "Are you sure you want to delete": "هل أنت متأكد أنك تريد إخفاء",
  "Are you sure you want to show X exceptions?":
    "هل تريد بالتأكيد إظهار {{count}} استثناء (استثناءات)؟",
  exceptions: "استثناء (استثناءات)",
  "exceptions successfully deleted": "تم إخفاء الاستثناءات بنجاح",
  "X exceptions successfully restored":
    "تمت استعادة {{count}} استثناء (استثناءات) بنجاح",
  // rpa qual
  Submitted: "تم الإرسال",
  Draft: "مسودة",
  draft: "مسودة",
  "Not submitted": "لم يتم الإرسال",
  "Are you sure?": "هل أنت متأكد؟",
  "You won't be able to revert this!": "لن تتمكن من التراجع عن هذا!",
  warning: "تحذير",
  "Yes, submit it!": "نعم ، أرسلها!",
  "Yes, save it!": "نعم ، احفظه!",
  "Submit later": "تحقق لاحقًا",
  Back: "عودة",
  Result: "نتيجة",
  "Impact/Feasibility Matrix": "مصفوفة التأثير / الجدوى",
  next: "التالي",
  "Red zone": "المنطقة الحمراء",
  "Impact and feasibility are limited": "العملية معقدة للتأتير بحد أدنى",
  "The process is likely to be reprioritized": "يُفضل إعادة ترتيب هذه العملية ما لم يتم اتخاذ معايير أخرى في الوساطة",
  "Yellow zone": "المنطقة الصفراء",
  "Here either the impact or the feasibility is relevant": "تُظهر إمكانية التأثير أو القابلية للتنفيذ هنا إشارات كبيرة",
  "Depending on the case and arbitration, the process could be deprioritized": "قد يتم ترتيب هذه العملية بحسب الحالة والوساطة مع معايير أخرى",
  "Green zone": "المنطقة الخضراء",
  "Both impact and feasibility show some great signs": "تظهر كل من إمكانية التأثير والقابلية للتنفيذ إشارات رائعة",
  "The process is likely to be prioritized": "يُفضل ترتيب هذه العملية بحسب الأولوية",
  "Saving (per year)": "تقدير المكاسب (سنويا)",
  "FTE Gains": "مكاسب FTE",
  "FTE Costs": "تكاليف FTE",
  "Total in $": "المجموع بالدولار",
  "Developement costs": "تقدير تكاليف التطوير (ب m/ d)",
  Developer: "المبرمج",
  "Business analyst": "محلل الأعمال",
  "Running costs (per year)": "تكاليف التشغيل (سنويا)",
  "Running costs in $": "تكاليف التشغيل بالدولار",
  "Payback period (year)": "فترة الاسترداد (سنة)",
  "Yes, accept it": "نعم، اقبله",
  "Yes, reject it": "نعم، ارفضه",
  "Yes, delete it": "نعم ، احذفه",
  NAME: "الاسم",
  "CREATED BY": "انشأ من قبل",
  "Date created": "تاريخ الإنشاء",
  "LAST UPDATE": "آخر تحديث",
  Edit: "تعديل",
  Exit: "خروج",
  "Home - RPA Qualification Tool By Novelis innovation":
    "الصفحة الرئيسية - أداة تأهيل RPA من ابتكار Novelis",
  "No opportunities found !": "لم يتم العثور على أي فرص!",
  "Add new opportunity": "أضف فرصة جديدة",
  "No cases found !": "لم يتم العثور على حالات!",
  "Tool for discovering, evaluating and classifying business processes":
    "أداة لاكتشاف وتقييم وتصنيف العمليات التجارية",
  "Sign in": "تسجيل الدخول",
  "Invalid email address": "البريد الإلكتروني غير صحيح",
  "Email Address": "البريد الإلكتروني",
  "Password is required": "كلمة المرور مطلوبة",
  "Password must contains at least 8 characters":
    "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
  Password: "كلمة المرور",
  "No network": "لا يوجد شبكة",
  "Please verify your internet connection and try again !":
    "يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى!",
  OK: "نعم",
  "New process": "عملية جديدة",
  "Edit process": "تحديث العملية",
  "Page not found": "الصفحة غير موجودة",
  "Sorry, the page you are looking for can't be found !":
    "عذرا ، الصفحة التي تبحث عنها لا يمكن العثور عليها!",
  Reject: "رفض",
  Submit: "إرسال",
  "rpaQual.action.submit": "تحقق",
  "assessment.submit": "إرسال",
  Accept: "قبول",
  Delete: "حذف",
  "priority.error": "القيمة موجودة بالفعل ، الرجاء اختيار قيمة أخرى.",
  "priority.error.unhide":
    "قيمة الأولوية لهذه العملية موجودة بالفعل. لن يتم إخفاء العملية الخاصة بك دون تحديد أي أولوية.",
  "rpaqual.submit": "تحقق",
  "Time period": "فترة زمنية",
  "Select a Department": "حدد القسم",
  "name your process": "اسم العملية الخاصة بك",
  "Select a resource": "حدد الروبوت",
  "describe briefly your process": "صف بإيجاز العملية الخاصة بك",
  "What is the value that would drive a round of process executions":
    "ما هي القيمة التي ستقود جولة من عمليات التنفيذ",
  "Select if process is rule-based":
    "حدد ما إذا كانت العملية قائمة على القواعد",
  "Select if process is standardized": "حدد ما إذا كانت العملية موحدة",
  "Select if data used in the process is structured ":
    "حدد ما إذا كانت البيانات المستخدمة في العملية منظمة",
  "Select the sentence that fits the most with the context of your process":
    "حدد الجملة الأكثر ملاءمة لسياق عمليتك",
  "Select if testing will be possible": "حدد ما إذا كان الاختبار سيكون ممكنًا",
  "the documentation level that fits the most with the context of your process":
    "مستوى التوثيق الأكثر ملاءمة لسياق عمليتك",
  "what authentication mode is applied for the systems in scope":
    "ما هو وضع المصادقة المطبق على الأنظمة الموجودة في النطاق",
  "Time required for a single but complete process execution":
    "الوقت اللازم لتنفيذ عملية واحدة ولكنها كاملة",
  "Time granularity of the above mentionned time":
    "الدقة الزمنية للوقت المذكور أعلاه",
  "Weekly=52, Monthly=12, Daily=220.Details of your calculations in the comment section":
    "أسبوعيًا = 52، شهريًا = 12، يوميًا = 220",
  "This field is populated automatically from the details time spent below":
    "يتم ملء هذا الحقل تلقائيًا من وقت الإنفاق المفصل أدناه",
  "Assess re-usability to the best of your knowledge":
    "قيِّم قابلية الإعادة باستناد إلى أفضل معرفتك",
  "Explain what are the benefits expected, e.g. better quality":
    "شرح ما هي الفوائد المتوقعة، مثل تحسين الجودة",
  "Select the number of steps": "اختر عدد الخطوات",
  "number of screens that change during the execution ":
    "عدد الشاشات التي تتغير خلال التنفيذ",
  "number of  the business rules governing the process":
    "اختر عدد قواعد العمل الحاكمة للعملية",
  "number of business applications involved in the process":
    "عدد تطبيقات الأعمال المشتركة في العملية",
  "whether unstructured data or any OCR processing will be processed":
    "سيتم معالجة البيانات غير المُهيكلة أو أي معالجة OCR",
  "communication with a database to establish or any sql commands will be executed":
    "التواصل مع قاعدة البيانات لإنشاء أو تنفيذ أي أوامر SQL",
  "if any Powershell script to install or any powershell code to execute in the command line":
    "إذا كان هناك أي سكربت PowerShell للتثبيت أو أي كود PowerShell للتنفيذ في سطر الأوامر",
  "if the resources will have a Windows account and communicate by email or Teams":
    "إذا كانت الروبوتات ستمتلك حساب ويندوز وتتواصل عبر البريد الإلكتروني أو تيمز",
  "if there is a need to create VB scripts or run macros directly on blue prism ":
    "إذا كان هناك حاجة لإنشاء نصوص VB أو تشغيل الماكروهات مباشرة على بلو بريزم",
  "if there is a possibility to use web services or to expose them for other processes":
    "إذا كان هناك إمكانية لاستخدام خدمات الويب أو عرضها للعمليات الأخرى",
  "if the process interacts with web applications via browsers":
    "إذا كانت العملية تتفاعل مع تطبيقات الويب عبر المتصفحات",
  "if the process interacts with Windows applications installed on the computer":
    "إذا كانت العملية تتفاعل مع تطبيقات ويندوز المثبتة على الكمبيوتر",
  "if  there is any application that we access via an emulator ":
    "إذا كان هناك أي تطبيق نصل إليه عبر محاكي ",
  "if  there is any application that we access via the cloud":
    "إذا كان هناك أي تطبيق نصل إليه عبر الكلاود",
  "the number of steps": "عدد الخطوات",
  // scheduler
  "Are you sure you want to disregard this schedule ?":
    "هل أنت متأكد أنك تريد تجاهل هذا الجدول الزمني؟",
  "Schedule disregarded!": "تم تجاهل الجدول!",
  "Are you sure you want to execute this process?":
    "هل أنت متأكد أنك تريد تنفيذ هذه العملية؟",
  "Feasibility/Impact": "الجدوى / التأثير",
  // kpi
  January: "يناير",
  February: "فبراير",
  March: "مارس",
  April: "أبريل",
  May: "ماي",
  June: "يونيو",
  July: "يوليو",
  August: "أغسطس",
  September: "سبتمبر",
  October: "أكتوبر",
  November: "نوفمبر",
  December: "ديسمبر",
  "Weeks ago": "قبل أسابيع",
  "7 Weeks ago": "قبل 7 أسابيع",
  "6 Weeks ago": "قبل 6 أسابيع",
  "5 Weeks ago": "منذ 5 أسابيع",
  "4 Weeks ago": "قبل 4 أسابيع",
  "3 Weeks ago": "قبل 3 اسابيع",
  "2 Weeks ago": "قبل أسبوعين",
  "Last Week": "الأسبوع الماضي",
  "hours ago": "منذ #NB# ساعات",
  "1 hour ago": "قبل ساعة",
  xHoursAgo: "منذ {{hours}} ساعات",
  Monday: "الاثنين",
  Tuesday: "الثلاثاء",
  Wednesday: "الأربعاء",
  Thursday: "الخميس",
  Friday: "الجمعة",
  Saturday: "السبت",
  Sunday: "الأحد",
  "Total executions": "إجمالي التنفيذات",
  "Number of process executions": "عدد التنفيذات",
  "Number of process executions with exceptions":
    "عدد التنفيذات مع استثناءات",
  "Number of process executions completed successfully":
    "عدد التنفيذات التي تم إكمالها بنجاح",
  "Latest activities": "أحدث الأنشطة",
  "Creation date (Descending)": "تاريخ الإنشاء (تنازلي)",
  "Creation date (Ascending)": "تاريخ الإنشاء (تصاعدي)",
  "Exception Time (Descending)": "تاريخ الاستثناء (تنازلي)",
  "Exception Time (Ascending)": "تاريخ الاستثناء (تصاعدي)",
  "End date (Descending)": "تاريخ الانتهاء (تنازلي)",
  "End date (Ascending)": "تاريخ الانتهاء (تصاعدي)",
  "Deferral date (Descending)": "تاريخ التأجيل (تنازلي)",
  "Deferral date (Ascending)": "تاريخ التأجيل (تصاعدي)",
  "Deferral date": "تاريخ التأجيل",
  "Creation date": "تاريخ الإنشاء",
  "Exception date": "تاريخ الاستثناء",
  "Sort By": "الترتيب حسب",
  "Document Name": "اسم المستند",
  "Document Status": "حالة المستند",
  Preview: "Preview",
  "The document was sent to the orchestrator": "تم إرسال المستند إلى المنظم",
  "The process has started successfully": "بدأت العملية بنجاح",
  "Are you sure you want to delete this document ?":
    "هل أنت متأكد أنك تريد حذف هذا المستند؟",
  "Document Deleted Successfully": "تم حذف المستند بنجاح",
  "Show empty fields": "إظهار الحقول الفارغة",
  "Are you sure you want to delete this Opportunity ?":
    "هل أنت متأكد أنك تريد حذف هذه الفرصة؟",
  "Opportunity deleted successfully": "تم حذف الفرصة بنجاح",
  // added by zineb
  "Are you sure you want to start this process ?":
    "هل أنت متأكد من أنك تريد بدء هذه العملية؟",
  START: "البدء",
  Of: "ل",
  FOREVER: "للأبد",
  "Are you sure you want to delete this schedule ?":
    "هل أنت متأكد أنك تريد حذف هذا الجدول؟",
  "Schedule Deleted Successfully": "تم حذف الجدول الزمني بنجاح",
  "No options": "لا يوجد خيارات",
  Clear: "مسح",
  "save as draft": "حفظ كمسودة",
  "Save as draft": "حفظ كمسودة",
  "Save draf": "حفظ المسودة",
  calculate: "احسب",
  Calculate: "احسب",
  // Process Assessment help Added By Zineb
  "Can a defined rule be applied to each step of this process?":
    "هل يمكن تطبيق قاعدة محددة على كل خطوة من هذه العملية؟",
  "To what extent is the process standard across locations?":
    "إلى أي مدى تعتبر العملية قياسية عبر المواقع؟",
  "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "كلما كانت العملية محسّنة ومُدارة مركزيًا ، كلما كانت اختبارات الأتمتة والتنفيذ أسهل.",
  "Are the process inputs provided in a structured manner?":
    "هل مدخلات العملية مقدمة بطريقة منظمة؟",
  "Is a full data set availbale to test this process ?":
    "هل مجموعة البيانات الكاملة متاحة لاختبار هذه العملية؟",
  "What is the level of IT environment complexity?":
    "ما هو مستوى تعقيد بيئة تكنولوجيا المعلومات؟",
  "What level of documentation exists to support this process?":
    "ما هو مستوى التوثيق الموجود لدعم هذه العملية؟",
  "What is scale of the security challenge for authentication?":
    "ما هو حجم التحدي الأمني للمصادقة؟",
  "What is the Time required for a single but complete process execution?":
    "ما هو الوقت المطلوب لتنفيذ عملية واحدة ولكن كاملة؟",
  "What is the frequency which this process occures?":
    "ما هو معدل تكرار هذه العملية؟",
  "What is the value that would drive a round of process executions?":
    "ما هي القيمة التي ستقود جولة من التنفيذات؟",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "يرجى الإشارة إلى طبيعة السائق لحدوث العملية (عدد أكواد الشركة ، الحسابات ، التعيينات ، إلخ.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "أسبوعيًا = 52 ، شهريًا = 12 ، يوميًا = 220 ، إلخ. للترددات المحددة ، يرجى إضافة تفاصيل حساباتك في قسم التعليقات",
  "This field is populated automatically from the details from the Time Spent Section":
    "يتم ملء هذا الحقل تلقائيًا من التفاصيل من قسم الوقت الذي تم إنفاقه",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
    "حدد خطورة الفوائد واذكر في منطقة التعليقات الفوائد المتوقعة ، على سبيل المثال جودة أفضل ومكاسب إنتاجية وتقليل المهلة وما إلى ذلك.",
  "To what extent is this same process performed worldwide?":
    "إلى أي مدى يتم تنفيذ هذه العملية نفسها في جميع أنحاء العالم؟",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "هل تم التخطيط لتغييرات في الأنظمة / الأدوات من شأنها التأثير على هذه العملية؟ (أي تطوير تقرير جديد)",
  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "حدد عدد الخطوات (النقرات والتنقل والقراءة والكتابة في كل نافذة تطبيق) ، إن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "حدد عدد النوافذ (الشاشات) التي تتغير أثناء تنفيذ العملية ، إن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
    "حدد عدد قواعد العمل التي تحكم العملية ، إذا لزم الأمر محددة في التعليقات",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "حدد عدد تطبيقات الأعمال المتضمنة في العملية (عدد العناصر التي سيتم إنشاؤها) ، إن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "اختر ما إذا كان سيتم معالجة البيانات غير المهيكلة (PDF، Excel، البريد الإلكتروني، ملف نصي) أو أي معالجة OCR، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
    "اختر ما إذا كان سيتم التواصل مع قاعدة بيانات لإنشائها أو تنفيذ أي أوامر SQL، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
    "اختر إذا كان هناك أي سكربت Powershell للتثبيت أو أي كود Powershell لتنفيذه في سطر الأوامر، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if the resources will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "اختر ما إذا كان الروبوتات ستكون لها حساب ويندوز وتتواصل عبر البريد الإلكتروني أو تيمز، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
    "اختر إذا كان هناك حاجة لإنشاء سكربتات VB أو تشغيل الماكروهات مباشرة على بلو بريزم، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "اختر إذا كان هناك إمكانية لاستخدام خدمات الويب أو عرضها للعمليات الأخرى، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "اختر إذا كانت العملية تتفاعل مع تطبيقات الويب عبر المتصفحات (IE، Chrome، Firefox)، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "اختر إذا كانت العملية تتفاعل مع تطبيقات ويندوز المثبتة على الكمبيوتر، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "اختر إذا كان هناك أي تطبيق نصل إليه عبر محاكي (IBM، AS400، HUB 5250)، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
    "اختر إذا كان هناك أي تطبيق نصل إليه عبر السحابة (الصورة)، وإن لزم الأمر، حدد بالتفصيل في التعليقات",
  "Select if this process can be automated without the use of RPA technologies":
    "اختر إذا كان بإمكان أتمتة هذه العملية بدون استخدام تقنيات الـ RPA",
  "Are you sure you want to save this case as a draft ?":
    "هل أنت متأكد أنك تريد حفظ هذه الحالة كمسودة؟",
  "Case saved as a draft": "تم حفظ الحالة كمسودة",
  "Are you sure you want to save and submit this case later ?":
    "هل أنت متأكد من رغبتك في حفظ وإرسال هذه الحالة لاحقًا؟",
  "Case saved but not submitted": "تم حفظ الحالة ولم يتم إرسالها بعد",
  "Are you sure you want to save and submit this case ?":
    "هل أنت متأكد من رغبتك في حفظ وإرسال هذه الحالة؟",
  "Case saved and submitted for approval": "تم حفظ الحالة وتم إرسالها للموافقة",
  "rpaQual.transition.accepted":
    "هل أنت متأكد أنك ترغب في قبول هذه الحالة كفرصة للأتمتة؟",
  "Case accepted as an opportunity": "تم قبول الحالة كفرصة",
  "Are you sure you want to reject this case ?":
    "هل أنت متأكد أنك ترغب في رفض هذه الحالة؟",
  "Case rejected": "تم رفض الحالة",
  "This case is not yet submitted. Are you sure you want to delete it?":
    "هذه الحالة لم يتم تقديمها بعد. هل أنت متأكد أنك تريد حذفها؟",
  "Case deleted successfully": "تم حذف الحالة بنجاح",
  "Case archived successfully": "تم أرشفة الحالة بنجاح",
  "This case is submitted and awaiting approval. Are you sure you want to delete it?":
    "تم تقديم هذه الحالة وهي في انتظار الموافقة. هل أنت متأكد أنك تريد حذفها؟",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it?":
    "تم قبول هذه الحالة كفرصة مقبولة للأتمتة. هل أنت متأكد أنك تريد حذفها؟",
  "Are you sure you want to delete this case ?":
    "هل أنت متأكد أنك تريد حذف هذه الحالة؟",
  "Your progress will be lost, are you sure you want to exit ?":
    "سيتم فقدان تقدمك، هل أنت متأكد أنك تريد الخروج؟",
  "Total in man-days": "الإجمالي بالأيام العملية",
  "Running costs in man-days": "تكاليف التشغيل بالأيام العملية",
  months: "أشهر",
  Months: "أشهر",
  Weeks: "أسابيع",
  Years: "سنين",
  days: "أيام",
  weeks: "أسابيع",
  years: "سنين",
  left: "اليسار",
  ago: "منذ {{value}} {{unit}}",
  Logout: "تسجيل الخروج",
  Profile: "الملف الشخصي",
  "Choose your timezone": "اختر توقيتك الزمني",
  "Execution Count": "عمليات التنفيذ",
  "Exception.count": "الاستثناءات",
  process: "العملية",
  "point.amortissage.negative": "{{value}} {{unit}} متبقي",
  "of the total": "من الإجمالي",
  SearchProcess: "البحث عن العملية",
  searchUser: "البحث عن المستخدم",
  status: "الحالة",
  sortBy: "الترتيب حسب",
  "Failed To create User": "فشل في إنشاء مستخدم",
  "Failed To create Scheduler": "فشل في إنشاء المجدول",
  "user.keycloak.exists": "المستخدم موجود بالفعل في Keycloak",
  "user.email.exists": "المستخدم موجود مسبقا",
  "User with same email already exists":
    "يوجد  مستخدم بنفس البريد الإلكتروني مسبقا",
  "user.update.fail": "فشل في تحديث المستخدم",
  "user.update.success": "تم تحديث المستخدم بنجاح",
  "invalid.value": "قيمة غير صحيحة",
  Required: "مطلوب",
  deleteUsersActionMessage: "هل أنت متأكد من أنك تريد حذف هؤلاء المستخدمين؟",
  "profile.update-password.current-password-required":
    "كلمة المرور الحالية مطلوبة",
  "profile.update-password.new-password-required": "كلمة المرور الجديدة مطلوبة",
  "profile.update-password.password-required": "كلمة المرور مطلوبة",
  "profile.update-password.password-not-matched": "كلمات المرور غير متطابقة",
  "profile.update-password.current-password": "كلمة المرور الحالية",
  "profile.update-password.new-password": "كلمة المرور الجديدة",
  "profile.update-password.confirm-password": "تأكيد كلمة المرور الجديدة",
  "profile.update-password.reset-password": "إعادة تعيين كلمة المرور",
  "profile.update-password.password-reset": "إعادة تعيين كلمة المرور",
  "profile.update-password.password-reset-success":
    "تم تغيير كلمة المرور بنجاح",
  "profile.update-password.password-email-send":
    "تم إرسال بريد إلكتروني. يرجى التحقق من بريدك.",
  "profile.update.password.success": "تم تغيير كلمة المرور بنجاح",
  "profile.update.password.wrong.current.password": "كلمة المرور الحالية غير صحيحة",
  "profile.update-password.password-reset-done": "تم",
  "profile.update-password.update-password": "تغيير كلمة المرور",
  "profile.update-password.email": "بريدك الحالي",
  "profile.update-password.tokenError":
    "الرمز غير صالح، تم تحديث كلمة المرور بالفعل!",
  "token.validation.noteExist": "تم إرسال طلب تغيير كلمة المرور",
  "Process CompletedItems": "المكتملة",
  "Completed time": "الوقت المكتمل",
  "traited item": "العناصر المُعالجة",
  "Items Count": "العناصر المُعالجة",
  "process.devCost.edit.cancel":
    "سيتم فقدان تغييراتك، هل أنت متأكد أنك تريد الإلغاء؟",
  "process.devCost.edit.confirm": "هل أنت متأكد من رغبتك في حفظ التغييرات؟",
  "process.status.pending": "معلق",
  "not submitted": "لم يتم الإرسال",
  "Process Assessment": "تقييم الفرص",
  "title.processassessment": "تقييم العملية",
  "no.exception.message": "لم يتم اكتشاف أي استثناءات",
  "show.all.exception.button": "عرض الكل",
  manageUsers: "إدارة المستخدمين",
  "assessment.Accounting & Finance": "المحاسبة والمالية",
  "assessment.Compliance": "الامتثال",
  "assessment.Customer Service": "خدمة العملاء",
  "assessment.Purchasing": "عمليات الشراء",
  "assessment.IT Operations": "العمليات التقنية",
  "assessment.Maintenance": "صيانة",
  "assessment.Sales": "المبيعات",
  "assessment.Marketing": "التسويق",
  "assessment.Research and Development": "البحث والتطوير",
  "assessment.Production": "الإنتاج",
  "assessment.Human Resources Management ": "إدارة الموارد البشرية ",
  "assessment.Software Development": "تطوير البرمجيات",
  "assessment.Other": "آخر",
  "assessment.Seconds": "ثواني",
  "assessment.Minutes": "دقائق",
  "assessment.Hours": "ساعات",
  "assessment.Days": "أيام",
  "assessment.Months": "أشهر",
  "assessment.Years": "سنين",
  "assessment.Weeks": "أسابيع",
  "orchestrator.management.menu.title": "المنظمون الآليون",
  "resource.management.menu.title": "الروبوتات",
  "Process Occurence": "العناصر المكتملة",
  "Process Item": "عناصر",
  "orchestrator.management.add.button": "إضافة المنظم الآلي",
  "orchestrator.management.delete.confirmMsg":
    "هل أنت متأكد أنك تريد حذف المنظم الآلي؟ سيتم فقدان جميع البيانات بشكل دائم.",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "هل أنت متأكد أنك تريد حذف المنظمين المحددين؟",
  "orchestrator.management.enaDes.confirmMsg":
    "هل أنت متأكد أنك تريد تفعيل المنظم الآلي؟ سيتم إعادة تأسيس الاتصال ببيئة RPA الخاصة بك.",
  "orchestrator.management.enaDes.button.confirm": "تفعيل",
  "orchestrator.management.desaDes.confirmMsg":
    "هل أنت متأكد أنك تريد تعطيل المنظم الآلي؟ سيتم فقدان الاتصال ببيئة RPA الخاصة بك.",
  "orchestrator.management.desaDes.button.confirm": "إلغاء تفعيل",
  "orchestrator.management.searchOrchestrator": "البحث عن المنظمين الآليين",
  "orchestrator.management.status": "الحالة",
  "orchestrator.management.name": "الاسم",
  "orchestrator.management.solutionProvider": "مزود الحلول",
  "orchestrator.management.isActionable": "قابل للتنفيذ",
  "orchestrator.management.orchestratorIp": "عنوان IP العام",
  "orchestrator.management.isAcionableToggle":
    "هل أنت متأكد أن هذا المنظم الآلي غير قابل للتنفيذ؟",
  "orchestrator.management.isAcionableToggle.confirm": "نعم ، أنا متأكد من ذلك",
  "orchestrator.management.isNotAcionableToggle":
    "هل أنت متأكد أنك تريد جعل هذا المنظم الآلي قابل للتنفيذ؟",
  "orchestrator.management.isNotAcionableToggle.confirm": "جعله قابلاً للتنفيذ",
  "orchestrator.management.editHeader": "تعديل المنظم الآلي",
  "orchestrator.management.addHeader": "إضافة المنظم الآلي",
  "orchestrator.management.displayHeader": "تفاصيل المنظم الآلي",
  "orchestrator.management.formControl.required": "الحقل المطلوب",
  "orchestrator.management.formControl.orchestratorType": "نوع المنظم",
  "orchestrator.management.formControl.orchestratorId": "معرف المنظم الآلي",
  "orchestrator.management.formControl.orchestratorName": "اسم المنظم الآلي",
  "orchestrator.management.formControl.orchestratorName.information":
    "اسم المنظم الآلي",
  "orchestrator.management.formControl.description.information":
    "أي معلومات ترغب في إضافتها هنا؟",
  "orchestrator.management.formControl.orchestratorIp.information":
    "عنوان IP العام أو الخاص لجهاز المنظم الآلي",
  "orchestrator.management.formControl.sessionNumber.information":
    "عدد الجلسات المسموح بها في الترخيص الحالي لبرنامج Blue Prism",
  "orchestrator.management.formControl.databaseServer.information":
    "اسم المضيف الخاص بسيرفر قاعدة البيانات",
  "orchestrator.management.formControl.serverPort.information":
    "يجب أن يتطابق هذا مع منفذ الاستماع الذي تم تكوينه على SQL Server",
  "orchestrator.management.formControl.databaseName.information":
    "اسم قاعدة البيانات التي يستخدمها BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
    "اسم مستخدم قاعدة البيانات المستخدم عند الاتصال. يجب أن يكون للمستخدم صلاحيات db_datareader و db_datawriter على قاعدة بيانات BluePrism",
  "orchestrator.management.formControl.dbPassword.information":
    "كلمة المرور لاسم المستخدم المستخدم عند الاتصال",
  "orchestrator.management.formControl.apiClientId.information":
    "المعرّف التطبيقي المُقدم من قِبل \u202EhtaPiU عند الإنشاء",
  "orchestrator.management.formControl.apiClientSecret.information":
    "السر التطبيقي المُقدم من قِبل \u202EhtaPiU عند الإنشاء",
  "orchestrator.management.formControl.organizationName.information":
    "UIPATHاسم المنظمة في منظم الآلي لـ",
  "orchestrator.management.formControl.tenantName.information":
    "اسم المستأجر الذي ستُسترجع منه البيانات",
  "orchestrator.management.formControl.orchestratorApiName.information":
    "اسم منظم الآلي لـ \u202EhtaPiU",
  "orchestrator.management.formControl.apiClientId": "مُعرّف تطبيق \u202EIPA",
  "orchestrator.management.formControl.apiClientSecret": "سر تطبيق \u202EIPA",
  "orchestrator.management.formControl.solutionProvider": "مزود الحلول",
  "orchestrator.management.formControl.orchestratorIp": "عنوان IP للمنظم الآلي",
  "orchestrator.management.formControl.yes": "نعم",
  "orchestrator.management.formControl.no": "لا",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "قابل للتنفيذ",
  "orchestrator.management.formControl.apiPath": "مسار API",
  "orchestrator.button.update": "تحديث المنظم",
  "orchestrator.delete.discard": "هل أنت متأكد أنك تريد تجاهل التغييرات؟",
  "orchestrator.management.save.confirmMsg":
    "هل أنت متأكد أنك تريد حفظ هذا المنظم الآلي؟",
  "user.enaDes.confirmMsg": "هل أنت متأكد من رغبتك في حذف المستخدم؟",
  "orchestrator.management.formControl.description": "الوصف",
  "orchestrator.management.formControl.databaseServer": "سيرفر قاعدة البيانات",
  "orchestrator.management.databaseServer": "سيرفر قاعدة البيانات",
  "orchestrator.management.formControl.serverPort": "المنفذ",
  "orchestrator.management.serverPort": "المنفذ",
  "orchestrator.management.formControl.databaseName": "اسم قاعدة البيانات",
  "orchestrator.management.formControl.dbUsername": "اسم المستخدم",
  "orchestrator.management.formControl.dbPassword": "كلمة المرور",
  "orchestrator.management.formControl.invalidPort":
    "يجب أن يكون رقم المنفذ بين 1 و 65,535",
  "orchestrator.management.formControl.organizationName":
    "اسم منظمة \u202EhtaPiU",
  "orchestrator.management.formControl.tenantName": "اسم مستأجر \u202EhtaPiU",
  "orchestrator.management.formControl.orchestratorApiName":
    "اسم مدير المنظم الآلي لـ \u202EhtaPiU",
  "orchestrator.management.testConnection": "اختبار الاتصال",
  "orchestrator.management.testConnection.successMessage": "نجاح الاتصال",
  "orchestrator.management.testConnection.failureMessage": "فشل الاتصال",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "هل أنت متأكد أنك تريد قبول هذه الحالة كفرصة للأتمتة؟",
  "Are you sure you want to save the changes?":
    "هل أنت متأكد من رغبتك في حفظ التغييرات؟",
  "Schedule modified successfully": "تم تعديل الجدول بنجاح",
  "Schedule saved successfully": "تم حفظ الجدول بنجاح",
  "Failed to update the schedule! Please try again later":
    "فشل تحديث الجدول! الرجاء معاودة المحاولة في وقت لاحق",
  "Create schedule": "إنشاء جدول الزمني",
  "Edit schedule": "تحديث الجدول الزمني",
  "Choose Process": "اختر العملية",
  "Choose Resource": "اختر الروبوت",
  "Disregard changes": "تجاهل التغييرات",
  "Save changes": "حفظ التغييرات",
  apply: "تطبيق",
  reset: "إعادة ضبط",
  processes: "العمليات",
  ACTIVE: "نشط",
  IDLE: "عاطل",
  PRIVATE: "خاص",
  WORKING: "يعمل",
  OFFLINE: "غير متصل",
  Idle: "عاطل",
  Working: "يعمل",
  Offline: "غير متصل",
  DISABLED: "غير نشط",
  DISABLE: "غير نشط",
  Disabled: "غير نشط",
  "Process status": "حالة العملية",
  "timeUnite.millis": " مللي",
  "timeUnite.sec": " ثانية",
  "timeUnite.min": " دقيقة",
  "timeUnite.hrs": " ساعة",
  "timeUnite.days": " يوم",
  "Choose icon": "اختر أيقونة",
  "Edit Icon": "تعديل  الأيقونة",
  export: "تصدير",
  "user.management.menu.title": "المستخدمين",
  "User Administration": "المستخدمين",
  "Group Administration": "إدارة المجموعاة",
  "Tag Administration": "إدارة الوسوم",
  Administration: "الإدارة",
  "user.management.add.button": "إضافة مستخدم",
  "user.management.delete.confirmMsg":
    "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
  "user.management.deleteUsersActionMessage":
    "هل أنت متأكد من أنك تريد حذف المستخدمين المحددين؟",
  "user.management.enaDes.confirmMsg":
    "هل أنت متأكد من رغبتك في تفعيل هذا المستخدم؟",
  "user.management.enaDes.button.confirm": "تفعيل",
  "user.management.desaDes.confirmMsg":
    "هل أنت متأكد من رغبتك في تعطيل هذا المستخدم؟",
  "user.management.desaDes.button.confirm": "تعطيل",
  "user.management.searchUser": "البحث عن المستخدمين",
  depuis: "منذ",
  "depuis la premiere execution": "أول تنفيذ تم العثور عليه",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems":
    "افترض الاستثناءات التجارية كعناصر مكتملة في مؤشرات الأداء الراقبة",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip":
    "سيتم اعتبار جميع الاستثناءات التجارية كما تمت بنجاح",
  "kpi.parameter.be": "تضمين البيانات منذ",
  "kpi.parameter.includeException":
    "ضمِّن استثناءات العناصر التجارية في مؤشرات الأداء المالي",
  "kpi.parameter.executionsIncludeException":
    "ضمِّن استثناءات التنفيذات في مؤشرات الأداء المالي",
  "kpi.parameter.invalidRate": "معدل غير صالح",
  "kpiParam.update.success": "تم تحديث الإعدادات بنجاح",
  "kpiParam.update.error": "فشل تحديث اعدادات مؤشرات الأداء الرئيسية",
  "kpiParam.reset.success": "تمت إعادة تعيين الإعدادات بنجاح",
  "kpiParam.reset.error": "فشل إعادة تعيين اعدادات مؤشرات الأداء الرئيسية",
  "kpi.parameter.includeSince.tooltip":
    "اختر متى سيبدأ SmartRoby في استخدام وعرض بيانات العمليات في الوحدات ومؤشرات الأداء الرئيسية عبر البوابة.",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "يتضمن نسبة من الوقت الموفر لكل عنصر تم معالجته باستثناء تجاري",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "يتضمن نسبة من الوقت الموفر لكل عملية تم إنهاؤها باستثناء تجاري",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "الفترة الزمنية المعتبرة عند تقدير نقطة التعادل لهذا التشغيل التلقائي",
  // ROUTES
  "routes.home": "الصفحة الرئيسية",
  "routes.orchestrators": "المنظمون",
  "routes.orchestrators.processAssessment": "تقييم العملية",
  "routes.orchestrators.processAssessment.rpa": "التطبيقات الروبوتية للعمليات",
  "routes.orchestrators.processAssessment.rpa.view": "عرض",
  "routes.orchestrators.processAssessment.rpa.create": "إنشاء",
  "routes.orchestrators.processAssessment.rpa.edit": "تعديل",
  "routes.orchestrators.smartResources": "الروبوتات الذكية",
  "routes.orchestrators.myProcesses": "العمليات",
  "My Processes": "التلقائيات",
  "routes.orchestrators.smartResources.documents": "وثائق",
  "routes.orchestrators.smartResources.documents.details": "التفاصيل",
  "routes.orchestrators.administration": "الادارة",
  "routes.orchestrators.administration.users": "المستخدمين",
  "routes.orchestrators.administration.users.add": "إضافة",
  "routes.orchestrators.administration.users.edit": "تعديل",
  "routes.orchestrators.administration.users.view": "عرض",
  "routes.orchestrators.protected": "محمي",
  "routes.orchestrators.protected.user": "المستخدم",
  "routes.orchestrators.protected.user.profile": "الملف الشخصي",
  "routes.orchestrators.administration.orchestrators": "المنظمون",
  "routes.orchestrators.administration.orchestrators.add": "إضافة",
  "routes.orchestrators.administration.orchestrators.edit": "تعديل",
  "routes.orchestrators.administration.orchestrators.view": "عرض",
  "routes.orchestrators.smartocr": "ocr الذكية",
  "routes.orchestrators.smartocr.identity_card": "البطاقة الشخصية",
  "routes.orchestrators.smartocr.passport": "جواز سفر",
  "routes.orchestrators.smartocr.driver_licence": "رخصة قيادة",
  "routes.orchestrators.smartocr.car_registration": "تسجيل السيارة",
  "routes.orchestrators.smartocr.green_card": "green_card",
  "routes.orchestrators.smartocr.rib": "rib",
  "routes.orchestrators.smartocr.invoice": "فاتورة",
  "routes.orchestrators.smartocr.purchase_order": "أمر شراء",
  "routes.orchestrators.smartocr.tax_notice": "إشعار ضريبي",
  "routes.orchestrators.smartocr.receipt": "وصل",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet": "بيان كشوف المرتبات",
  "routes.orchestrators.exceptions": "استثناءات",
  "routes.orchestrators.exceptions.processes": "استثناءات العملية",
  "routes.orchestrators.exceptions.items": "استثناءات العنصر",
  "routes.orchestrators.process-occurrence": "حدوث العملية",
  // ALERT MANAGEMENT ROUTES
  "routes.orchestrators.alertsManagement": "التنبيهات",
  "routes.orchestrators.alertsManagement.defaultAlerts": "تنبيهات افتراضية",
  "routes.orchestrators.alertsManagement.customAlerts": "تنبيهات مخصصة",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "إضافة",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "تعديل",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "عرض",
  "routes.orchestrators.alertsManagement.customAlerts.add": "إضافة",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "تعديل",
  "routes.orchestrators.alertsManagement.customAlerts.view": "عرض",
  //= =======================================================================
  "exceptions.item.tab.title": "استثناءات",
  "exceptions.processes.tab.title": "استثناءات العملية",
  "routes.orchestrators.process-occurence": "حدوث العملية",
  "routes.orchestrators.profile": "الملف الشخصي",
  "routes.orchestrators.administration.fleets": "مجموعات",
  "routes.orchestrators.administration.fleets.add": "إضافة",
  "routes.orchestrators.administration.fleets.edit": "تعديل",
  "routes.orchestrators.administration.fleets.view": "عرض",
  Analytics: "التقارير",
  "Alerts Management": "التنبيهات",
  "save draft": "حفظ المسودة",
  "user.title.editHeader": "تعديل المستخدم",
  "fleet.management.status": "الحالة",
  "fleet.management.companyName": "الشركة",
  "fleet.management.email": "بريد إلكتروني",
  "fleet.management.clientSince": "تم إنشاء المجموعة منذ",
  "fleet.management.menu.title": "المجموعات",
  groups: "المجموعات",
  "fleet.management.add.button": "إضافة مجموعة",
  "fleet.management.delete.confirmMsg":
    "هل أنت متأكد أنك تريد حذف هذه المجموعة؟",
  "fleet.management.deleteFleetsActionMessage":
    "هل أنت متأكد أنك تريد حذف المجموعات المحددة؟",
  "fleet.management.enaDes.confirmMsg":
    "هل أنت متأكد أنك تريد تفعيل هذه المجموعة ؟",
  "fleet.management.enaDes.button.confirm": "تفعيل",
  "fleet.management.desaDes.confirmMsg":
    "هل أنت متأكد أنك تريد إلغاء تفعيل هذه المجموعة ؟",
  "fleet.management.desaDes.button.confirm": "إلغاء تفعيل",
  "fleet.management.searchFleet": "البحث عن المجموعات",
  "fleet.management.addHeader": "إضافة مجموعة",
  "fleet.management.editHeader": "تعديل المجموعة",
  "fleet.management.displayHeader": "تفاصيل المجموعة",
  "fleet.management.formControl.required": "حقل مطلوب",
  "fleet.management.formLabel.companyName": "اسم الشركة",
  "fleet.management.formControl.email": "تنسيق البريد الإلكتروني خاطئ",
  "fleet.management.formControl.siret.notValid": "SIRET غير صالح",
  "fleet.management.formControl.siren.notValid": "SIREN غير صالح",
  "fleet.management.formLabel.email": "بريد إلكتروني",
  "fleet.management.formLabel.phoneNumber": "رقم التليفون",
  "fleet.management.formLabel.vatNumber": "رقم ضريبة القيمة المضافة",
  "fleet.management.formControl.vatNumber.notValid": "يجب أن تكون القيمة رقم",
  "fleet.management.formLabel.address": "العنوان الكامل",
  "fleet.management.formLabel.subscription": "الاشتراك",
  "fleet.management.formLabel.clientSince": "عميل منذ",
  "fleet.button.update": "تحديث",
  "fleet.button.save": "إضافة",
  "fleet.button.cancel": "إلغاء",
  "fleett.update.confirmMsg": "هل أنت متأكد أنك تريد تحديث معلومات المجموعة؟",
  "fleet.save.confirmMsg": "هل أنت متأكد أنك تريد إضافة مجموعة؟",
  "fleet.delete.discard": "هل أنت متأكد أنك تريد تجاهل تغييراتك؟",
  "fleet.button.discard": "تجاهل",
  "fleet.formControl.updateSuccess": "تم تحديث معلومات المجموعة بنجاح",
  "fleet.formControl.updateError": "خطأ أثناء تحديث معلومات المجموعة",
  "fleet.formControl.saveError": "خطأ أثناء إضافة مجموعة جديدة",
  "fleet.management.formControl.invalidDate": "تنسيق التاريخ غير صالح",
  "orchestrator.formControl.updateSuccess": "تم تحديث معلومات المنظم بنجاح",
  "user.management.formLabel.fleet": "مجموعة",
  "processesSelector.availableProcess": "العمليات المتاحة",
  "processesSelector.assignedProcess": "العمليات المعينة",
  "processTagSelector.assignedProcessTags": "العمليات المعينة و الوسوم ",
  "tagSelector.availableTags": "الوسوم المتاحة",
  "user.formControl.saveError": "حدث خطأ أثناء إضافة مستخدم جديد",
  "user.formControl.updateError": "خطأ أثناء تحديث معلومات المجموعة",
  "user.formControl.updateSuccess": "تم تحديث معلومات المستخدم بنجاح",
  "user.formControl.saveSuccess": "تمت إضافة مستخدم جديد بنجاح",
  "user.permissions.title": "صلاحيات المستخدم",
  "user.permission.save": "حفظ",
  "user.permissions.template.createBtn": "إنشاء قالب",
  "user.add.template": "قالب",
  "user.permission.confirmSave":
    "هل أنت متأكد أنك تريد تحديث صلاحيات المستخدمين؟",
  "user.permission.confirmDelete": "هل أنت متأكد أنك تريد حذف هذا القالب؟",
  "user.permission.saveAsTemplace": "حفظ وإنشاء قالب",
  "user.permission.saveAsTemplaceTitle": "قالب جديد",
  "user.permission.templateCreate.success": "تم إنشاء القالب بنجاح",
  "user.permission.templateCreate.error": "اسم القالب موجود بالفعل",
  "permission.action.applyTemplate": "تطبيق القالب",
  "user.permissions.template.name": "اسم القالب",
  "user.permissions.default": "الافتراضي",
  "user.add.permissions": "الصلاحيات",
  "fleet.formControl.saveSuccess": "تم إضافة المجموعة الجديدة بنجاح",
  "orchestrator.formControl.saveSuccess": "تمت إضافة المنظم الجديد بنجاح",
  "fleet.formControl.unassignProcessError":
    "العملية مُعيّنة بالفعل لـ {{users}} مستخدمين",
  "fleet.management.delete.warning":
    "سيتم حذف المجموعة تلقائيًا مع حذف جميع مستخدميه وإلغاء تعيين عملياته",
  "user.administration.fleet": "مجموعة",
  "user.administration.lastLogin": "آخر تسجيل دخول",
  "tooltip.action.enable": "تفعيل",
  "tooltip.action.disable": "تعطيل",
  "tooltip.action.permissions": "الصلاحيات",
  "user.management.formControl.required": "حقل مطلوب",
  "user.management.formControl.email": "تنسيق البريد الإلكتروني غير صحيح",
  "fleet.management.formControl.phone": "تنسيق رقم الهاتف غير مقبول",
  "user.management.formControl.phone": "تنسيق رقم الهاتف خاطئ",
  "fleet.formSection.fleetInfo": "معلومات عامة",
  "fleet.formSection.clientParams": "اعدادات العميل",
  "fleet.formSection.subscriptionInfo": "معلومات الاشتراك",
  "fleet.formSection.clientParameters": "إعدادات المجموعة",
  "fleet.management.formLabel.rate": "معدل",
  "fleet.management.formLabel.overConsumptionRate": "معدل الاستهلاك الزائد",
  "fleet.management.formLabel.subscriptionPeriod": "فترة الاشتراك",
  "fleet.management.formLabel.paymentTerm": "مصطلح الدفع",
  "fleet.formSection.processes": "العمليات",
  "fleet.formSection.processesTags": "العمليات والوسوم",
  "fleet.formSection.summary": "ملخص الاشتراك النشط",
  "fleet.management.formLabel.rateUnit": "€ / ساعة",
  "fleet.management.formLabel.subscriptionPeriodUnit": "أشهر",
  "fleet.management.formLabel.siret": "Siret",
  "fleet.management.formLabel.siren": "Siren",
  "fleet.management.formLabel.manDayValue": "يوم شخص واحد (يوم عمل واحد)",
  "fleet.management.formLabel.fullTime": "1 موظف بدوام كامل (FTP)",
  "fleet.management.formLabel.bePeriod": "فترة حساب نقطة التعادل",
  "fleet.management.formLabel.recommendedOccupancy": "الإشغال المُوصى به",
  "fleet.management.be.period.1": "الشهر الماضي",
  "fleet.management.be.period.2": "آخر 3 أشهر",
  "fleet.management.be.period.3": "آخر 6 أشهر",
  "fleet.management.be.period.4": "العام الماضي",
  "fleet.add.previous": "السابق",
  fleet: "مجموعة",
  "process.filter.fleet": "مجموعة",
  "process.enabled": "ظاهر",
  "process.filter.showAll": "إظهار ({{count}}) عمليات مخفية",
  Unremarkable: "<5 سنوات",
  "fleet.management.formControl.number":
    "تنسيق رقم ضريبة القيمة المضافة غير مقبول",
  "fleet.management.formControl.occupancyNumber":
    "يجب أن يكون الإشغال الموصي به رقمًا",
  "fleet.management.formControl.occupancyHigherThan":
    "لا يمكن أن يكون الإشغال الموصى به أعلى من 24",
  "fleet.management.formControl.occupancyLowerThan":
    "لا يمكن أن يكون الإشغال الموصى به أقل من 0",
  "fleet.management.hoursPerDay": "ساعة في اليوم",
  "reporting.copyToClipboard": "نسخ إلى الحافظة",
  "reporting.process": "عملية",
  "reporting.kpi": "مؤشر الأداء الرئيسي",
  "reporting.reportType": "نوع التقرير",
  "reporting.date": "التاريخ",
  "reporting.table": "جدول",
  "reporting.lineChart": "مخطط خطي",
  "reporting.pieChart": "مخطط دائري",
  "reporting.barChar": "مخطط شريطي",
  "reporting.columnChart": "مخطط عمودي",
  "reporting.processExecutions": "التنفيذات",
  "reporting.itemsProcessed": "العناصر المعالجة",
  "reporting.itemsCompleted": "العناصر المكتملة",
  "reporting.itemsSuccessRate": "معدل نجاح العناصر",
  "reporting.itemsExceptionRate": "معدل الاستثناءات للعناصر",
  "reporting.itemsBusinessExceptions": "استثناءات العمليات للعناصر",
  "reporting.itemsSystemExceptions": "استثناءات النظام للعناصر",
  "reporting.totalException": "إجمالي الاستثناءات",
  "Exception rate": "معدل الاستثناء",
  "reporting.timeSaved": "الوقت الموفر",
  "reporting.executionTime": "زمن التنفيذ",
  "reporting.itemExecutionTime": "زمن تنفيذ العنصر",
  "reporting.breakEven": "نقطة التعادل",
  "reporting.roi": "معدل الربحية",
  "reporting.roiShort": "معدل الربحية",
  "reporting.occupancyRate": "معدل الاشغال",
  "reporting.roiPercentage": "معدل الربحية",
  "reporting.executedProcesses": "عمليات مُنفَّذة",
  "reporting.save": "تصدير كصورة PNG",
  "reporting.copyClipboard": "نسخ إلى الحافظة",
  "reporting.exportPDF": "تصدير كملف PDF",
  "reporting.clear": "مسح",
  "reporting.export": "تصدير",
  "reporting.from": "من",
  "reporting.to": "إلى",
  "reporting.exceptionCount": "عدد الاستثناءات",
  "reporting.reportTitle": "بين {{from}} و {{to}}",
  "reporting.and": "و",
  for: "من أجل",
  "reporting.cumul": "النتيجة التراكمية",
  "reporting.date.errorFuture": "يجب أن تكون القيمة أكبر من {{dateValue}}",
  "reporting.date.errorPast": "يجب أن تكون القيمة أقل من {{dateValue}}",
  "reporting.presetDeletionSuccess": "تم حذف الإعداد مع النجاح",
  "reporting.presetDeletionError": "تعذر حذف الإعداد",
  "reporting.presetCreationSuccess": "تم إنشاء الإعدادات بنجاح",
  "reporting.presetCreationError": "تعذر إنشاء الإعداد",
  "reporting.customKpiCreationOrUpdateSuccess": "تم حفظ مؤشر الأداء الرئيسي المخصص بنجاح",
  "reporting.customKpiCreationOrUpdateError": "تعذر حفظ مؤشر الأداء الرئيسي المخصص",
  "reporting.customKpiDeletionSuccess": "تم حذف مؤشر الأداء الرئيسي المخصص بنجاح",
  "reporting.customKpiDeletionError": "تعذر حذف مؤشر الأداء الرئيسي المخصص",
  "Process launcher": "مُطلق العملية",
  "Execution detail": "تفاصيل التنفيذ",
  "Business Exception": "استثناء تجاري",
  "System Exception": "استثناء نظامي",
  Unspecified: "غير محدد",
  Internal: "استثناء داخلي",
  "Process.list.names.by.orchestrator": "قائمة العمليات",
  "Resource.list.names.by.orchestrator": "قائمة الروبوتات",
  "fleet.management.formLabel.effectiveStartDate": "تاريخ البدء الفعال",
  "rpaQual.transition.delete": "حذف",
  "rpaQual.transition.deleteMsg": "هل أنت متأكد أنك تريد حذف هذه الفرصة؟",
  "rpaQual.button.delete": "هل أنت متأكد أنك تريد حذف هذا المؤهل؟",
  "rpaQual.transition.reconsider": "أعد النظر",
  "rpaQual.transition.reconsiderMsg":
    "هل أنت متأكد أنك تريد إعادة النظر في هذه الحالة؟",
  "rpaQual.transition.archive": "أرشيف",
  "rpaQual.transition.archiveMsg": "هل أنت متأكد أنك تريد أرشفة هذه الحالة؟",
  "rpaQual.process.archived.success": "تم وضع الحالة في الأرشيف",
  "rpaQual.process.roadMap.success": "تمت الإضافة إلى الخارطة",
  "rpaQual.process.reconsider.success": "أعيد النظر في الفرصة",
  "rpaQual.transition.addToRoadMap": "أضف إلى الخارطة ",
  "rpaQual.transition.addToRoadMapMsg":
    "هل أنت متأكد أنك تريد إضافة هذه الحالة إلى الخارطه  الخاصة بك؟",
  "rpaQual.filter.show": "تشمل المؤرشفة",
  "rpaQual.display.nonArchived": "غير مؤرشف",
  "rpaQual.display.archived": "مؤرشف",
  "rpaQual.display.all": "كل",
  "breadcrumbs.label.details": "المدخلات",
  "rpaQual.opportunity.list.empty": "لم يتم العثور على فرصة!",
  "rpaQual.case.list.empty": "لم يتم العثور على حالة!",
  "rpaQual.status.submitted": "معلق",
  "rpaQual.status.reconsider": "معلق",
  "rpaQual.status.accepted": "مقبول",
  "rpaQual.status.draft": "مسودة",
  "rpaQual.status.not submitted": "غير مصدق",
  "rpaQual.status.rejected": "مرفوض",
  "rpaQual.status.roadMap": "خارطة زمنية",
  "filter.graph": "رؤى",
  "filter.export": "تصدير",
  // Analytics - help
  "help.process": "يرجى اختيار العمليات المرتبطة بتحليلات البيانات",
  "help.kpi": "يرجى اختيار مؤشرات الأداء الرئيسية",
  "help.reportType":
    "قائمة أنواع التقارير المعروضة تعتمد على المؤشرات الرئيسية المحددة",
  "Select All": "تحديد الكل",
  // permissions
  "Start process": "ابدأ العملية",
  "Schedule process": "جدولة العملية",
  "KPI parameters": "الإعدادات",
  "Edit process details": "تعديل تفاصيل العملية",
  "Enable/Disable process": "إظهار/إخفاء العمليات",
  "Item exceptions": "استثناءات العناصر",
  "Delete item exceptions": "إخفاء استثناءات العناصر",
  "Retry item exceptions with changed data":
    "إعادة محاولة استثناءات العناصر ببيانات معدلة",
  "Retry item exceptions": "إعادة محاولة استثناءات العناصر",
  "Manage item exceptions": "إدارة استثناءات العناصر",
  "Process execution exceptions": "استثناءات التنفيذ",
  "Delete process execution exceptions": "حذف استثناءات التنفيذ",
  "View process exceptions": "عرض استثناءات العملية",
  "Delete process exceptions": "إخفاء استثناءات العملية",
  "View item exceptions": "عرض استثناءات العناصر",
  "Add opportunity": "أضف فرصة",
  "Accept / Reject / Reconsider": "قبول / رفض / إعادة النظر",
  "Archive / Backlog": "الأرشيف / الأعمال المؤجلة",
  "Delete opportunity": "حذف الفُرصة",
  "Add fleet": "إضافة مجموعة",
  "Edit fleet": "تعديل المجموعة",
  "Delete fleet": "حذف المجموعة",
  "Add user": "إضافة مستخدم",
  "Edit user": "قم بتعديل المستخدمين",
  "Delete user": "حذف المستخدم",
  "User Permissions": "صلاحيات المستخدم",
  "Add Orchestrator": "إضافة منظم",
  "Edit Orchestrator": "تعديل المنظمات",
  "Delete Orchestrators": "حذف المنظم",
  "process-occurence.cutsomdate": "تاريخ مخصص",
  "exception.analytics.by.process": "الاستثناءات حسب العملية",
  "exception.analytics.by.type": "ملخص نوع الاستثناء",
  "exception.analytics.by.reason": "أهم أسباب الاستثناء",
  "exception.analytics.by.trend": "اتجاه الاستثناء",
  "Execution Time (Descending)": "مدة التنفيذ (تنازلي)",
  "Execution Time (Ascending)": "مدة التنفيذ (تصاعدي)",
  "no.process.message": "لم يتم اكتشاف أية عمليات",
  "no.queue.message": "لم يتم اكتشاف أية طوابير",
  "no.completedItems.message": "لم يتم الكشف عن عناصر مكتملة",
  "no.pendingItems.message": "لم يتم الكشف عن عناصر معلقة",
  "no.orchestrator.message": "لا يوجد منظمون حتى الان",
  "no.resource.message": "لا يوجد روبوت حتى الآن",
  "no.fleets.message": "لا يوجد مجموعات حتى الآن",
  "no.users.message": "لا يوجد مستخدمين حتى الآن",
  "no.resources.message": "لا يوجد روبوتات حتى الآن",
  "no.items.exception.message": "لم يتم العثور على استثناءات عنصر",
  "no.process.exception.message": "لم يتم العثور على استثناءات العملية",
  "no.analytics.message": "لم يتم الكشف عن أي عملية",
  "no.planned.execution.message": "لم يتم العثور على تنفيذات مخطط لها",
  "no.activities.message": "لم يتم العثور على أنشطة",
  "no.logs.message": "لم يتم العثور على أي سجل",
  "no.execution.message": "لم يتم العثور على تنفيذ",
  "no.scheduler.message": "لم يتم العثور على جدولة",
  "no.type.message": "لم يتم العثور على أي نوع",
  // ALERT MANAGEMENT
  "alerts.default.tab.title": "تنبيهات افتراضية",
  "alerts.custom.tab.title": "تنبيهات مخصصة",
  // DEFAULT ALERTS TABLE HAEDER CELLS
  "alert.status": "الحالة",
  "alert.alertName": "الاسم",
  "default.alert.event": "الحدث",
  "alert.operator": "المشغل",
  "alert.condition": "الشرط",
  "alert.type": "النوع",
  "alert.searchAlerts": "البحث عن تنبيهات",
  "no.alerts.message": "لم يتم العثور على تنبيهات",
  "alerts.default.addButton": "إضافة تنبيه",
  // CUSTOM ALERTS TABLE HAEDER CELLS
  "custom.alert.KPI": "مؤشر الأداء الرئيسي",
  "custom.alert.process": "عملية",
  "custom.alert.searchCustomAlerts": "البحث في التنبيهات",
  "alerts.custom.addButton": "إضافة تنبيه",
  "alert.management.delete.confirmMsg":
    "هل أنت متأكد أنك تريد حذف هذا التنبيه؟",
  // Add & Edit headers alert
  "alerts.addHeader": "تنبيه جديد",
  "alerts.editHeader": "تعديل التنبيه",
  "alerts.displayHeader": "تفاصيل التنبيه",
  // error messages
  "alerts.management.required.fields": "حقل مطلوب",
  // confirmation buttons
  "alerts.management.button.update": "تحديث",
  "alerts.management.button.add": "إضافة",
  "alerts.management.button.cancel": "إلغاء",
  "alerts.management.button.save": "حفظ",
  "alerts.management.button.exit": "خروج",
  "alerts.management.button.discard": "تجاهل",
  "alerts.management.trigger": "مشغل",
  "alerts.management.triggers": "مشغلات",
  "alerts.management.general": "عام",
  "alerts.management.checking.frequence": "تردد التحقق",
  "alerts.management.advanced.settings.alert.text": "  {{time}} في {{date}} ابتداءً من {{days}} سيتحقق كل SmartRoby ",
  "alerts.management.advanced.settings.btn": "الإعدادات المتقدمة",
  "alerts.management.simple.settings.btn": "تهيئة بسيطة",
  "alerts.management.advanced.settings.days.label": "خلال الأيام التالية",
  "alerts.management.advanced.settings.time.label": "خلال الفترات الزمنية التالية",
  "alerts.management.advanced.settings.all.days.label": "كل يوم",
  "alerts.management.advanced.settings.profile.time.zone": "المنطقة الزمنية للملف الشخصي",
  "alerts.management.notifications": "الاشعارات",
  "alerts.management.subscribers": "المشتركين",
  "alerts.management.name.placeholder": "أدخل اسم التنبيه",
  "alerts.management.name.label": "اسم التنبيه *",
  "alerts.management.condition.label": "قيمة *",
  "alerts.management.alertDescription.placeholder": "أدخل وصف التنبيه",
  "alerts.management.alertDescription.label": "الوصف",
  "alerts.management.when.placeholder": "عندما *",
  "alerts.management.operator.placeholder": "هو *",
  "alerts.management.process.placeholder": "ل *",
  "alerts.management.of.placeholder": "ل *",
  "alerts.management.email.invalidEmail":
    "تنسيق عنوان البريد الإلكتروني غير صالح",
  "alerts.management.email.invalidEmails":
    "تنسيق عناوين البريد الإلكتروني غير صالح",
  "alerts.management.email.invalidPhoneNumber": "تنسيق رقم الهاتف غير صالح",
  "alerts.management.email.invalidPhoneNumbers": "تنسيق أرقام الهواتف غير صالح",
  "alerts.management.severity": "نوع التنبيه *",
  "alerts.management.save.confirmMsg": "هل أنت متأكد أنك تريد حفظ هذا التنبيه؟",
  "alerts.management.update.confirmMsg":
    "هل أنت متأكد أنك تريد تحديث معلومات التنبيه؟",
  "alerts.management.delete.discard":
    "هل أنت متأكد من أنك تريد تجاهل التغييرات التي أجريتها؟",
  "Alert Frequency": "تكرار",
  "alert.management.formControl.saveSuccess": "تمت إضافة تنبيه جديد بنجاح",
  "alert.management.formControl.updateSuccess":
    "تم تحديث معلومات التنبيه بنجاح",
  "alert.management.formControl.delete": "تم حذف الاشعار",
  "alert.management.enable.email.notification":
    "هل أنت متأكد أنك تريد  تفعيل الإشعارات عبر البريد الإلكتروني لهذا التنبيه؟",
  "alert.management.disable.email.notification":
    "هل أنت متأكد من أنك تريد تعطيل الإشعارات عبر البريد الإلكتروني لهذا التنبيه؟",
  "alert.management.enable.sms.notification":
    "هل أنت متأكد أنك تريد  تفعيل الإشعارات عن طريق الرسائل القصيرة sms لهذا التنبيه؟",
  "Success Rate": "معدل النجاح",
  "alert.management.disable.sms.notification":
    "هل أنت متأكد أنك تريد تعطيل الإشعارات عن طريق الرسائل القصيرة sms لهذا التنبيه؟",
  "alert.management.subscription.status.success": "تم تحديث حالة الاشتراك بنجاح",
  "alert.management.subscription.status.error": "فشل تحديث حالة الاشتراك",
  "Execution Time": "مدة التنفيذ",
  "Process execution duration": "مدة التنفيذ",
  "Item execution duration": "مدة تنفيذ العنصر",
  "Total Executions": "إجمالي عمليات التنفيد",
  "Items success rate": "معدل نجاح العناصر",
  "Exceptions Rate": "معدل الاستثناءات",
  "Items exception rate": "معدل استثناء العناصر",
  "Time Saved": "الوقت الموفر",
  "Break-Even": "نقطة التعادل",
  "Number of completed items": "عدد العناصر المكتملة",
  "Add Scheduler": "أضف المجدول",
  "Edit Scheduler": "تعديل المجدول",
  "First day of month": "أول يوم في الشهر",
  "Last day of month": "آخر يوم في الشهر",
  "Day number": "رقم اليوم",
  "alert.management.email.helper":
    "يمكنك التحقق من صحة إضافة البريد الإلكتروني الخاص بك إما بالنقر فوق Enter أو Space.",
  "alert.management.sms.helper":
    "يمكنك التحقق من صحة إضافة رقم هاتفك إما بالنقر فوق Enter أو Space.",
  "alert.management.trigger.helper":
    "في حالة وجود عدة مشغلات ، يجب أن تكون جميع المشغلات صحيحة حتى يتم إرسال الإشعار.",
  "alerts.management.trigger.exceptionReasons.not.found": "لم يتم العثور على أسباب استثناء موجودة للعملية ونوع الاستثناء المحددين",
  Active: "نشط",
  info: "معلومات",
  error: "خطأ",
  EQUAL: "متساوي",
  TRUE: "صحيح",
  FALSE: "خطأ",
  "GREATER THAN": "أكبر من",
  "LESS THAN": "أصغر من",
  "GREATER OR EQUAL TO": "أكبر من أو يساوي",
  "LESS OR EQUAL TO": "أقل أو يساوي",
  "alerts.management.alertName.maxLength.error":
    "يجب ألا يتجاوز هذا الحقل 55 حرفًا!",
  "alerts.management.alertDescription.maxLength.error":
    "يجب ألا يتجاوز هذا الحقل 65535 حرفًا!",
  "select.all": "تحديد الكل",
  "add.fleet.form.vat.number.email.company.name.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني ورقم ضريبة القيمة المضافة واسم الشركة",
  "add.fleet.form.vat.number.email.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني ونفس رقم ضريبة القيمة المضافة",
  "add.fleet.form.vat.number.company.name.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس رقم ضريبة القيمة المضافة واسم الشركة",
  "add.fleet.form.email.company.name.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني واسم الشركة",
  "add.fleet.form.email.already.exist":
    "يوجد بالفعل مجموعة بنفس عنوان البريد الإلكتروني",
  "add.fleet.form.vat.number.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس رقم ضريبة القيمة المضافة",
  "add.fleet.form.company.name.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس اسم الشركة",
  "add.fleet.form.vat.number.email.company.name.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني ورقم ضريبة القيمة المضافة واسم الشركة و SIREN",
  "add.fleet.form.vat.number.email.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني ورقم ضريبة القيمة المضافة و SIREN",
  "add.fleet.form.vat.number.company.name.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس رقم ضريبة القيمة المضافة واسم الشركة و SIREN",
  "add.fleet.form.vat.number.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس رقم ضريبة القيمة المضافة ونفس SIREN",
  "add.fleet.form.email.company.name.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني واسم الشركة و SIREN",
  "add.fleet.form.email.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس عنوان البريد الإلكتروني و SIREN",
  "add.fleet.form.company.name.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس اسم الشركة و نفس SIREN",
  "add.fleet.form.siren.already.exist":
    "يوجد بالفعل مجموعة تحمل نفس صفارات الإنذار المبكر",
  // Resource Management
  "resource.management.name": "الاسم",
  "resource.management.formControl.actionsRunning": "الإجراءات قيد التشغيل",
  "resource.management.formControl.processesRunning": "العمليات قيد التشغيل",
  "resource.management.formControl.resourceName": "اسم الروبوت",
  "resource.management.formControl.resourceOriginalName": "اسم المورد الأصلي",
  "resource.management.formControl.statusDescription": "وصف الحالة",
  "resource.management.formControl.resourceId": "معرف الروبوت",
  "resource.management.formControl.unitsAllocated": "الوحدات المخصصة",
  "resource.management.solutionProvider": "مزود الحلول",
  "resource.management.displayStatus": "عرض الحالة",
  "resource.management.orchestratorName": "منظم",
  "resource.management.attributeName": "سمة الموارد",
  "resource.management.processesRunning": "العمليات الجارية",
  "resource.management.resourceId": "معرف الروبوت",
  "resource.management.resourceName": "اسم المورد",
  "resource.management.searchResource": "البحث عن روبوت",
  "Routes.orchestrators.administration.resources": "الروبوتات",
  "resource.management.displayHeader": "تفاصيل الروبوت",
  "resource.management.enable.confirmMsg":
    "هل أنت متأكد من رغبتك في تفعيل هذا الروبوت؟",
  "resource.management.disable.confirmMsg":
    "هل أنت متأكد من رغبتك في إلغاء تفعيل هذا الروبوت؟",
  "resource.management.disable.button.confirm": "إلغاء تفعيل",
  "resource.management.enable.button.confirm": "تفعيل",
  "resource.button.update": "تحديث",
  "resource.button.cancel": "إلغاء",
  "resource.button.discard": "تجاهل",
  "resource.update.confirmMsg": "هل أنت متأكد من رغبتك في تحديث معلومات الروبوت؟",
  "resource.update.discard":
    "هل أنت متأكد من أنك تريد تجاهل التغييرات التي أجريتها؟",
  "resource.modify": "تعديل",
  "routes.orchestrators.administration.resources.view": "عرض",
  "routes.orchestrators.administration.resources.edit": "تعديل",
  "routes.orchestrators.administration.resources": "الروبوتات",
  "administration.detail.entity.processes.title": "العمليات",
  "start.process.no.resource.assigned": "لا توجد موارد معينة أو الموارد المعينة غير متصلة بالإنترنت",
  "no.process.planned": "لا توجد عمليات مخطط لها حتى الآن",
  Fleet: "مجموعة",
  "Orchestrator Administration": "إدارة المنظم",
  "New opportunity created": "خلق فرصة",
  "Opportunity accepted": "تم قبول الفرصة",
  "Opportunity rejected": "تم رفض الفرصة",
  "Opportunity added to the road map": "تمت إضافة الفرصة إلى الخارطة ",
  "Opportunity archived": "تمت أرشفة الفرصة",
  "Process activated": "تم تفعيل العملية",
  "Process disabled": "تم تعطيل العملية",
  "Orchestrator enabled": "تم تفعيل المنظم",
  "Orchestrator disabled": "تم تعطيل المنظم",
  "Orchestrator actionable": "المنظم قابل للتنفيذ",
  "Orchestrator not actionable": "المنظم غير قابل للتنفيذ",
  "Schedule added": "تمت إضافة الجدول",
  "Time Spent": "قضاء الوقت",
  "IT Review": "مراجعة تكنولوجيا المعلومات",
  Comment: "تعليق",
  Schedules: "جداول",
  "Delete Schedule": "حذف الجدول الزمني",
  "Filter List": "قائمة الفلاتر",
  Validate: "تحقق",
  "kpi.header.breakEven.tooltip":
    "تم تقدير نقطة التعادل استنادًا إلى البيانات التاريخية. إنها تجيب عن متى يتم استعادة الاستثمار في العمليات بالكامل؟ النتائج مبنية على المعطيات المالية المُكوّنة لكل عملية ومرشح الوقت في لوحة المعلومات.",
  "kpi.header.roi.tooltip":
    "الإجابات عن سؤال حول كم تم كسب من الاستثمار الأولي بالفعل؟ النتائج مبنية على المعطيات المالية المُكوّنة لكل عملية ومرشح الوقت في لوحة المعلومات.",
  "kpi.header.breakEven.tooltip.nodata":
    "في انتظار مزيد من التنفيذات لتقدير نقطة التعادل.",
  "kpi.header.roi.tooltip.nodata":
    "في انتظار مزيد من التنفيذات لحساب نسبة الربحية.",
  "kpi.header.success.rate.tooltip":
    "تم حساب معدل النجاح لعناصر. النتائج مبنية على الإعدادات المُكوّنة لكل عملية ومرشح الوقت في لوحة المعلومات. ",
  "kpi.header.exception.rate.tooltip":
    "تم حساب معدل الاستثناءات لعناصر. النتائج مبنية على الإعدادات المُكوّنة لكل عملية ومرشح الوقت في لوحة المعلومات.",
  "dashboard.graph.treatedItems": "العناصر المكتملة",
  "dashboard.graph.pendingItems": "العناصر المعلقة",
  "dashboard.graph.exceptionItems": "عناصر الاستثناء",
  "dashboard.graph.totalItems": "مجموع العناصر",
  "Submitting...": "جارٍ الإرسال ...",
  "NOT VALIDATED": "لم يتم التحقق منه",
  "tooltip.action.hide": "إخفاء",
  "tooltip.action.show": "إظهار",
  "resource.management.deleteResourceActionMessage":
    "العناصر المحددة سيتم تغيير رؤيتها إلى إما مخفية أو مرئية.",
  "tooltip.action.showHiddenItems": "إظهار المخفية",
  "exception.analytics.typeNotFound": "نوع الاستثناء غير محدد",
  "orchestrator.management.formControl.sessionNumber":
    "عدد الدورات المسموح بها",
  "profile.user.general.title": " معلومات عامة",
  "profile.user.general.security": "الأمن",
  "rpa.export.rpa": "الأتمتة الروبوتية للعمليات",
  "rpa.export.qualification": "التأهيل",
  "user.add.admin": "المستخدم المسؤول",
  "Items status by process": "حالة العناصر حسب العملية",
  "Average Handling Time": "متوسط وقت المعالجة",
  "Current Period": "الفترة الحالية",
  "Last Period": "الفترة الأخيرة",
  "use intervals": "استخدم الفواصل",
  "use cumulative": "استخدم التراكمي",
  "please enter at least one email to enable email notification":
    "الرجاء إدخال عنوان بريد إلكتروني واحد على الأقل ل تفعيل إشعارات البريد الإلكتروني",
  "please enter at least one phone number to activate the sms notification":
    "الرجاء إدخال رقم هاتف واحد على الأقل لتفعيل إشعارات الرسائل النصية",
  Exception: "استثناء",
  Success: "نجاح",
  "The calculation of the KPIs will be based on an interval":
    "سيتم حساب مؤشرات الأداء الرئيسية بناءً على فاصل زمني.",
  "Calculation of the KPIs for the whole time":
    "حساب مؤشرات الأداء الرئيسية لكامل الوقت.",
  "Receive alerts from the following date":
    "استقبال التنبيهات ابتداءً من التاريخ التالي.",
  "Stop receiving alerts from the following date":
    "إيقاف استقبال التنبيهات ابتداءً من التاريخ التالي.",
  "dashboard.tooltip.timesaved":
    "يعرض هذا الرسم البياني توزيع الوقت الموفّر (بالساعات) على مدار الفترة المحددة في مرشح الوقت بلوحة المعلومات. يتم حساب النتائج بناءً على المعطيات المالية المُكوّنة لكل عملية ومرشح عملية بلوحة المعلومات.",
  "dashboard.tooltip.processOverview":
    "يُظهِر هذا الرسم البياني توزيع وقت التنفيذ لكل عملية مقارنةً بكل العمليات. تعتمد النتائج على مرشح الوقت في لوحة المعلومات.",
  "dashboard.tooltip.itemsProcess":
    "يُظهِر هذا الرسم البياني تفصيل حالة العناصر لأفضل العمليات. تعتمد النتائج على مرشح الوقت في لوحة المعلومات.",
  "dashboard.tooltip.executionsProcess":
    "يُظهِر هذا الرسم البياني تفصيل التنفيذات على مدار الفترة المحددة في مرشح الوقت بلوحة المعلومات.",
  "dashboard.tooltip.averageTime":
    "الوقت المتوسط للمعالجة (AHT) هو الوقت المتوسط الذي استغرق تنفيذ عنصر في هذه العملية خلال الفترة المحددة في مرشح الوقت.",
  STOPPED: "متوقف",
  COMPLETED: "مكتمل",
  DEBUGGING: "تصحيح الأخطاء",
  ARCHIVED: "مؤرشف",
  STOPPING: "إيقاف",
  WARNING: "تحذير",
  "process.history.duration": "مدة التنفيذ",
  "process.history.completedItems": "العناصر المكتملة",
  "process.history.exceptionItems": "عناصر الاستثناء",
  "process.history.resource": "روبوت",
  AUTOMATIC: "أوتوماتيكي",
  MANUAL: "يدوي",
  "Process data mapping": "تعيين البيانات",
  "Are you sure you want to update the mapping ?":
    "هل أنت متأكد أنك تريد تحديث التعيين؟",
  "no.data-mapping.message":
    "لا يمكن إجراء التعيين إلا بعد معالجة عنصر العملية الأول.",
  Data: "بيانات",
  "New name": "اسم جديد",
  Display: "عرض",
  "display.in.items.list": "إظهار في قائمة العناصر",
  "Orchestrator configuration": " إعداد وضبط المنظم",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "سيقوم SmartRoby بإضافة أعمدة وإنشاء طرق عرض ومشغلات على قاعدة بيانات المنظم الخاص بك.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "قم بتهيئة الموصلات لمزامنة البيانات بين المنظم الخاص بك و SmartRoby.",
  "sync.Execute": "تنفيذ",
  "sync.Initialize": "التهيئة",
  "Database configuration": "إعداد وضبط قاعدة البيانات",
  "Orchestrator/SmartRoby synchronization": "Orchestrator / مزامنة SmartRoby",
  "Orchestrator status": "حاله المنظم",
  "sync.Refresh": "تحديث",
  "sync.General": "General",
  "Database connection": "اتصال قاعدة البيانات",
  "Synchronization engine": "محرك التزامن",
  "sync.Synchronization": "التزامن",
  "sync.process": "عمليات",
  "sync.execution": "تنفيذات",
  "sync.resource": "موارد",
  "sync.schedule": "جداول",
  "sync.work_queue_item": "العناصر",
  "Number of items with Exceptions": "عدد العناصر مع استثناءات",
  "sync.deleted_record": "حذف السجلات",
  "sync.license": "التراخيص",
  "sr.sync.license": "SmartRoby تراخيص",
  "resource.management.hide.confirmMsg":
    "هل أنت متأكد أنك تريد إخفاء هذا الروبوت؟",
  "resource.management.show.confirmMsg":
    "هل أنت متأكد أنك تريد إظهار هذا الروبوت؟",
  "filter.clear": "مسح الفلاتر ",
  Reasons: "الأسباب",
  "List of conflicts for": "قائمة التعارضات لـ",
  allowedSessionsExceededForAnOrchestrator:
    "تم العثور على {{parallelExecutions}} مهام متوازية بينما تم السماح للمنظم: '{{OrhcestratorName}}' {{allowedSessions}} من الجلسات المسموح بها.",
  concurrentExecutionInTheSameResourceMessage:
    "تم العثور على أكثر من عملية تنفيذ في الروبوت: '{{resourceName}}'. ستبدأ عمليات الإعدام الأخرى المخطط لها بعد اكتمال التنفيذ الأول وفقًا لهذا الأمر:",
  "Number of pending items": "عدد العناصر قيد الانتظار",
  "kpi.parameter.allowScheduling": "يمكن جدولتها",
  "kpi.parameter.allowManual": "يمكن أن تبدأ يدويا",
  "kpi.parameter.retryable": "يمكن إعادة محاولة عناصر الاستثناء",
  "kpi.parameter.retryableWithData":
    "يمكن إعادة محاولة عناصر الاستثناء ببيانات مختلفة",
  "kpiParam.schedule.warning":
    "هذه العملية لها جداول بالفعل. إذا تابعت ، فسيتم حذفهم جميعًا.",
  "Warnings Management": "إدارة التحذيرات",
  "processStore.year": "سنة",
  "processStore.years": "سنين",
  "can.be.edited.for.retry": "يمكن تعديله لإعادة المحاولة",
  retry: "إعادة المحاولة",
  "retry-dialog.title.one-item": "هل أنت متأكد أنك تريد إعادة محاولة هذا الاستثناء؟",
  "retry-dialog.title.multiple-items": "هل أنت متأكد أنك تريد إعادة المحاولة لـ {{count}} استثناءات؟",
  "retry-dialog.text.one-item":
    "ستتم إعادة محاولة العنصر في المرة التالية التي يتم فيها تنفيذ العملية.",
  "retry-dialog.text.multiple-items":
    "سيتم إعادة المحاولة للعناصر في المرة القادمة التي يتم فيها تنفيذ عملياتها.",
  "retry-dialog.pending-alert": "يوجد محاولة إعادة معلقة لهذا العنصر بالفعل.",
  "retry-dialog.pending-alert-multiple": "إعادة المحاولة المعلقة لهذه العناصر موجودة بالفعل",
  "retry-dialog.message.warning.pending": " (العناصر التي لها إعادة محاولة معلقة بالفعل لن تخضع لإعادة محاولة أخرى)",
  "retry-dialog.not-retryable":
    "العناصر المحددة تنتمي إلى عملية لا يمكن إعادة محاولتها.",
  "retry-dialog.radio.same-data": "إعادة المحاولة بنفس البيانات",
  "retry-dialog.radio.changed-data": "إعادة المحاولة ببيانات مختلفة",
  "retry-dialog.table.name": "اسم",
  "retry-dialog.table.old-value": "القيمة القديمة",
  "retry-dialog.table.new-value": "القيمة الجديدة",
  "retry-dialog.message.success.one-item": "تم إنشاء محاولة إعادة المهمة بنجاح",
  "retry-dialog.message.success.multiple-items":
    "تم إنشاء {{count}} محاولات إعادة المهمة بنجاح.",
  "retry-dialog.message.error.one-item":
    "فشل إنشاء إعادة المحاولة الخاصة بالعنصر",
  "retry-dialog.message.error.multiple-items":
    "فشل في إنشاء العناصر التي أعادت المحاولة",
  "restriction.form.hour.maxValue.error":
    "يجب أن تكون القيمة أقل من أو تساوي 23.",
  "restriction.form.min.sec.maxValue.error":
    "يجب أن تكون القيمة أقل من أو تساوي 59.",
  attempt: "محاولة",
  "attempt.chip.tooltip": "هذه محاولة {{attempt}} لهذا العنصر",
  "exception-workflow.status.ready": "غير معالج",
  "exception-workflow.status.taken": " تم تعيينه",
  "exception-workflow.status.done": "تمت المعالجة",
  "exception-workflow.drawer.title": "إدارة الاستثناءات",
  "exception-workflow.action.take": "أخذ",
  "exception-workflow.action.assign": "تعيين",
  "exception-workflow.action.release": "إطلاق",
  "exception-workflow.action.reopen": "أعد فتح",
  "exception-workflow.action.markAsDone": "وضع علامة كمعالج",
  "exception-workflow.log.take":
    "تم أخذ  بواسطة <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
    "تم التعيين إلى <b> {{targetUser.firstname}} {{targetUser.lastname}} </b> بواسطة <b> {{user.firstname}} {{user.lastname}} </b>",
  "exception-workflow.log.markAsDone":
    "تم وضع علامة تم بواسطة <b> {{user.firstname}} {{user.lastname}} </b>",
  "exception-workflow.log.release":
    "تم إصداره بواسطة <b> {{user.firstname}} {{user.lastname}} </b>",
  "exception-workflow.log.reopen":
    "أعاد فتحه <b> {{user.firstname}} {{user.lastname}} </b>",
  "exception-workflow.snackbar.success": "تم تنفيذ الإجراء بنجاح",
  "exception-workflow.snackbar.error": "فشل في تنفيذ الإجراء",
  "exception-workflow.confirm.take":
    "هل أنت متأكد أنك تريد أن تأخذ هذا الاستثناء؟",
  "exception-workflow.confirm.assign":
    "هل أنت متأكد من أنك تريد تعيين هذا الاستثناء إلى {{selectedUser.firstName}} {{selectedUser.lastName}}؟",
  "exception-workflow.confirm.markAsDone":
    "هل أنت متأكد أنك تريد وضع علامة تم على هذا الاستثناء؟",
  "exception-workflow.confirm.release":
    "هل أنت متأكد أنك تريد تحرير هذا الاستثناء؟",
  "exception-workflow.confirm.reopen":
    "هل أنت متأكد أنك تريد إعادة فتح هذا الاستثناء؟",
  "exception-workflow.open": "مسار العمل",
  "exception-workflow.open-bulk": "مسار العمل",
  "exception-workflow.bulk-drawer.title": "إدارة الاستثناءات",
  "exception-workflow.no-bulk-action-possible":
    "لا يمكن تنفيذ أي إجراء لتركيبة الاستثناءات المحددة.",
  "exception-workflow.selected-message": "تم اختيار {{count}} استثناء",
  "exception-workflow.confirm.bulk-take":
    "هل أنت متأكد من أنك تريد أخذ {{count}} استثناء؟",
  "exception-workflow.confirm.bulk-assign":
    "هل أنت متأكد من أنك تريد تعيين {{count}} استثناء لـ {{selectedUser.firstName}} {{selectedUser.lastName}}؟",
  "exception-workflow.confirm.bulk-markAsDone":
    "هل أنت متأكد من أنك تريد وضع علامة على {{count}} استثناء كمنتهية؟",
  "exception-workflow.confirm.bulk-release":
    "هل أنت متأكد من أنك تريد إصدار {{count}} استثناء؟",
  "exception-workflow.confirm.bulk-reopen":
    "هل أنت متأكد من أنك تريد إعادة فتح {{count}} استثناء؟",
  "exception-workflow.choose-user": "اختر مستخدم",
  "exception-workflow.snackbar.success.take": "تم قبول الاستثناء بنجاح",
  "exception-workflow.snackbar.success.assign": "تم تعيين الاستثناء بنجاح",
  "exception-workflow.snackbar.success.assign.notify":
    "تم إبلاغ المكلف بالمهمة",
  "exception-workflow.snackbar.success.release": "تم إصدار الاستثناء بنجاح",
  "exception-workflow.snackbar.success.markAsDone":
    "تم وضع علامة على الاستثناء بنجاح كمكتمل",
  "exception-workflow.snackbar.success.reopen": "تم فتح الاستثناء بنجاح",
  "exception-workflow.snackbar.success.bulkTake": "تم أخذ الاستثناءات بنجاح!",
  "exception-workflow.snackbar.success.bulkAssign":
    "تم تعيين الاستثناءات بنجاح",
  "exception-workflow.snackbar.success.bulkRelease":
    "تم إلغاء الاستثناءات بنجاح",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "تم وضع علامة على الاستثناءات كمكتملة بنجاح",
  "exception-workflow.snackbar.success.bulkReopen":
    "تم إعادة فتح الاستثناءات بنجاح",
  "processe.schedules.onDay.first": "- في يوم {{startDay}} الاول",
  "processe.schedules.onDay.second": "- في يوم {{startDay}} الثاني",
  "processe.schedules.onDay.third": "- في يوم {{startDay}} الثالث",
  "processe.schedules.onDay": "- في يوم {{startDay}}",
  "exception-workflow.assign.notification.message":
    "عزيزي {{assigneeName}} ، <br> <br> <br> تم تعيين استثناء لك. <br> <br> انتقل إلى {{pageHyperlink}} للتحقق من ذلك. <br> <br> <br > SmartRoby",
  "exception-workflow.bulkAssign.notification.message":
    "عزيزي {{assigneeName}} ، <br> <br> <br> {{numberExceptions}} تم تعيين استثناءات لك. <br> <br> انتقل إلى {{pageHyperlink}} للتحقق منها. <br> < br> <br> SmartRoby",
  "exception-workflow.assign.notification.exceptionsPage": "صفحة الاستثناءات",
  "orchestrator.license.status": "الحالة",
  "orchestrator.license.status.ACTIVE": "نشط",
  "orchestrator.license.status.EXPIRED": "منتهي الصلاحية",
  "orchestrator.license.status.NOT_YET_ACTIVE": "لم يتم التفعيل بعد.",
  "process.laststage": "المرحلة الأخيرة: {{lastStage}}",
  "analytics.preset.button.label": "الإعدادات المسبقة",
  "analytics.preset.load": "تحميل الإعداد المسبق الموجود",
  "analytics.preset.create": "إنشاء إعداد مسبق جديد",
  "analytics.preset.noPresets": "لا توجد إعدادات مسبقة محفوظة",
  "analytics.preset.dialog.label": "الإعداد المسبق الجديد",
  "analytics.preset.dialog.field.label": "اسم الإعداد المسبق",
  "analytics.preset.dialog.field.submit": "إنشاء إعداد مسبق",
  "analytics.preset.dialog.delete.label": "حذف الإعداد المسبق",
  "analytics.preset.dialog.delete.text":
    "هل أنت متأكد أنك تريد حذف هذا الإعداد المسبق؟",
  "analytics.preset.dialog.delete.submit": "حذف الإعداد المسبق",
  "resource.tooltip.hide": "هل أنت متأكد أنك تريد إخفاء {{count}} من الروبوتات",
  "resource.tooltip.unhide": "هل أنت متأكد أنك تريد إظهار {{count}} من الروبوتات",
  "export.confirmation.radio.grouped": "تصدير مع تجميع تفاصيل العنصر.",
  "export.confirmation.radio.columns": "تصدير مع تفاصيل العنصر في الأعمدة.",
  "export.confiramtion.warnning":
    "ل تفعيل هذا الخيار ، عليك تحديد عملية واحدة.",
  FINISHED: "تم الانتهاء",
  EXPORTING: "تصدير",
  COLLECTING: "جمع",
  "exporting.title": "تصدير الملفات",
  "exporting.no.data": "لا توجد ملفات للتنزيل",
  "exporting.btn.download.all": "تحميل الكل",
  "exporting.btn.download": "تحميل",
  "exporting.btn.delete.all": "حذف الكل",
  Executions: "تنفيذات",
  "routes.orchestrators.executions": "تنفيذات",
  "no.executions.message": "لم يتم العثور على أي تنفيذات",
  Resource: "روبوت",
  Trigger: "مشغل",
  MANUALLY: "يدويا",
  SCHEDULED: "مجدول",
  KEEP: "ابق في قائمة الانتظار",
  DELETE: "حذف من قائمة الانتظار",
  Dates: "تواريخ",
  "Launching date": "تاريخ الإطلاق",
  "Launching date (Descending)": "تاريخ الإطلاق (تنازلي)",
  "Launching date (Ascending)": "تاريخ الإطلاق (تصاعدي)",
  "queue.management.status": "الحالة",
  "queue.management.name": "الاسم",
  "queue.management.creation-date": "تاريخ الإنشاء",
  "queue.management.active-since": "نشط منذ",
  "queue.management.nb-licences": "عدد التراخيص",
  "queue.management.orchestrator": "منظم",
  "queue.management.search": "بحث",
  "queue.management.add": "إضافة قائمة انتظار",
  "queue.management.delete.confirm-msg":
    "هل أنت متأكد أنك تريد حذف قائمة الانتظار المحددة؟",
  "queue.management.bulk-delete.confirm-msg":
    "هل أنت متأكد أنك تريد حذف قوائم الانتظار المحددة؟",
  "queue.management.enable.confirm-msg":
    "هل أنت متأكد من أنك تريد تفعيل قائمة الانتظار هذه؟",
  "queue.management.enable.confirm": "تفعيل قائمة الانتظار",
  "queue.management.disable.confirm-msg":
    "هل أنت متأكد أنك تريد تعطيل قائمة الانتظار هذه؟",
  "queue.management.disable.confirm": "تعطيل قائمة الانتظار",
  "queue.management.section.info": "معلومات عامة",
  "queue.management.section.processes": "العمليات",
  "queue.management.section.resources": "الروبوتات",
  "queue.management.form.save.success": "تم إنشاء قائمة انتظار SR بنجاح",
  "queue.management.form.update.success": "تم تحديث قائمة انتظار SR بنجاح",
  "queue.management.form.save.error": "فشل إنشاء قائمة انتظار SR",
  "queue.management.form.update.error": "فشل تحديث قائمة انتظار SR",
  "queue.management.form.validation.required": "هذا الحقل مطلوب",
  "queue.management.form.validation.negative-nb-licences":
    "لا يمكن أن يكون عدد التراخيص سالبًا",
  "queue.management.update": "تحديث القائمة انتظار",
  "queue.management.save.confirm-msg":
    "هل أنت متأكد أنك تريد إنشاء هذه القائمة انتظار SR؟",
  "queue.management.update.confirm-msg":
    "هل أنت متأكد أنك تريد تحديث هذه قائمة انتظار SR؟",
  "queue.management.reset.confirm-msg":
    "هل أنت متأكد أنك تريد حذف جميع التنفيذات الفائتة لهذه قائمة انتظار SR؟",
  "restriction.form.missedExecutionsPolicy": "سياسة التنفيذات الفائتة",
  "restriction.userGuide.missedExecutionsPolicy":
    "يُحدد ما إذا كان يجب الاحتفاظ بالتنفيذ الفائت أو إزالته من قائمة الانتظار في SR.",
  "queue.management.choose.resource":
    "بعض الجداول الزمنية غير معينة لأي روبوت. عند نقلها إلى المخطط الزمني الأصلي، يُرجى تعيينها لـ:",
  "queue.management.assign.process.part1":
    "أنت على وشك تفويض تنفيذ {{this_process}} إلى قوائم الانتظار الذكية. لضمان الأداء المتوقع، يرجى عدم إنشاء جداول زمنية وإطلاق التنفيذات خارج SmartRoby.",
  "queue.management.assign.process.part2":
    "تم العثور على جدول زمني لـ {{this_process}} في المحرك الأصلي. هل ترغب في ترحيله لاستخدامه مع قوائم الانتظار الذكية؟",
  "queue.management.assign.process.part2_plural":
    "أنت على وشك إلغاء تفويض تنفيذات {{this_process}} من قوائم الانتظار الذكية. في المستقبل، يمكن أن تكون جميع التنفيذات والجداول إما من SmartRoby أو من المحرك الأصلي.",
  "queue.management.unassign.process.part1":
    "أنت على وشك إلغاء تفويض تنفيذات {{this_process}} من قوائم الانتظار الذكية. في المستقبل، يمكن أن تكون جميع التنفيذات والجداول إما من SmartRoby أو من المحرك الأصلي.",
  "queue.management.unassign.process.part2":
    "تم العثور على جدول زمني لقائمة الانتظار الذكية، هل ترغب في نقلها إلى المحرك الأصلي؟",
  "queue.management.unassign.process.part2_plural":
    "{{nbSchedules}} جداول زمني لقائمة الانتظار الذكية تم العثور عليها، هل ترغب في نقلها إلى المحرك الأصلي؟",
  thisProcess: "هذه العملية",
  thisProcess_plural: "عمليات قائمة الانتظار",
  atIts: "في",
  atIts_plural: "في",
  some: "بعض",
  "add.schedule.same.date.constraint": "يوجد جدول زمني بنفس تاريخ البدء بالفعل",
  "resource-selector.available-resources": "الروبوتات المتاحة",
  "resource-selector.assigned-resources": "الروبوتات المعينة",
  "queue.management.form.validation.nb-licences-bigger-than-remaining":
    "هناك {{count}} تراخيص متبقية في المنظم المحدد",
  "queue.management.no-data": "لا توجد قوائم انتظار حتى الآن",
  "queue.management.open-queue": "افتح في قائمة انتظار SR",
  "queue.management.reset-queue": "إعادة تعيين قائمة انتظار SR",
  "execution.manager.title": "قائمة انتظار SR",
  "queue reseted successfully": "تم إعادة تعيين قائمة انتظار SR بنجاح.",
  "error while reseting queue": "فشل في إعادة تعيين قائمة انتظار SR.",
  "Add SR Queue": "إضافة قائمة انتظار SR",
  "Edit SR Queue": "تعديل قائمة انتظار SR",
  "Delete SR Queue": "حذف قائمة الانتظار SR",
  "process.assigned.srqueue.tooltip":
    "تم تعيين هذه العملية إلى قائمة انتظار SR.",
  "process.not.assigned.srqueue.tooltip":
    "لم يتم تعيين هذه العملية لأي قائمة انتظار SR",
  "number of runing executions singular": "هناك تنفيذ قيد التقدم",
  "number of runing executions plural": "بعض التنفيذات قيد التقدم",
  "not enough licenses":
    "ليس لديك أي تراخيص متاحة. يُرجى التواصل مع مسؤول RPA الخاص بك.",
  "check.connectivity":
    "الرجاء التحقق من الاتصال بخادم التطبيق {{serverName}}، يبدو أن الاتصال قد فُقد.",
  "deleted by sr policy":
    "جدول زمني تم حذفه بواسطة سياسة قوائم الانتظار الذكية.",
  "execution is deleted": "تم حذف التنفيذ يدويًا",
  "execution is missed": "تم فقدان تنفيذ",
  "missed raison": "غاب بسبب",
  UNAVAILABLE_ROBOTS: "الروبوتات الغير متاحة",
  NOT_ENOUGH_LICENCES: "لا توجد تراخيص كافية",
  BP_UNREACHABLE: "لا يمكن الوصول إلى BP",
  BP_UNRESPONSIVE: "BP لا تستجيب",
  "resource.formControl.succesUpdate": "تم تحديث معلومات الروبوت بنجاح",
  "cant perform multi queue migration":
    "تم العثور على جداول زمنية لبعض طوابير SR في الطوابير المحددة. لا يمكن نقلها إلى المُنفذ الأصلي.",
  "something went wrong":
    "حدث خطأ ما أثناء معالجة استفسارك ، يرجى إعادة المحاولة.",
  Continue: "متابعه",
  "Something.Wrong": "شيء ما خاطئ",
  "export.successful.notification":
    "تمت إضافة ملفك إلى قسم تصدير الملفات وهو الآن جاهز للوصول إليه وتنزيله. كانت الإضافة ناجحة.",
  revoke: "إلغاء",
  "division.formSection.divisionInfo": "معلومات القسم",
  "division.formSection.divisionProcess": "عمليات القسم",
  "division.formSection.divisionUser": "مستخدمي القسم",
  "division.formSection.divisionAdmin": "مشرفو القسم",
  "division.name": "اسم القسم",
  "division.description": "الوصف",
  "division.fleet": "اسم المجموعة",
  "division.cancel": "إلغاء",
  "division.previous": "السابق",
  "division.save": "حفظ",
  "division.next": "التالي",
  "divisions.module.add.button": "أضف القسم",
  "usersSelector.availableUsers": "المستخدمون المتاحون",
  "usersSelector.assignedUsers": "المستخدمون المعينون",
  "usersSelector.availableAdmins": "المشرفون المتاحون",
  "usersSelector.assignedAdmins": "المشرفون المعينون",
  "divisions.module.title": "ادارة الاقسام",
  "divisions.status": "الحالة",
  "divisions.division": "قسم",
  "divisions.fleet": "مجموعة",
  "divisions.creation.date": "تاريخ الإنشاء",
  "division.form.updateSuccess": "تم تحديث القسم بنجاح",
  "division.form.addSuccess": "تمت إضافة القسم بنجاح",
  "divisions.module.delete.confirmMsg": "هل أنت متأكد أنك تريد حذف هذا القسم؟",
  "divisions.module.deleteClientsActionMessage":
    "هل أنت متأكد أنك تريد حذف كل هذه الأقسام؟",
  "divisions.module.enaDes.confirmMsg":
    "هل أنت متأكد أنك تريد تفعيل هذا القسم؟",
  "divisions.module.enaDes.button.confirm": "تفعيل",
  "divisions.module.desaDes.button.confirm": "إلغاء تفعيل",
  "divisions.module.desaDes.confirmMsg":
    "هل أنت متأكد أنك تريد تفعيل هذا القسم؟",
  "divisions.module.searchClient": "البحث عن قسم",
  "division deleted successfully": "تم حذف القسم بنجاح.",
  "no.division.message": "عذراً، لا توجد أقسام متاحة حالياً.",
  "fleet.formSection.divisions": "الأقسام",
  "divisionsSelector.availableDivision": "الأقسام المتاحة",
  "divisionsSelector.assignedDivision": "الأقسام المعينة",
  "custom.kpi.item": "العناصر",
  "custom.kpi.process": "عملية",
  "custom.kpi.processExecution": "التنفيذات",
  "custom.kpi.pending": "معلق",
  "custom.kpi.completedSuccess": "تم الانتهاء بنجاح",
  "custom.kpi.completedWithException": "تم الانتهاء  بحدوث استثناء",
  "custom.kpi.completedWithBusinessException":
    "تم الانتهاء  بحدوث استثناء في العملية",
  "custom.kpi.completedWithIntSysException":
    "تم الانتهاء  بحدوث استثناء داخلي /  في النظام",
  "custom.kpi.executionTimeHours": "ساعة وقت التنفيذ",
  "custom.kpi.timeSavedHours": "ساعة وقت التوفير",
  "reporting.createKpi": "إنشاء مؤشر أداء  جديد",
  "custom.kpi.dialog.create.label": "مؤشر أداء جديد",
  "custom.kpi.dialog.field.name": "اسم مؤشر الأداء  (KPI)",
  "custom.kpi.dialog.field.element": "لكل واحد",
  "custom.kpi.dialog.field.detail": "تفصيل",
  "custom.kpi.dialog.field.allocation": "تخصيص",
  "custom.kpi.dialog.field.unitofmesure": "وحدة القياس",
  "custom.kpi.formControl.required": "حقل مطلوب",
  "custom.kpi.formControl.pattern": "يرجى إدخال رقم",
  "custom.kpi.dialog.create.field.submit": "(KPI)إنشاء مؤشر الأداء",
  "custom.kpi.dialog.create.label.edit": "تحديث مؤشر الأداء المخصص",
  "custom.kpi.dialog.create.field.edit": "تحديث  مؤشر الأداء  (KPI)",
  "custom.kpi.dialog.delete.label": "حذف  مؤشر الأداء  (KPI)",
  "custom.kpi.dialog.delete.text":
    "هل أنت متأكد أنك تريد حذف مؤشر الأداء  (KPI)؟",
  "custom.kpi.dialog.delete.submit": "حذف مؤشر الأداء  (KPI)",
  "fleets.fleet": "المجموعة",
  "no.fleet.message": "لا يوجد مجموعات حتى الآن",
  "instance.owner": "مالك النسخة",
  "instance.owner.confirmMsg":
    "هل أنت متأكد أنك تريد المتابعة لتعيين مالك جديد للنسخة؟ ستؤدي هذه العملية إلى فقدان النسخة الخاصة بالمجموعة لوضع مالك النسخة.",
  "orchestrator.management.formControl.isPrivate": "منظم خاص",
  "orchestrator.management.formControl.automatic.tags.creation": "الإنشاء التلقائي للعلامات",
  "orchestrator.management.formControl.automatic.tags.creation.information": "سيتم تعيين جميع العمليات التلقائية داخل المجموعة إلى العلامة المقابلة. Blue Prism سيؤدي تمكين هذا الخيار إلى إنشاء علامات تلقائيًا فيمنظم العمليات لكل مجموعة من العمليات في",
  private: "خاص",
  "fleet.with.no.users":
    "لا يمكن جعل هذه المجموعة  مالكًا للنسخة لأنه ليس لديه أي مستخدمين.",
  "fleet.with.no.admins":
    "لا يمكن جعل هذه المجموعة  مالكًا للنسخة لأنه ليس لديه أي مسؤولين.",
  "user.fleet.disabled.message":
    "لا يمكن تفعيل هذا المستخدم لأن مجموعته معطلة.",
  "search.available.processes": "البحث عن العمليات المتاحة",
  "search.assigned.processes": "البحث عن العمليات المعينة",
  "search.available.tags": "البحث عن الوسوم المتاحة",
  "search.available.users": "البحث عن المستخدمين المتاحين",
  "search.assigned.users": "البحث عن المستخدمين المعينين",
  "search.available.divisions": "البحث عن الأقسام المتاحة",
  "orchestrator.license.originalId": "الرقم المعرف  للترخيص الأصلي",
  "orchestrator.license.starts": "يبدأ",
  "orchestrator.license.expires": "ينتهي",
  "orchestrator.license.installedOn": "مثبتة",
  "orchestrator.license.maxConcurrentSessions": "عدد الدورات",
  "search.assigned.divisions": "البحث عن الأقسام المعينه",
  "search.available.resources": "البحث عن الروبوتات المتاحة",
  "search.assigned.resources": "بحث عن الروبوتات المعينه",
  "drag.and.drop.here": "قم بالسحب والإسقاط هنا",
  "tooltip.action.license": "التراخيص",
  "A license is one month away from expiry":
    "تبقى شهر واحد على انتهاء صلاحية الترخيص",
  "A license is two months away from expiry":
    "تبقى شهران على انتهاء صلاحية الترخيص",
  "Orchestrator licenses": "تراخيص المنظم",
  Tags: "الوسوم",
  "tag.management.add.button": "إضافة وسم",
  "tag.management.searchTag": "البحث عن الوسوم",
  "no.tags.message": "لا يوجد وسوم حتى الآن",
  "tag.management.add": "إضافة",
  "tag.management.update": "تحديث",
  "tag.add.previous": "السابق",
  "tag.management.save.confirm-msg": "هل ترغب حقًا في إنشاء هذا الوسم؟",
  "tag.management.update.confirm-msg": "هل ترغب حقًا في تحديث هذا الوسم؟",
  "tag.form.addSuccess": "تمت إضافة الوسم بنجاح",
  "tag.deleted.success": "تم حذف الوسم بنجاح",
  "tag.form.next": "التالي",
  "tag.form.name": "اسم الوسم",
  "tag.form.description": "وصف الوسم",
  "tooltip.action.archive": "أرشيف",
  "archive.confirm.msg": "سيتم أرشفة العلامة (الوسوم) المحددة",
  "tag.management.btn.confirm": "أرشيف",
  "tag.archived.success": "تمت أرشفة الوسم",
  "tag.management.deleteTagActionMessage": "حذف الوسم (الوسوم) المحددة",
  "tag.management.archived": "مؤرشف",
  "tag.status": "الحالة",
  "tag.icon": "أيقونة",
  "tag.name": "الاسم",
  "tag.description": "الوصف",
  "tag.management.name": "الاسم",
  "tag.management.formControl.desc": "الوصف",
  "wrong.file.type": "نوع الملف غير صالح",
  activation: "تفعيل",
  license: "الترخيص",
  "import your license here": "أولج الترخيص الخاص بك هنا",
  activate: "تفعيل",
  "licence.id": "معرّف الرخصة",
  "licence.licensee": "صاحب الرخصة",
  "licence.start.at": "يبدأ عند",
  "licence.end.at": "ينتهي عند",
  "licence.validation.valid": "لديك ترخيص صالح",
  "licence.validation.expire": "انتهت صلاحية الترخيص الخاص بك. يرجى تجديدها",
  "licence.goback.btn": "اذهب الى الصفحة الرئيسية",
  "tag.management.hide.confirmMsg":
    "هل أنت متأكد من رغبتك في حذف الوسم؟ سيتم فقدان جميع البيانات بشكل دائم",
  "productivity.hrs": "الإنتاجية (ساعات)",
  Occupancy: "نسبة الاشغال (%)",
  "dashboard.monitoring.mode": "وضع المراقبة",
  "HRS Saved": "تم حفظ الوقت (ساعات)",
  "Resource Productivity": "إنتاجية الموارد",
  "Process Activity": "نشاط العملية",
  "Working Time (HRS)": "وقت العمل (ساعات)",
  "Processed Items": "العناصر المعالجة",
  "HRS Runtime": "وقت تشغيل (ساعات)",
  mins: "دقائق",
  "recently.used.processes": "العمليات الأكثر إستعمالا",
  "process.total.cost": "التكلفة الإجمالية للعملية",
  "tag.management.form.save.success": "تم حفظ الوسم بنجاح",
  "tag.management.form.update.success": "تم تحديث الوسم بنجاح",
  "tag.management.form.save.error": "فشل في إنشاء الوسم",
  "tag.management.form.update.error": "فشل في تحديث الوسم",
  "restriction.form.estimatedExecutionDuration": "المدة التنفيذية المقدرة",
  "add.schedule": "إنشاء جدول زمني",
  "add.process": "إضافة عملية",
  "An execution trigerred manually on the same resource has already been requested for this process":
    "تم طلب تنفيذ يدوي على نفس الروبوت مسبقًا لهذه العملية",
  "Process UoM": "وحدة قياس العملية",
  "create.opportunity": "إنشاء فرصة",
  "Are you sure you want to delete this frequency ?":
    "هل أنت متأكد أنك تريد حذف هذه التكرار؟",
  "What does an execution represent?": "ماذا يمثل كل تنفيذ؟",
  "Frequency Deleted Successfully": "تم حذف التكرار بنجاح",
  Missing: "مفقود",
  "timeUnite.manDay": "يوم عمل",
  "timeUnite.fte": "FTE",
  "user.permission.templateDelete.success": "تم حذف القالب بنجاح",
  "reporting.successRate": "نسبة النجاح للعناصر",
  "reporting.exceptionRate": "نسبة الاستثناء للعناصر",
  "Results for": "النتائج لـ",
  "help.tags": "يرجى اختيار العلامة (أو العلامات) المعنية بالتحليلات",
  "Financial Parameters": "المعطيات المالية",
  Workflow: "سير العمل",
  "report.type": "نوع التقرير",
  "create.scheduler": "إنشاء جدول",
  "Confirm frequency": "تأكيد التكرار",
  "Update frequency": "تحديث التكرار",
  "rpa.create.businessApplication": "قائمة التطبيقات هنا",
  "status.running.with.warning": "يعمل بتحذير",
  "retry.mark.done": "إعادة المحاولة ووضع علامة كتمت المعالجة",
  "queue.management.new": "إنشاء قائمة الانتظار",
  "custom.kpi.completed": "تم الانتهاء بنجاح",
  "instance.owner.can.not.be.disabled": "لا يمكن تعطيل مالك الحالة",
  "instance.owner.only.active.admin.can.not.be.disabled":
    "يمكن تعطيل المسؤول النشط الوحيد لمالك الحالة",
  "invalid license file": "ملف ترخيص غير صالح",
  "invalid signature": "توقيع غير صالح",
  "empty file": "ملف فارغ",
  "wrong file extension": "امتداد ملف خاطئ",
  "missing file": "ملف مفقود",
  "the submitted license file is invalid": "ملف الترخيص المقدم غير صالح",
  "the signature of the license is invalid": "توقيع الترخيص غير صالح",
  "the submitted file is empty": "الملف المقدم فارغ",
  "we only accept .lic files": "نحن نقبل فقط ملفات .lic",
  "please upload a license file": "يرجى تحميل ملف الترخيص",
  "scheduler.is.required": "الجدول الزمني مطلوب",
  "try.active.alert":
    "العملية {{processName}} مخفية، لتفعيل هذه التنبيهات يرجى إظهارها.",
  "tooltip.action.close": "إغلاق",
  "Time Saved per Execution": "الوقت الموفر لكل تنفيذ",
  "Time Saved per Item": "الوقت الموفر لكل عنصر",
  "filter by": "تصفية حسب",
  showing: "عرض",
  from: "من",
  to: "إلى",
  entries: "إدخالات",
  "Data mapping": "ربط البيانات",
  Temps: "أوقات",
  "process.start.restriction":
    "العملية التي تقوم بتشغيلها غير مقررة لتشغيلها اليوم بناءً على معايير الخدمة، هل أنت متأكد أنك تريد المتابعة؟",
  "process.start.require.input": "تتطلب هذه العملية إدخالًا لتشغيلها",
  "process.inputs.button.reset": "إعادة تعيين",
  "process.inputs.button.save": "إضافة",
  "process.inputs.save.blank.values.title":
    "تم اكتشاف بعض المعلمات بقيم إدخال فارغة.",
  "process.inputs.save.blank.values.message":
    "هل أنت متأكد أنك تريد بدء هذه الجلسات بالقيم المقدمة؟",
  Now: "الآن",
  "server Type": "نوع الخادم",
  "Automation Impact": "تأثير الأتمتة",
  ERRORS_NO_DATA_AVAILABLE: "لا تتوفر بيانات",
  "queue.management.resource.has.pendingExecutions":
    "لديك {{count}} تنفيذ معلق باستخدام هذا الروبوت، يرجى حذفها إذا كنت ترغب في متابعة هذا العملية.",
  "queue.management.resource.has.scheduled.pendingExecutions":
    "{{count}} من التنفيذات مجدولة، يرجى مراجعة الجداول المخصصة لهذا الروبوت.",
  "occupancy.waiting.more": "في انتظار تنفيذات أكثر لحساب احتلال الروبوت",
  "select.process": "اختيار عملية",
  "changes.saved.successfully": "تم حفظ التغييرات بنجاح",
  "changes.saving.failed": "فشل حفظ التغييرات",
  "process.owner": "مالك العملية",
  "end date cannot be earlier than the start date":
    "لا يمكن أن تكون تاريخ الانتهاء أقدم من تاريخ البداية",
  "end date cannot be in the future":
    "لا يمكن أن يكون تاريخ الانتهاء في المستقبل",
  "no.period.message": "لم يتم العثور على فترة",
  "no.status.message": "لم يتم العثور على حالة",
  "pending.executions.warning":
    "تحذير: هذه العملية لديها تنفيذات معلقة. سيؤدي حذفها الآن إلى مسحها جميعًا.",
  "calendar.formControl.saveRiskSuccess": "تمت إضافة هامش الخطر بنجاح",
  "export.confirm.msg.delete.all": "هل أنت متأكد أنك تريد حذف جميع الملفات؟",
  "export.confirm.msg.delete.selected": "هل أنت متأكد أنك تريد حذف الملفات؟",
  "export.confirm.msg.delete.one": "هل أنت متأكد أنك تريد حذف هذا الملف؟",
  on: "تشغيل",
  language: "اللغة",
  "Choose.the.checking.frequency": "أضف تردد التحقق",
  "resources.show.hidden": "عرض الروبوتات المخفية",
  "exceptions.show.hidden": "عرض الاستثناءات المخفية",
  "user.logo.allowed.extensions":
    "نحن نسمح فقط بامتدادات jpg، jpeg، png، و bmp",
  "allowed.extensions": "نحن نسمح فقط بامتدادات {{extensions}}",
  "max.size.allowed": "الحد الأقصى لحجم الملف المسموح به هو {{size}} ميغابايت",
  "items.pending.locked": "يتم حاليًا العمل على هذا العنصر",
  "items.pending.deferred": "تم تأجيل هذا العنصر حتى {{date}}",
  "export.limit":
    "يقتصر التصدير على أول 100,000 سجل. للحصول على نتائج أكثر دقة، يمكنك تحسين معايير البحث الخاصة بك.",
  "queue.create.update.restriction":
    "يجب أن تحتوي كل قائمة انتظار على ما لا يقل عن ترخيص واحد، وعملية واحدة مخصصة، وروبوت واحد مخصص",
  "custom.kpi.dialog.field.type": "نوع KPI",
  FINANCIAL_KPI: "KPI مالي",
  PRODUCTIVIDAD_KPI: "مؤشر الأداء الرئيسي للإنتاجية",
  EFFICIENCY_KPI: "مؤشر الأداء الرئيسي للإنتاجية",
  "reporting.financialImpact": "التأثير المالي",
  "reporting.resourceProductivity": "إنتاجية الروبوت",
  "reporting.avgHandlingTime": "متوسط وقت المعالجة",
  "max.datamap.reached": "يمكنك اختيار ثلاث حقول فقط لعرضها في قائمة العناصر",
  "fleet.management.formLabel.currency": "العملة",
  "tooltip.visit.link": "زيارة الرابط",
  "tooltip.edit.link": "تحرير الرابط",
  "tooltip.delete.link": "حذف الرابط",
  "no.link.message": "لم يتم العثور على روابط",
  "confirm.delete.link": "هل أنت متأكد أنك تريد حذف هذا الرابط؟",
  "add.link": "إدراج رابط جديد",
  "create.link": "إنشاء رابط",
  "confirm.add.link": "هل أنت متأكد أنك تريد إنشاء هذا الرابط؟",
  "insert.link": "إدراج",
  "link.validate.url": "الرجاء إدخال رابط صحيح",
  "link.hypertextUrl": "رابط الوثيقة الخارجية",
  "link.url": "الرابط",
  "link.name": "الاسم",
  "link.description": "الوصف",
  "link.delete.success": "تم حذف الرابط بنجاح",
  "link.add.success": "تم إنشاء الرابط بنجاح",
  "link.update.success": "تم تعديل الرابط بنجاح",
  "link.add.failure": "فشل إنشاء الرابط! يرجى المحاولة مرة أخرى لاحقًا",
  "link.update.failure": "فشل تحديث الرابط! يرجى المحاولة مرة أخرى لاحقًا",
  "link.tab": "رابط الوثائق الخارجية",
  "View external documentation": "عرض الوثائق الخارجية",
  "Manage external documentation": "إدارة الوثائق الخارجية",
  "Description (ascending)": "الوصف (تصاعدي)",
  "Description (descending)": "الوصف (تنازلي)",
  "Name (ascending)": "الاسم (تصاعدي)",
  "Name (descending)": "الاسم (تنازلي)",
  "disconnection.period": "لأكثر من (دقائق)",
  "help.exception.type": "يرجى اختيار نوع الاستثناء",
  "audit.object": "الكائن",
  "audit.user": "المستخدم",
  "audit.action": "الإجراء",
  "audit.time.filter": "مرشح الوقت",
  "audit.sort.by": "فرز حسب",
  Audit: "سجل التدقيق",
  "audit.module": "الوحدة",
  "audit.times.stamp": "الطابع الزمني",
  "audit.search.logs": "البحث في السجلات",
  "no.object.message": "لم يتم العثور على كائن",
  "no.user.message": "لم يتم العثور على مستخدم",
  "no.module.message": "لم يتم العثور على أي وحدة",
  "no.action.message": "لم يتم العثور على إجراء",
  "no.time.filter.message": "لم يتم العثور على مرشح الوقت",
  "no.sort.message": "لم يتم العثور على فرز",
  "audit.module.descending": "الوحدة (تنازلي)",
  "audit.module.ascending": "الوحدة (تصاعدي)",
  "audit.user.descending": "المستخدم (تنازلي)",
  "audit.user.ascending": "المستخدم (تصاعدي)",
  "users.delete.has.exception": "لدى المستخدم (المستخدمين) استثناءات مرفقة به",
  "Split equally": "تقسيم بالتساوي",
  deletedSuccessfullySingle: "تم حذف {{objectNature}} {{ObjectName}} بنجاح.",
  deletedSuccessfullyDouble: "تم حذف {{objectNature}} {{firstObjectName}} و {{secondObjectName}} بنجاح.",
  deletedSuccessfullyMultiple: "تم حذف {{objectNature}} {{twoFirstObjectName}} و {{remainingCount}} آخرون بنجاح.",
  userSingle: "المستخدم",
  userPlural: "المستخدمين",
  groupSingle: "المجموع",
  groupPlural: "المجموعات",
  tagSingle: " الوسم ",
  tagPlural: "الوسوم",
  orchesratorSingle: "المنظم الآلي",
  orchesratorPlural: "المنظمون الآليون",
  queueSingle: "قائمة الانتظار",
  queuePlural: "قوائم الانتظار",
  "time.zone": "المنطقة الزمنية",
  "profile.update-password.rewrite.email": "إعادة كتابة البريد الإلكتروني",
  "profile.update-password.wrong.password": "كلمة المرور غير متطابقة",
  Preferences: "تفضيلات",
  "user.information": "معلومات المستخدم",
  "change.your.password": "غير كلمة السر الخاصة بك",
  "Tag already exist with the same name": "الوسم موجودة بالفعل بنفس الاسم.",
  "title.confirm.message.delete": "حذف مجموعة",
  "items.pending": "قيد الانتظار",
  configuration: "إعدادات",
  "field.is.required": "الحقل {{field}} إجباري",
  "Setup Automatic Assignment": "التخصيص التلقائي",
  "Assignement Rules": "قواعد التعيين",
  "Automatic Exception Assignment": "تخصيص الاستثناء التلقائي",
  "Rule Configuration": "تكوين القاعدة",
  "Rule Name": "اسم القاعدة",
  "When an exception occurs for": "عند حدوث استثناء لـ",
  "logical Connector": "الموصل المنطقي",
  "Item Data": "بيانات العنصر",
  Condition: "الحالة",
  Value: "القيمة",
  "Assign to": "تعيين إلى",
  "User List": "قائمة المستخدمين",
  "Group List": "قائمة المجموعات",
  "Alert on assignment": "تنبيه عند التعيين",
  Summary: "ملخص",
  "New Rule": "قاعدة جديدة",
  "Exception Type/Reason": "نوع الاستثناء/السبب",
  GREATER: "أكبر من",
  SMALLER: "أصغر من",
  CONTAINS: "يحتوي",
  "IS.EQUAL": "يساوي",
  "rules.already.exists.with.processes": "القاعدة موجودة بالفعل مع نفس العمليات. هل تريد حذف القاعدة القديمة؟",
  "confirm.delete.rule": "هل أنت متأكد أنك تريد حذف هذه القاعدة؟",
  "confirm.disable.rule": "هل أنت متأكد من رغبتك في تعطيل هذه القاعدة ؟",
  "confirm.enable.rule": "هل أنت متأكد من رغبتك في تفعيل هذه القاعدة ؟",
  "When an exception occurs for details": "استخدم هذا الإعداد لتحديد الاستثناءات التي يجب تعيينها تلقائيًا. حدد عملية وحدد تفاصيل الاستثناءات التي ترغب في تعيينها.",
  "Assign to details": "هنا، يمكنك اختيار ما إذا كنت ترغب في تعيين الاستثناءات لمستخدم مح",
  migrate: "نقل",
  "do.not.migrate": "لا تنقل",
  "The name of the schedule": "اسم الجدول الزمني",
  "The first task executed by the schedule": "المهمة الأولى التي تُنفَّذها الجدول الزمني",
  "The date and time of the first execution of the schedule": "تاريخ ووقت أول تنفيذ للجدول الزمني",
  "The date and time after which the schedule will no longer be executed": "تاريخ ووقت بعد الذي لن يتم فيه تنفيذ الجدول الزمني بعد الآن",
  "The frequency at which the schedule will be executed (starting from the start date)": "التكرار الذي سيتم فيه تنفيذ الجدول الزمني (بدءًا من تاريخ البدء)",
  "Set(s) of process sessions that are executed concurrently": "مجموعة(مجموعات) من جلسات المعالجة التي يتم تنفيذها بشكل متزامن",
  "The task to execute if this task is completed": "المهمة التي يجب تنفيذها إذا تم إكمال هذه المهمة",
  "The task to execute if this task ends on an exception": "المهمة التي يجب تنفيذها إذا انتهت هذه المهمة على استثناء",
  "The process to be executed in this task session": "العملية التي يتعين تنفيذها في جلسة المهمة هذه",
  "The resource (robot on prod) on which the process will be executed": "المورد (الروبوت على الإنتاج) الذي سيتم تنفيذ العملية عليه",
  GROUP_ADDED: "تمت إضافة المجموعة",
  GROUP_MODIFIED: "تم تعديل معلومات المجموعة",
  GROUP_DELETED: "تم حذف المجموعة",
  USER_ADDED: "تمت إضافة المستخدم",
  USER_INFO_MODIFIED: "تم تعديل معلومات المستخدم",
  USER_DELETED: "تم حذف المستخدم",
  USER_PERMISSIONS_MODIFIED: "تم تغيير صلاحيات المستخدم",
  PROCESS_EXCEPTION_HIDDEN: "تم إخفاء استثناء العملية",
  PROCESS_EXCEPTION_UNHIDDEN: "تم عرض استثناء العملية",
  DEFAULT_ALERT_CREATED: "تم إنشاء التنبيه الافتراضي",
  DEFAULT_ALERT_MODIFIED: "تم تعديل التنبيه الافتراضي",
  DEFAULT_ALERT_DELETED: "تم حذف التنبيه الافتراضي",
  CUSTOM_ALERT_CREATED: "تم إنشاء التنبيه المخصص",
  CUSTOM_ALERT_MODIFIED: "تم تعديل التنبيه المخصص",
  CUSTOM_ALERT_DELETED: "تم حذف التنبيه المخصص",
  TAG_CREATED: "تم إنشاء الوسم",
  TAG_DELETED: "تم حذف الوسم",
  TAG_ASSIGNED_PROCESSES: "تم تعيين عمليات للوسم",
  TAG_UNASSIGNED_PROCESSES: "تم سحب عمليات من الوسم",
  USER_ASSIGNED_PROCESSES: "تم تعيين عمليات للمستخدم",
  USER_UNASSIGNED_PROCESSES: "تم سحب عمليات من المستخدم",
  USER_ASSIGNED_TAGS: "تم تعيين وسوم للمستخدم",
  USER_UNASSIGNED_TAGS: "تم سحب وسوم من المستخدم",
  PROCESS_SLA_UPDATED: "تم تحديث ال SLA للعملية",
  PROCESS_SETTINGS_UPDATED: "تم تحديث إعدادات العملية",
  PROCESS_DATA_MAPPING_UPDATED: "تم تحديث إعدادات تعيين البيانات",
  RESOURCE_ASSIGNED_PROCESSES: "تم تعيين عمليات للروبوت",
  RESOURCE_UNASSIGNED_PROCESSES: "تم سحب عمليات من الروبوت",
  RESOURCE_INFORMATION_MODIFIED: "تم تعديل معلومات الروبوت",
  RESOURCE_HIDDEN: "تم إخفاء الروبوت",
  RESOURCE_UNHIDDEN: "تم عرض الروبوت",
  COST_ALLOCATION_ADDED: "تمت إضافة التكلفة",
  COST_ALLOCATION_DELETED: "تم حذف التكلفة",
  COST_ALLOCATION_EDITED: "تم تعديل التكلفة",
  PROCESS_PARAMETERS_EDITED: "تم تعديل معطيات العملية",
  DB_CONNECTION_INITIATED: "تم بدء الاتصال بقاعدة البيانات",
  ORCHESTRATOR_ADDED: "تمت إضافة المنظم الآلي",
  ORCHESTRATOR_MODIFIED: "تم تعديل معلومات المنظم الآلي",
  ORCHESTRATOR_ENABLED: "تم تفعيل المنظم الآلي",
  ORCHESTRATOR_DISABLED: "تم تعطيل المنظم الآلي",
  ORCHESTRATOR_DELETED: "تم حذف المنظم الآلي",
  ANALYTICS_PRESET_CREATED: "تم إنشاء العداد المسبق",
  ANALYTICS_PRESET_DELETED: "تم حذف العداد المسبق",
  CUSTOM_KPI_DELETED: "تم حذف مؤشر الأداء المخصص",
  CUSTOM_KPI_CREATED: "تم إنشاء مؤشر الأداء المخصص",
  CUSTOM_KPI_MODIFIED: "تم تعديل معلومات مؤشر الأداء المخصص",
  QUEUE_ASSIGNED_RESOURCE: "تم تعيين الروبوت إلى قائمة انتظار SR",
  QUEUE_UNASSIGNED_RESOURCE: "تم سحب الروبوت من قائمة انتظار SR",
  QUEUE_ASSIGNED_PROCESS: "تم تعيين العملية إلى قائمة انتظار SR",
  QUEUE_UNASSIGNED_PROCESS: "تم سحب العملية من قائمة انتظار SR",
  QUEUE_CREATED: "تم إنشاء قائمة انتظار SR",
  QUEUE_MODIFIED: "تم تعديل معلومات قائمة الانتظار SR",
  QUEUE_DELETED: "تم حذف قائمة انتظار SR",
  "A role with the same already exists": "الدور بنفس الاسم موجود بالفعل",
  "Update Role": "تحديث الدور",
  "Role Name": "اسم الدور",
  "Apply to this group": "تعيينه كدور افتراضي للمجموعات التالية",
  "Select Permissions": "تحديد الأذونات",
  "Manage Roles": "الأدوار",
  Role: "الدور",
  "default.group.role": "الدور الافتراضي للمجموعة",
  "group.change.role": "سيؤدي تغيير دور المجموعة الافتراضي إلى تطبيق الصلاحيات على جميع المستخدمين الذين تم تعيينهم ليحصلوا على صلاحيات هذه المجموعة",
  "Create Role": "إنشاء دور",
  assign: "تعيين",
  unassign: "إلغاء التعيين",
  "user.permission.paste.error": "يرجى التأكد من نسخ الأذونات أولاً",
  "user.permission.paste": "لصق الأذونات",
  "user.permission.copy": "نسخ الأذونات",
  "copy.role.permissions.template": "نسخ قالب أذونات الدور",
  "Role Configuration": "تكوين الدور",
  "user.add.inherit.group.permissions": "وراثة أذونات المجموعة",
  Interfaces: "الواجهات",
  Automations: "التلقائيات",
  "Opportunity assessment": "الفرص",
  "Personal settings": "الإعدادات الشخصية",
  "List of item Data for this process": "قائمة ببيانات العنصر لهذا العملية",
  "DASHBOARD Today": "اليوم",
  "DASHBOARD This Week": "آخر 7 أيام",
  "DASHBOARD This Month": "آخر 30 يوماً",
  "DASHBOARD This Year": "آخر 365 يوماً",
  "Last session synched": "آخر جلسة تم مزامنتها",
  "The number of resources linked to this orchestrator": "عدد الموارد المرتبطة بهذا المنظم",
  "The number of automations linked to this orchestrator": "عدد الآليات المرتبطة بهذا المنظم",
  "Number of licences allowed to use on this orchestrator": "عدد التراخيص المسموح بها للاستخدام على هذا المنظم",
  "The date of the latest session synched by SmartRoby": "تاريخ آخر جلسة تم مزامنتها بواسطة SmartRoby",
  "The number of users belonging to this group": "عدد المستخدمين الذين ينتمون إلى هذه المجموعة",
  "The number of automations assigned to this group": "عدد الآليات المخصصة لهذه المجموعة",
  "Resource type": "نوع المورد",
  "Paired automations": "الآليات المقترنة",
  "The number of automations that this resource is able to run": "عدد الآليات التي يمكن لهذا المورد تشغيلها",
  "The number of automations already scheduled with this resource": "عدد الآليات المجدولة بالفعل مع هذا المورد",
  "Created since": "تم الإنشاء منذ",
  "Human-in-the-loop activated": "تم تنشيط الإنسان في الحلقة",
  "kpi.parameter.allowScheduling.tooltip": "يسمح بجدولة التشغيل التلقائي باستخدام SmartRoby",
  "kpi.parameter.allowManual.tooltip": "يسمح بتشغيل التشغيل التلقائي يدويًا باستخدام SmartRoby",
  "kpi.parameter.parameter.retryable.tooltip": "يسمح للمستخدمين بإعادة محاولة الاستثناءات لهذا التشغيل التلقائي",
  "kpi.parameter.human.in.the.loop.activated.tooltip": "يسمح للمستخدمين بإكمال أو تغيير البيانات المعاملاتية قبل إعادة محاولة عنصر لهذا التشغيل التلقائي",
  "KPI settings": "إعدادات KPI",
  "SR properties": "خصائص SR",
  "Assignment Rules": "قواعد التعيين",
  "You choose a process assigned to queue, all processes must be assigned to the same queue": "لقد اخترت عملية مُسندة إلى طابور الانتظار، يجب تعيين جميع العمليات إلى نفس الطابور",
  "process.migration.not.possible": "التطبيق(ات) الآلي(ة) التالية مُسندة بالفعل إلى SRQs أخرى. لا يمكن تعيين تطبيق آلي إلى أكثر من SRQ واحد.",
  "Report a Bug": "الإبلاغ عن خطأ",
  "executions found": "تم العثور على التنفيذات",
  "licence.max.automations": "أقصى التشغيلات",
  "Inherit group automations": "وراثة الأتمتة الجماعية",
  "File exported successfully": "تم تصدير الملف بنجاح",
  "master.process": "عملية رئيسية",
  "newDashboard.AllStats.title": "إحصائيات جميع الأوقات",
  "newDashboard.CurrentStats.title": "الإحصائيات الحالية",
  "items.header.label": "العناصر",
  "successRate.header.label": "نسبة النجاح",
  "Schedules health": "صحة الجداول الزمنية",
  "til.now": "حتى اللحظة",
  "completed.transactions.status": "مكتمل",
  "System exceptions": "استثناءات النظام",
  "Business exceptions": "استثناءات العمل الخاصة",
  "notWorking.processes.status": "الجداول الزمنية مع عمليات تنفيذ مفقودة",
  "working.processes.status": "تشغيل وفقاً للجداول الزمنية",
  "Monitoring & Reporting": "المراقبة والإبلاغ",
  "Automation Management": "إدارة التشغيل الآلي",
  "Resource Management": "إدارة الموارد",
  "Monitoring Dashboard": "لوحة المراقبة",
  Orchestrators: "المنظمات",
  Resources: "الموارد",
  "SR Queues": "SR قوائم انتظار ",
  "see.all": "عرض الكل",
  "pendingCount.descending": "العناصر المعلقة (تنازلياً)",
  pendingCount: "العناصر المعلقة",
  "completedCount.descending": "العناصر المكتملة (تنازلياً)",
  completedCount: "العناصر المكتملة",
  "processRestriction.recentActivity": "آخر الأنشطة",
  "processRestriction.recentActivity.descending": "آخر الأنشطة (تنازلياً)",
  "robots.occupancy": "الإشغال",
  "Automation dependency": "اعتماد الأتمتة",
  "reporting.itemExceptions": "إجمالي الاستثناءات",
  "tooltip.kpi.time.saved": "(FTEs) يشير إلى إجمالي الوقت الذي تم توفيره من خلال الأتمتة، ويعبر عنه غالبًا بالساعات أو الموظفين المتفرغين ",
  "tooltip.kpi.breakEven": "الوقت المتوقع عندما تعوض المدخرات من الأتمتة تكاليفها الأولية. إنه مقياس أساسي للتخطيط المالي يشير إلى متى ستبدأ استثمارات الأتمتة في تحقيق عوائد إيجابية صافية",
  "tooltip.kpi.roi": "يعكس هذا المقياس النسبة المئوية للعوائد المالية بالنسبة لتكلفة الاستثمار في كل عملية أتمتة. لقراءته، قارن النسبة مع 100% - فوق 100% يعني أن الأرباح تتجاوز التكلفة. يُحسب بقسمة المكاسب المالية من الأتمتة على إجمالي تكلفة الاستثمار",
  "tooltip.kpi.costSaved": "يعكس إجمالي التوفير في التكاليف المحقق من خلال الأتمتة عن طريق تقليل تنفيذ المهام اليدوية وزيادة الكفاءة",
  "tooltip.kpi.totalExecutions": "عدد مرات التشغيل الإجمالية (عمليات الأتمتة)",
  "tooltip.kpi.itemsCompleted": "يتتبع عدد العناصر (مهام الأتمتة) التي تم إكمالها بدون مشاكل، مما يعكس فعالية ومصداقية الأتمتة",
  "tooltip.kpi.executionTime": "يجمع إجمالي الوقت المستغرق في جميع عمليات التشغيل (الأتمتة)",
  "tooltip.kpi.itemExecutionTime": "يجمع إجمالي وقت المعالجة لجميع العناصر (مهام الأتمتة)",
  "tooltip.kpi.successRate": "يقيس نسبة المهام التي تم إكمالها بدون استثناءات إلى إجمالي المحاولات. تشير النسب العالية إلى تصميم أتمتة قوي وفعالية الأتمتة",
  "tooltip.kpi.exceptionRate": "نسبة المهام التي واجهت استثناءات أثناء الأتمتة. يساعد في مراقبة صحة الأتمتة وتحديد الأنماط التي قد تؤدي إلى تحسين الأتمتة",
  "tooltip.kpi.occupancyRate": "يوضح مدى استخدام وقت المورد المتاح لتنفيذ الأتمتة. تشير النسبة العالية إلى الاستخدام الجيد، بينما قد تشير النسبة المنخفضة إلى سوء الاستخدام وفرص تحسين التخصيص.",
  "tooltip.kpi.resourceProductivity": "كمية الوقت الذي يكون فيه المورد مشاركًا بنشاط في معالجة عمليات الأتمتة",
  "tooltip.kpi.avgHandlingTime": "متوسط وقت المعالجة لكل عنصر (مهمة الأتمتة)، مما يوفر معيارًا للأداء وتحديد الاختناقات",
  "tooltip.kpi.itemExceptions": "إجمالي عدد العناصر (مهام الأتمتة) التي لم تكتمل كما هو مخطط لها بسبب أحداث غير متوقعة",
  "tooltip.kpi.itemsProcessed": "يشير إلى إجمالي كمية العناصر التي تم البدء بها أو إدخالها في النظام للمعالجة، بغض النظر عن النتيجة. يوفر هذا المقياس رؤى حول حجم العمل الذي تعالجه النظام أو الأتمتة",
  "transactions.header.tooltip": "إجمالي عدد العمليات",
  "Completed transactions": "المعاملات المنجزة",
  "bots.header.tooltip": "إجمالي وقت عمل الروبوتات",
  "FTESaved.header.tooltip": "إجمالي وحدات العمل بدوام كامل الموفرة",
  "productivity.descending": "الإنتاجية (تنازلياً)",
  "occupancy.ascending": "الإشغال",
  "occupancy.descending": "الإشغال (تنازلياً)",
  "reporting.exportCSV": "CSV تصدير كملف ",
  "reporting.exportCSV.all": "CSV تصدير جميع الصفحات كملف ",
  "Additional information": "معلومات إضافية",
  Once: "مرة",
  "task.initial.task": "المهمة الأولية",
  "task.post.completion.delay": "تأخير بعد إتمام المهمة",
  "task.remove": "إزالة المهمة",
  "task.name": "اسم المهمة",
  "task.description": "وصف المهمة",
  "task.on.complete": "عند الاكتمال",
  "task.on.exception": "عند الاستثناء",
  "task.add.session": "إضافة جلسة",
  "task.delete.session": "حذف الجلسة",
  "task.name.already.exist": "اسم المهمة موجود بالفعل",
  "task(s)": "المهام",
  "add.task": "إضافة مهمة",
  task: "مهمة",
  "no.tasks.yet": "لا توجد مهام حتى الآن",
  "no.task.message": "لم يتم العثور على مهام",
  "task.stop": "إيقاف",
  "task.abort": "إلغاء",
  "task.required.process": "يرجى اختيار الأتمتة",
  "task.required.first.session.process": "يرجى اختيار الأتمتة للجلسة الأولى",
  "All time stats": "إحصائيات جميع الأوقات",
  "FTEs saved": "وحدات العمل بدوام كامل الموفرة",
  SUBSCRIBE: "الاشتراك",
  UNSUBSCRIBE: "إلغاء الاشتراك",
  "Profile Time zone": "منطقة توقيت الملف الشخصي",
  "Reccuring ": "متكرر",
  "One shot": "مهمة واحدة",
  "Exclusive  ": "حصري",
  transactions: "المعاملات",
   "total.transactions": "إجمالي المعاملات",
  "average.transactions": "متوسط المعاملات",
  "average.FTEs.saved": "متوسط الوقت الموفر",
  "success.rate": "معدل النجاح",
  "average.success.rate": "متوسط معدل النجاح",
  "Processes.activity.tooltip": ".يقيس هذا المؤشر عدد عمليات الأتمتة التي تجري وفقًا للجدول الزمني وتُنفذ كما هو مخطط مقابل عدد عمليات الأتمتة التي تم جدولتها ولكنها لا تسير وفقًا للجدول الزمني المخطط",
  "Edit Resources": "تعديل الموارد",
  QUEUE_ASSIGNED_ROBOT: "تم تعيين المورد إلى القائمة",
  SCHEDULE_ADDED: "تمت إضافة الجدول الزمني",
  ITEM_EXCEPTION_MARKED_DONE: "تم وضع علامة على استثناء العنصر بأنه مكتمل",
  ITEM_EXCEPTION_RETRIED: "تمت إعادة المحاولة لاستثناء العنصر",
  PROCESS_STARTED: "تم بدء العملية",
  SCHEDULE_MODIFIED: "تم تعديل الجدول الزمني",
  SCHEDULE_DELETED: "تم حذف الجدول الزمني",
  EXCEPTION_RULE_MODIFIED: "تم تعديل قاعدة الاستثناء",
  EXCEPTION_RULE_ADDED: "تمت إضافة قاعدة الاستثناء",
  ITEM_EXCEPTION_RELEASED: "تم تحرير استثناء العنصر",
  EXCEPTION_RULE_DELETED: "تم حذف قاعدة الاستثناء",
  QUEUE_UNASSIGNED_ROBOT: "تم إلغاء تعيين المورد من القائمة",
  ITEM_EXCEPTION_ASSIGNED: "تم تعيين استثناء العنصر",
  EXCEPTION_RULE_DISABLED: "تم تعطيل قاعدة الاستثناء",
  "Launched with input": "تم إطلاقه بإدخال",
  "Items processed": "العناصر المعالجة",
  "Items Exceptions can be retried with original": "يمكن إعادة محاولة استثناءات العناصر مع الأصل",
  "Item Exceptions can be retried with different data": "يمكن إعادة محاولة استثناءات العناصر مع بيانات مختلفة",
  "Available robots": "الروبوتات المتاحة",
  "Robot assignation": "تخصيص الروبوت",
  Time: "الوقت",
  "Estimated execution time": "الوقت المقدر للتنفيذ",
  "Cost distribution": "توزيع التكلفة",
  "enhanced.search.tooltip": " ('اليدوية NOT مثل 'الأتمتة) لاستبعاد مواضيع معينة من نتائجك 'NOT' و ,('الموارد البشرية OR مثل 'المالية) لاكتشاف المشاريع في أي من المجالين 'OR' ,('الأتمتة AND مثل 'المالية) للعثور على المشاريع التي تلبي معايير متعددة 'And' عزِّز بحثك باستخدام المشغلات المنطقية: استخدم",
};
